import moment from "moment";

export const formatDateToLocal = (date, withTime = true) => {
  if (!date) return "";
  var d = new Date(date);
  var ampm = d.getHours() >= 12 ? 'PM' : 'AM';
  var hours = d.getHours() > 12 ? d.getHours() - 12 : d.getHours();
  if (withTime) {
    return ("0" + (d.getMonth() + 1)).slice(-2) + "/" + ("0"
      + d.getDate()).slice(-2) + "/" + d.getFullYear()
      + " " + ("0" + (hours)).slice(-2)
      + ":" + ("0" + (d.getMinutes())).slice(-2)
      + ":" + ("0" + (d.getSeconds())).slice(-2)
      + " " + ampm;
  } else {
    return ("0" + (d.getMonth() + 1)).slice(-2) + "/" + ("0"
      + d.getDate()).slice(-2) + "/" + d.getFullYear();
  }
}


export const formatDateToUTC = (date) => {
  if (!date) return "";
  var d = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0);
  var r = d.getUTCFullYear() + "-" + ("0" + (d.getUTCMonth() + 1)).slice(-2) + "-" + ("0" + d.getUTCDate()).slice(-2)
    + "T" + ("0" + (d.getUTCHours())).slice(-2) + ":" + ("0" + (d.getUTCMinutes())).slice(-2) + ":" + ("0" + (d.getUTCSeconds())).slice(-2);
  return r;
}

export const formatCurreny = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

export const getFullAddress = (value) => {
  var list = [];
  if (value) {
    if (value.address_line1) {
      list.push(value.address_line1);
    }
    if (value.address_line2) {
      list.push(value.address_line2);
    }
  } else { return ''; }
  return list.join(', ');
}

export const getCityStateZipCode = (value) => {
  var list = [];
  if (value) {
    if (value.city) {
      list.push(value.city);
    }
    if (value.state) {
      list.push(value.state + ' ' + (value.zip_code ? value.zip_code : ''));
    }
  } else { return ''; }
  return list.join(', ');
}

export const convertDateString = (str) => {
  if (str) {
    var arr = [];
    arr.push(str.substring(0, 4));
    arr.push(str.substring(4, 6));
    arr.push(str.substring(6));
    return arr.join('-');
  } else {
    return '';
  }

}

export const convertStringToDate = (str) => {
  if (str) {
    var yy=Number(str.substring(0, 4));
    var mm=Number(str.substring(4, 6))-1;
    var dd=Number(str.substring(6));
    return (new Date(yy,mm,dd));
  } else {
    return '';
  }
}

export const removeFCSString = (str) => {
  var a= str != null ? str.split("-") : '';
  if(a.length==1) return a[0];
  if(a.length>=2) return a[1];
  return a;
}

export const convertISOToDate = (value) => {
  var date = (typeof value === "string") ? new Date(value) : value.toDate();
  var formatted = formatDateToLocal(date, false);
  return formatted;
}

export const getTimeFormDate = (d, timePeriod = true) => {
  if (!d) return "";
  var ampm = d.getHours() >= 12 ? 'PM' : 'AM';
  var hours = d.getHours() > 12 ? d.getHours() - 12 : d.getHours();
  if (timePeriod) {
    return ("0" + (hours)).slice(-2)
      + ":" + ("0" + (d.getMinutes())).slice(-2)
      + " " + ampm;
  } else {
    return ("0" + (d.getHours())).slice(-2)
      + ":" + ("0" + (d.getMinutes())).slice(-2);

  }

}

export const getDay = (value, short = true) => {
  var longDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  var d = new Date(value);

  if (short) {
    var dayName = days[d.getDay()];
    return dayName;
  } else {
    var _dayName = longDays[d.getDay()];
    return _dayName;
  }

}

export const getFormatObject = (data, formatString) => {
  for (const [key, value] of Object.entries(data)) {
    var replace = '%' + key + '%';
    var re = new RegExp(replace, "g");
    formatString = formatString.replace(re, value);
  }
  return (formatString.replace(/\r\n/g, '\n'));
}

export const getFormatDate = (data, formatString) => {
  var date = new Date(data);
  var dateString = moment(date).format(formatString);
  return dateString;
}

export const getFormatTime = (data, formatString) => {
  var date = new Date(data);
  var timeString = moment(date).format(formatString);
  return timeString;
}

export const convertMonthString = (str) => {
  var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  if (str) {
    var yy = str.substring(0, 4);
    var mm = Number(str.substring(4) - 1);
    // console.log("month:", mm);
    return months[mm] + " " + yy;
  } else {
    return '';
  }

}