import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { changeMyPakagesPage, getMyPackagesData } from '../../actions/explore';
import theme from '../../theme';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import MyPackageToolBar from './my_package_search_bar';
import EnhancedTableHeader from '../enhanced_table_header';
import { changeRowPerPage } from '../../actions/app';
import { setPickupData } from '../../actions/pickup';
import { formatDateToLocal } from '../../util';
import TrackPackageList from '../track/track_package_list';
import { withCookies,useCookies } from 'react-cookie';

const useStyles = makeStyles({
    root: {
        width: '100%',
        flexGrow: 1,
        marginTop: '30px'
    },
    table: {
        minWidth: 650,
    },
    trackingID: {
        cursor: "pointer",
        color: theme.primary,
        textDecoration: "underline",
    }
});

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const headCells = [
    { id: 'sn', numeric: true, label: 'No.', width: 50 },
    { id: 'tracking_id', numeric: false, label: 'Tracking Number' },
    // { id: 'shipment_number', numeric: false, label: 'Shipment Number' },
    // { id: 'invoice_number', numeric: false, label: 'Invoice Number' },
    { id: 'received_date', numeric: false, label: 'Received Date' },
    { id: 'updated_date', numeric: false, label: 'Updated Date', sortable: true },
    { id: 'status', numeric: false, label: 'Status' },
];


function MyPackageList(props) {
    const { dispatch, history } = props;
    const classes = useStyles();
    const packages = useSelector(state => state.explore.myPackages);
    const count = useSelector(state => state.explore.myPackagesCount);
    const rowsPerPage = useSelector(state => state.app.rowsPerPage);
    const page = useSelector(state => state.explore.myPackagesPage);
    const order = useSelector(state => state.explore.myPackagesOrder);
    const user = useSelector(state => state.app.user);
    const isGrid=props.cookies.get('my-package-view')=="grid";

    useEffect(() => {
        if (user.id !== undefined) {
            dispatch(getMyPackagesData());
        }
    }, [user, dispatch]);

    const handleRequestSort = (event, property) => {
        const isAsc = order['field_name'] === property && order['direction'] === 'asc';
        var _order = { "field_name": property, "direction": isAsc ? 'desc' : 'asc' };
        dispatch(changeMyPakagesPage(0));
        dispatch(getMyPackagesData(_order));
    };

    const handleClick = (event, id) => {
        dispatch(setPickupData({}));
        event.preventDefault();
        var pathName = '/my-package-detail/' + id;
        history.push(pathName);
    };

    const handleChangePage = (event, newPage) => {
        dispatch(changeMyPakagesPage(newPage));
        dispatch(getMyPackagesData());
    };

    const handleChangeRowsPerPage = (event) => {
        var rowPerPage = parseInt(event.target.value, 10);
        dispatch(changeMyPakagesPage(0));
        dispatch(changeRowPerPage(rowPerPage));
        dispatch(getMyPackagesData());
    };

    return (
        <div className={classes.root}>
            <Paper>
                <MyPackageToolBar placeHolder={'Search my package'} {...props}></MyPackageToolBar>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`}
                />
                { isGrid?<TrackPackageList packages={packages}></TrackPackageList>:
                <TableContainer>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <EnhancedTableHeader
                            classes={classes}
                            order={order.direction}
                            orderBy={order.field_name}
                            onRequestSort={handleRequestSort}
                            rowCount={count}
                            headCells={headCells}
                        />
                        <TableBody>
                            {packages.map((row) => (
                                <StyledTableRow key={row.id}>
                                    {headCells.map((h, i) => {
                                        if (h.id === 'sn') {
                                            return (<TableCell key={h.id} style={{ width: h.width }} align="right">{row['sn']}</TableCell>);
                                        } if (h.id === 'tracking_id') {
                                            return (<TableCell className={classes.trackingID} style={{ width: h.width }} key={h.id} align={h.numeric ? 'right' : 'left'}
                                                onClick={(event) => handleClick(event, row['id'])}
                                            >{row[h.id]}</TableCell>);
                                        } else if (h.id === 'updated_date' || h.id === 'received_date') {
                                            return (<TableCell style={{ width: h.width }} key={h.id} align={'left'} >{formatDateToLocal(row[h.id],false)}</TableCell>);
                                        } else {
                                            return (<TableCell key={h.id} style={{ width: h.width }} align={h.numeric ? 'right' : 'left'}>{row[h.id]}</TableCell>);
                                        }
                                    })}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
}
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`}
                />
            </Paper>
        </div>
    );
}

MyPackageList.propTypes = {
    history: PropTypes.object,
};


export default withCookies(connect()(MyPackageList));