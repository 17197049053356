
import React, { useEffect, useState } from 'react';
import {
    Button,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import theme from '../../theme';
import EmailForm from './email_form';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect, useSelector } from 'react-redux';
import { addPickupDate, addPickupZone, deletePickupDate, deletePickupZone, getEmails, getPickupDates, getPickupZones, updateEmailFormats, updatePickupDate, updatePickupZone } from '../../actions/app';
import ConfirmDialog from '../confirm_dialog';
import PickupDateDialog from './pickup_date_dialog';
import PickupZoneDialog from './pickup_zone_dialog';
import { convertStringToDate, formatDateToLocal, getDay } from '../../util';
import LocalOverlay from '../local_overlay';
import { clearMessages } from '../../actions/sms';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        flexGrow: 1,
        marginTop: '30px',
        backgroundColor: 'white'
    },
    paper: {
        width: '100%',
        height: '400px',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 800,
    },
    trackingID: {
        cursor: "pointer",
        color: theme.primary,
        textDecoration: "underline",
    },
    listItemSelected: {
        padding: '10px',
        color: theme.palette.primary.main,
        textTransform: 'none',
        cursor: 'pointer',
        fontWeight: 'bold'
    },
    listItem: {
        padding: '10px',
        color: "grey",
        textTransform: 'none',
        cursor: 'pointer'
    },
    listItemHeader: {
        padding: '10px 15px 10px 15px',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        marginBottom: '10px'
    },
}));

var formatList = [
    { "name": "available_pickup", "display": "Available Pickup Dates" },
    { "name": "confirm", "display": "Confirm Pickup Email/SMS Format" },
    { "name": "cancel", "display": "Cancel Pickup Email/SMS Format" },
    { "name": "complete", "display": "Complete Pickup Email/SMS Format" },
    { "name": "reschedule", "display": "Reschedule Pickup Email/SMS Format" },
    { "name": "complete-dropoff", "display": "Complete Dropoff Email/SMS Format" },
    { "name": "pickup_zone", "display": "Pickup Zones" },
    { "name": "sms_list", "display": "SMSs" },
    { "name": "sms_rate", "display": "SMS Rates" },
];

function SettingsPage(props) {
    const classes = useStyles();
    const { dispatch, history } = props;
    const [open, setOpen] = React.useState(false);
    const [dateOpen, setDateOpen] = React.useState(false);
    const [isDeleteZoneConfirm, setDeleteZoneConfirm] = React.useState(false);
    const [isDeleteDateConfirm, setDeleteDateConfirm] = React.useState(false);
    const [newDate, setNewDate] = useState(false);
    const [loading, setIsLoading] = useState(false);

    const [formKey, setFormKey] = useState("available_pickup");
    const [pickupDate, setPickupDate] = React.useState({});
    const [pickupZone, setPickupZone] = React.useState({});

    const zones = useSelector(state => state.app.zones);
    const pickupDates = useSelector(state => state.app.pickupDates);
    const emails = useSelector(state => state.app.emails);
    pickupDates.sort((a, b) => {
        if (a.id > b.id) {
            return -1;
        }
        if (a.id < b.id) {
            return 1;
        }
        return 0;
    });


    useEffect(() => {
        dispatch(getPickupZones());
        dispatch(getPickupDates());
        dispatch(getEmails());
    }, [dispatch]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleForm = (e, value) => {
        e.preventDefault();
        if (value === "sms_list") {
            dispatch(clearMessages());
            history.push("/sms")
            return;
        }
        if (value === "sms_rate") {
            history.push("/rates")
            return;
        }
        setFormKey(value);
    }

    const handleSavePickupZone = (pickupZone) => {
        setIsLoading(true);
        if (pickupZone['id'] === undefined) {
            dispatch(addPickupZone(pickupZone, () => {
                setIsLoading(false);
                setOpen(!open);
            }));
        } else {
            dispatch(updatePickupZone(pickupZone, () => {
                setIsLoading(false);
                setOpen(!open);
            }));
        }
    }

    const handelAddZoneDialog = () => {
        setPickupZone({});
        setOpen(!open);
    }

    const handleDeleteZoneConfirmDialog = (zone) => {
        setPickupZone(zone);
        setDeleteZoneConfirm(!isDeleteZoneConfirm);
    }

    const handleDeletePickupZone = (v) => {
        setDeleteZoneConfirm(false);
        dispatch(deletePickupZone(pickupZone));
        setPickupZone({});
    }

    const handleZoneEdit = (e, zone) => {
        e.preventDefault();
        setPickupZone(zone);
        setOpen(!open);
    }

    /**
     * Functions of pickup date
     * @param {*} e 
     */
    const handelAddPickupDateDialog = () => {
        setNewDate(true);
        setDateOpen(!dateOpen);
    }

    const handleEditPickupDate = (e, date) => {
        e.preventDefault();
        setNewDate(false);
        setPickupDate(date);
        setDateOpen(true);
    }

    const handleClosePickUpDate = (e) => {
        setDateOpen(!dateOpen);
    }

    const handleSavePickUpDate = (value) => {
        setIsLoading(true);
        setPickupDate(value);
        if (newDate) {
            dispatch(addPickupDate(value, () => {
                setIsLoading(false);
                setDateOpen(false);
            }));
        } else {
            dispatch(updatePickupDate(value, () => {
                setIsLoading(false);
                setDateOpen(false);
            }));
        }
        setNewDate(false);
    }

    const handleDeleteDateConfirmDialog = (e, date) => {
        e.preventDefault();
        setPickupDate(date);
        setDeleteDateConfirm(true);
    }

    const handleDeletePickupDate = (v) => {
        setDeleteDateConfirm(false);
        dispatch(deletePickupDate(pickupDate));
        setPickupDate({});
    }

    const handleSaveEmailFormats = (value) => {
        setFormKey(formKey);
        dispatch(updateEmailFormats(value));
    }

    const formatDate = (date) => {
        return formatDateToLocal(convertStringToDate(date.id), false) + '\t' + getDay(convertStringToDate(date.id))
    }

    var index = 0;

    return (
        <LocalOverlay isActive={loading}>
            <div className={classes.root}>
                <Grid container style={{ padding: '10px' }} spacing={2}>
                    <Grid item xs={12} sm={3} style={{ borderColor: '1px solid grey' }}>
                        <Paper>
                            <CardContent>
                                <div style={{ height: 'auto' }}>
                                    <div >
                                        <List dense={true}>
                                            {formatList.map((value) => {
                                                return <ListItem key={value.name} button onClick={(e) => handleForm(e, value.name)}
                                                    className={value.name === formKey ? classes.listItemSelected : classes.listItem}>
                                                    {value.display}
                                                </ListItem>
                                            })}
                                        </List>
                                    </div>
                                </div>
                            </CardContent>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Paper>
                            <CardContent>
                                <div style={{ height: 'auto' }}>
                                    <div>
                                    {formKey === "available_pickup" ?
                                                <div>
                                                    <Typography className={classes.listItemHeader} style={{ color: theme.palette.primary.main, textAlign: 'center', paddingTop: '8px', fontSize: '16px' }}> {formatList.find(e => e.name === formKey).display}</Typography>
                                                    <div style={{ textAlign: 'start' }}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            startIcon={<AddIcon />}
                                                            style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                                            onClick={handelAddPickupDateDialog}>
                                                            Add Pickup Date
                                                        </Button>
                                                        {dateOpen ? newDate ? <PickupDateDialog
                                                            open={dateOpen}
                                                            title={"New Pickup Date"}
                                                            data={{}}
                                                            zones={zones}
                                                            onClose={handleClosePickUpDate}
                                                            onSave={(value) => handleSavePickUpDate(value)}
                                                        ></PickupDateDialog> : <PickupDateDialog
                                                            open={dateOpen}
                                                            title={"Update Pickup Date"}
                                                            data={pickupDate}
                                                            zones={zones}
                                                            onClose={handleClosePickUpDate}
                                                            onSave={(value) => handleSavePickUpDate(value)}
                                                        ></PickupDateDialog> : <div />}
                                                    </div>
                                                    <div style={{ marginTop: '10px' }}>
                                                        <table>
                                                            <tbody>
                                                                {pickupDates.map((date) => {
                                                                    return <tr key={date.id}>
                                                                        <td style={{ textAlign: 'left', borderBottom: '1px solid gray' }}>
                                                                            <div>
                                                                                <div style={{ display: 'flex', width: '250px' }}>
                                                                                    <span style={{ color: 'grey', width: '160px', paddingTop: '5px' }}> {formatDate(date)}</span>
                                                                                    <IconButton color="primary" size="small" onClick={(e) => handleEditPickupDate(e, date)} ><EditIcon /></IconButton>
                                                                                    <span style={{ width: '20px' }} />
                                                                                    <IconButton color="primary" size="small" onClick={(e) => handleDeleteDateConfirmDialog(e, date)}><DeleteIcon /></IconButton>
                                                                                </div>
                                                                                <div>
                                                                                    <Typography style={{ color: 'grey', minWidth: '50px', fontWeight: 'bolder' }}>{date.remark}</Typography>
                                                                                </div>
                                                                                <div style={{ paddingLeft: '20px', paddingTop: '0px', paddingBottom: '5px' }}>
                                                                                    <ul style={{ margin: '5px' }}>
                                                                                        {date.zones.map(e => <li key={e.name}><Typography>{e.name}</Typography></li>)}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        {isDeleteDateConfirm ? <ConfirmDialog
                                                            msg={'Delete this pickup date "' + formatDate(pickupDate) + '"?'}
                                                            openDialog={isDeleteDateConfirm}
                                                            confirmBtnLabel={"Delete"}
                                                            onCancel={(v) => setDeleteDateConfirm(false)}
                                                            onContinue={(v) => handleDeletePickupDate(v)}></ConfirmDialog> : <div />}
                                                    </div>
                                                </div>
                                                : formKey === "pickup_zone" ?
                                                    <div>
                                                        <Typography className={classes.listItemHeader} style={{ color: theme.palette.primary.main, textAlign: 'center', paddingTop: '8px', fontSize: '16px' }}> {formatList.find(e => e.name === formKey).display}</Typography>
                                                        <div style={{ textAlign: 'start' }}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                startIcon={<AddIcon />}
                                                                style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                                                onClick={handelAddZoneDialog}>
                                                                Add Pickup Zone
                                                            </Button>
                                                            <PickupZoneDialog data={pickupZone} open={open} onClose={handleClose}
                                                                onSave={handleSavePickupZone}
                                                            ></PickupZoneDialog>
                                                        </div>
                                                        <div style={{ textAlign: 'start' }}>
                                                            <TableContainer>
                                                                <Table size="small">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>No</TableCell>
                                                                            <TableCell>Name</TableCell>
                                                                            <TableCell>Zip Codes</TableCell>
                                                                            <TableCell></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {zones.map((zone) => (<TableRow key={index}>
                                                                            <TableCell>{++index}</TableCell>
                                                                            <TableCell>{zone['name']}</TableCell>
                                                                            <TableCell>{zone['zip_codes'] ? zone['zip_codes'].join(", ") : ''}</TableCell>
                                                                            <TableCell width="60px">
                                                                                <IconButton color="primary" size="small"
                                                                                    onClick={(e) => handleZoneEdit(e, zone)}
                                                                                ><EditIcon /></IconButton>
                                                                                <IconButton color="primary" size="small"
                                                                                    onClick={() => handleDeleteZoneConfirmDialog(zone)}
                                                                                ><DeleteIcon /></IconButton>
                                                                            </TableCell>
                                                                        </TableRow>))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            {isDeleteZoneConfirm ? <ConfirmDialog
                                                                msg={'Delete this pickup zone "' + pickupZone.name + '"?'}
                                                                openDialog={isDeleteZoneConfirm}
                                                                confirmBtnLabel={"Delete"}
                                                                onCancel={(v) => setDeleteZoneConfirm(false)}
                                                                onContinue={(v) => handleDeletePickupZone(v)}></ConfirmDialog> : <div />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        {emails.length > 0 ?
                                                            <EmailForm
                                                                {...props}
                                                                formName={formKey}
                                                                subject={emails.find(e => e.id === formKey).subject ? emails.find(e => e.id === formKey).subject : ""}
                                                                message={emails.find(e => e.id === formKey).message ? emails.find(e => e.id === formKey).message : ""}
                                                                sms={emails.find(e => e.id === formKey).sms ? emails.find(e => e.id === formKey).sms : ""}
                                                                title={formatList.find(e => e.name === formKey).display ?? ""}
                                                                valueList={emails.find(e => e.id === formKey).variables ? emails.find(e => e.id === formKey).variables : []}
                                                                enableSendEmail={emails.find(e => e.id === formKey).enable_send_email ? emails.find(e => e.id === formKey).enable_send_email : false}
                                                                enableSendSms={emails.find(e => e.id === formKey).enable_send_sms ? emails.find(e => e.id === formKey).enable_send_sms : false}
                                                                onSave={(value) => { handleSaveEmailFormats(value) }}></EmailForm>
                                                            : <div />}
                                                    </div>}
                                    </div>
                                </div>
                            </CardContent>
                        </Paper>
                    </Grid>
                </Grid>

            </div>
        </LocalOverlay>
    );
}

export default connect()(SettingsPage);