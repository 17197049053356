import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function BoxIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <path d="M509.5 184.6L458.9 32.8C452.4 13.2 434.1 0 413.4 0H272v192h238.7c-.4-2.5-.4-5-1.2-7.4zM240 0H98.6c-20.7 0-39 13.2-45.5 32.8L2.5 184.6c-.8 2.4-.8 4.9-1.2 7.4H240V0zM0 224v240c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V224H0z" />
    </SvgIcon>
  );
}

export function AssignPickupIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 87.736 87.736">
      <path d="M58.514,41.095l-8.387-0.149c-0.618-0.011-1.244-0.557-1.34-1.167l-2.3-14.621c-0.053-0.34-0.149-0.626-0.274-0.863
		c-0.426-1.098-1.487-1.885-2.728-1.885l-13.5,0.586c-1.629,0.071-2.954,1.455-2.954,3.086l0.01,29.591
		c-0.016,1.424-0.082,3.49-0.156,4.806l-0.407,7.213c-0.072,1.271-0.696,3.259-1.364,4.342l-5.928,9.623
		c-0.507,0.823-0.654,1.786-0.413,2.711c0.241,0.926,0.839,1.695,1.684,2.167l1.326,0.739c0.541,0.303,1.159,0.462,1.788,0.462
		c1.297,0,2.518-0.674,3.186-1.758l5.927-9.623c0.828-1.343,1.635-3.621,1.838-5.188l1.707-13.193l1.847-0.18l7.943,12.265
		c0.693,1.072,1.372,3.049,1.482,4.317L48.399,84.7c0.142,1.635,1.33,2.776,2.888,2.776c0.371,0,0.747-0.066,1.119-0.198l1.43-0.507
		c1.783-0.632,3.052-2.603,2.888-4.487l-0.898-10.322c-0.135-1.554-0.765-3.874-1.435-5.282l-7.629-16.041
		c-0.018-0.038-0.042-0.072-0.061-0.109l-0.032-2.852l12.184-0.502c0.809-0.033,1.546-0.383,2.077-0.981
		c0.527-0.596,0.783-1.363,0.72-2.161C61.524,42.442,60.118,41.124,58.514,41.095z"/>
      <path d="M34.321,19.35c1.098,0,2.184-0.188,3.228-0.558c2.436-0.861,4.39-2.621,5.502-4.953c1.113-2.332,1.25-4.958,0.388-7.393
		C42.074,2.591,38.409,0,34.319,0c-1.098,0-2.183,0.187-3.227,0.556c-2.436,0.863-4.39,2.622-5.502,4.954
		c-1.113,2.332-1.25,4.958-0.388,7.392C26.567,16.759,30.232,19.35,34.321,19.35z"/>
      <path d="M68.272,22.931c-0.114-1.634-1.544-2.891-3.198-2.791l-12.76,0.851c-0.797,0.053-1.527,0.414-2.055,1.019
		c-0.528,0.605-0.789,1.378-0.733,2.177l0.796,11.438c0.109,1.568,1.426,2.798,2.998,2.798c0.066,0,0.133-0.003,0.2-0.007
		l12.76-0.851c0.797-0.053,1.527-0.414,2.055-1.019c0.528-0.605,0.789-1.378,0.733-2.177L68.272,22.931z"/>
    </SvgIcon>
  );
}

export function FilterListOffIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M10.83,8H21V6H8.83L10.83,8z M15.83,13H18v-2h-4.17L15.83,13z M14,16.83V18h-4v-2h3.17l-3-3H6v-2h2.17l-3-3H3V6h0.17 L1.39,4.22l1.41-1.41l18.38,18.38l-1.41,1.41L14,16.83z" />
    </SvgIcon>
  );
}

export function QrcodeIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path xmlns="http://www.w3.org/2000/svg" d="M19 2c1.654 0 3 1.346 3 3v14c0 1.654-1.346 3-3 3h-14c-1.654 0-3-1.346-3-3v-14c0-1.654 1.346-3 3-3h14zm0-2h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8 8h-1v-2h1v1h2v1h-1v1h-1v-1zm2 12v-1h-1v1h1zm-1-15v-1h-2v1h1v1h1v-1zm8-1v6h-1v-1h-4v-5h5zm-1 4v-3h-3v3h3zm-14 2h-1v1h2v-1h-1zm0 3h1v1h1v-3h-1v1h-2v2h1v-1zm5 1v2h1v-2h-1zm4-10h-1v3h1v-3zm0 5v-1h-1v1h1zm3-2h1v-1h-1v1zm-10-1h-1v1h1v-1zm2-2v5h-5v-5h5zm-1 1h-3v3h3v-3zm9 5v1h-1v-1h-2v1h-1v-1h-3v-1h-1v1h-1v1h1v2h1v-1h1v2h1v-2h3v1h-2v1h2v1h1v-3h1v1h1v2h1v-1h1v-1h-1v-1h-1v-1h1v-1h-2zm-11 8h1v-1h-1v1zm-2-3h5v5h-5v-5zm1 4h3v-3h-3v3zm12-3v-1h-1v1h1zm0 1h-1v1h-1v-1h-1v-1h1v-1h-2v-1h-1v2h-1v1h-1v3h1v-1h1v-1h2v2h1v-1h1v1h2v-1h1v-1h-2v-1zm-9-3h1v-1h-1v1zm10 2v1h1v1h1v-3h-1v1h-1zm2 4v-1h-1v1h1zm0-8v-1h-1v1h1z" />
    </SvgIcon>
  );
}

export function PackageImport(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 21 21" style={{ fill: "none" }} xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 5.5625V13.4375L8.5 17.375V9.5M1.5 5.5625L8.5 1.625L15.5 5.5625M1.5 5.5625L8.5 9.5M15.5 5.5625V9.5M15.5 5.5625L8.5 9.5M17.25 14.75H11.125M11.125 14.75L13.75 12.125M11.125 14.75L13.75 17.375" stroke={props.stroke?props.stroke: "#808080"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}