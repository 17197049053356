import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import BackIcon from '@material-ui/icons/ArrowBack';
import { Box, IconButton, makeStyles, NativeSelect, Grid, InputBase, withStyles, Paper, TextField, FormControl, FormHelperText } from '@material-ui/core';
import theme from '../../theme';
import { connect, useSelector } from 'react-redux';
import { getPickupDates, getPickupZones } from '../../actions/app';
import AddressCard from '../address_card';
import { addAdminPickupRequest, changeCustomerPickupAddress, setPickupData } from '../../actions/pickup';
import { convertStringToDate, formatDateToLocal, getDay } from '../../util';
import { clearSearchCustomers } from '../../actions/user';
import PhoneInput from 'react-phone-input-2'


import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getStaffsData } from '../../actions/user';
import { setDropoffFormData, createDropoff } from '../../actions/dropoff';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        // border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    table: {
        padding: "10px 20px 10px 20px"
    },
}));

function CreateDropoff(props) {
    const classes = useStyles();
    const { title, history, dispatch } = props;

    const [userType, setUserType] = React.useState("existing");
    const [isNewUser, setIsNewUser] = React.useState(false);

    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const selectedCustomer = useSelector(state => state.user.selectedCustomer);
    const staffs = useSelector(state => state.user.staffs);
    const defalutCountry = useSelector(state => state.app.defalutCountry);
    const countries = useSelector(state => state.app.countries);

    const [staffId, setStaffId] = useState();

    const data = useSelector(state => state.dropoff.dropoffFormData)

    const [customerRemark, setCustomerRemark] = useState();

    const [customerFCSId, setCustomerFCSId] = useState();
    const [customerID, setCustomerID] = useState();
    const [customerName, setCustomerName] = useState();
    const [customerPhoneNumber, setCustomerPhoneNumber] = useState();

    const [newCustomerName, setNewCustomerName] = useState();
    const [newCustomerPhoneNumber, setNewCustomerPhoneNumber] = useState();

    const [error, setError] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        dispatch(getStaffsData());
    }, [dispatch]);

    useEffect(() => {
        let isNewCustomer = false;
        if (data) {
            setNewCustomerName(data.customer_name);
            setNewCustomerPhoneNumber(data.customer_phone_number);
            setCustomerRemark(data.customer_remark);
            setStaffId(data.receiving_staff_id);
            isNewCustomer = data.is_new_customer === true;
        }
        setIsNewUser(isNewCustomer);
        setUserType(isNewCustomer ? "new" : "existing");
    }, [data]);

    useEffect(() => {
        if (selectedCustomer.id) {
            setCustomerID(selectedCustomer.id);
            setCustomerFCSId(selectedCustomer.fcs_id);
            setCustomerName(selectedCustomer.user_name);
            setCustomerPhoneNumber(selectedCustomer.phone_number);
        }
    }, [selectedCustomer]);

    const handleChangeStaff = (e) => {
        dispatch(setDropoffFormData({ "receiving_staff_id": e.target.value }));
    }

    const handleCustomerNameChange = (e) => {
        dispatch(setDropoffFormData({ "customer_name": e.target.value }));
    }

    const handleCustomerPhoneNumberChange = (e) => {
        dispatch(setDropoffFormData({ "customer_phone_number": e.target.value }));
    }

    const handleRemark = (e) => {
        dispatch(setDropoffFormData({ "customer_remark": e.target.value }));
    }
    const handleBack = () => {
        history.goBack();
    }

    const handleCreateDropOff = () => {
        let isError = false;
        var errorMsg = [];
        if (isNewUser) {
            if (!newCustomerName || newCustomerName.trim() === "") {
                isError = true;
                errorMsg['customer_name'] = "Customer Name is required";
            }
            if (!newCustomerPhoneNumber || newCustomerPhoneNumber.trim() === "") {
                isError = true;
                errorMsg['customer_phone_number'] = "Customer Phone Number is required";
            }
        } else {
            if (!customerID) {
                isError = true;
                errorMsg['customer'] = "Customer is required";
            }
        }
        if (!staffId || staffId.trim() === "") {
            isError = true;
            errorMsg['staff_id'] = "Receving Staff is required";
        }

        setErrorMessages(errorMsg);
        setError(isError);

        if (!isError) {
            setError(false);
            setErrorMessages([]);
            var d = {
                "customer_name": newCustomerName,
                "customer_phone_number": newCustomerPhoneNumber,
                "customer_id": customerID,
                "customer_remark": customerRemark,
                "receiving_staff_id": staffId,
                "is_new_customer": isNewUser
            };
            console.log("create drop off:", d);
            dispatch(createDropoff(d, () => {
                history.goBack();
            }));
        }
    }

    const handleSelectCustomer = () => {
        dispatch(clearSearchCustomers());
        history.push("/search-customers");
    }

    const handleUserTypeChange = (event) => {
        dispatch(setDropoffFormData({ "is_new_customer": event.target.value == "new" }));
    };

    const handlePhoneNumber = (p) => {
        dispatch(setDropoffFormData({ "customer_phone_number": "+" + p }));
    }

    return (
        <div className={classes.root}>
            <Box display="flex" p={1}>
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table className={classes.table}>
                <tbody>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <FormControl error={error}>
                                    <RadioGroup row aria-label="position" name="user-type" value={userType}
                                        onChange={handleUserTypeChange}>
                                        <FormControlLabel value="existing" control={<Radio color="primary" />} label="Existing Customer" />
                                        <FormControlLabel value="new" control={<Radio color="primary" />} label="New Customer" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </td>
                    </tr>
                    {isNewUser ?
                        <tr>
                            <td style={{ textAlign: 'left', paddingTop: '10px' }}>
                                <FormControl error={error}>
                                    <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Customer Name'}</Typography>
                                    <TextField id="outlined-basic" variant="outlined" margin='dense' style={{ backgroundColor: "white" }}
                                        value={newCustomerName !== undefined ? newCustomerName : '' || ''}
                                        onChange={handleCustomerNameChange} />
                                    {error && errorMessages['customer_name'] ? <FormHelperText style={{ color: "red" }}>{errorMessages['customer_name']}</FormHelperText> : <span />}
                                </FormControl>
                            </td>
                        </tr> : <tr />
                    }
                    {isNewUser ?
                        <tr>
                            <td style={{ textAlign: 'left', paddingTop: '10px' }}>
                                <FormControl error={error}>
                                    <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Customer Phone Number'}</Typography>
                                    <PhoneInput
                                        value={newCustomerPhoneNumber !== undefined ? newCustomerPhoneNumber : '' || ''}
                                        country={defalutCountry}
                                        onlyCountries={countries}
                                        onChange={(e) => handlePhoneNumber(e)}
                                        style={{ border: error && errorMessages['customer_phone_number'] ? "1px solid red" : null, borderRadius: "4px" }}
                                    />
                                    {error && errorMessages['customer_phone_number'] ? <FormHelperText style={{ color: "red" }}>{errorMessages['customer_phone_number']}</FormHelperText> : <span />}
                                </FormControl>
                            </td>
                        </tr> : <tr />
                    }
                    {!isNewUser ?
                        <tr style={{ height: '50px' }}>
                            <td style={{ textAlign: 'left' }}>
                                <div style={{ marginBottom: '5px' }}>
                                    <div>
                                        <FormControl error={error}>
                                            <Box style={{ display: 'inline-flex', marginTop: '5px' }}>
                                                <Typography style={{ color: 'grey', paddingRight: '30px' }}>{'Customer'}</Typography>
                                                <Button size="small" variant="outlined" color="primary" style={{ float: 'left', textTransform: 'none' }}
                                                    onClick={handleSelectCustomer}
                                                >Select Customer</Button>
                                            </Box>
                                            {error && errorMessages['customer'] ? <FormHelperText style={{ color: "red" }}>{errorMessages['customer']}</FormHelperText> : <span />}
                                        </FormControl>
                                    </div>
                                </div>
                            </td>
                        </tr> : <tr />
                    }
                    {customerID && !isNewUser ? <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'FCS ID'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{customerFCSId ? customerFCSId : ''}</Typography>

                                </Box>
                            </div>
                        </td>
                    </tr> : <tr />}
                    {customerID && !isNewUser ? <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Name'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{customerName ? customerName : ''}</Typography>

                                </Box>
                            </div>
                        </td>
                    </tr> : <tr />}
                    {customerID && !isNewUser ? <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Phone Number'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{customerPhoneNumber ? customerPhoneNumber : ''}</Typography>
                                </Box>

                            </div>
                        </td>
                    </tr> : <tr />}
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left', paddingTop: '10px' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <FormControl error={error}>
                                    <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Received By'}</Typography>
                                    <NativeSelect
                                        id="staff-select"
                                        value={staffId ? staffId : ""}
                                        onChange={handleChangeStaff}
                                        style={{ width: '200px', border: error ? !staffId ? "1px solid red" : "1px solid #ced4da" : "1px solid #ced4da", borderRadius: error ? "4px" : "4px" }}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" >Select Staff</option>
                                        {staffs.map((s) => {
                                            return <option key={s.id} value={s.id}>{s.user_name}</option>
                                        })}
                                    </NativeSelect>
                                    {error && errorMessages['staff_id'] ? <FormHelperText style={{ color: "red" }}>{errorMessages['staff_id']}</FormHelperText> : <span />}
                                </FormControl>
                            </div>
                        </td>
                    </tr>

                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left', paddingTop: '10px' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Customer Remark'}</Typography>
                                <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    size={"small"}
                                    value={customerRemark !== undefined ? customerRemark : '' || ''}
                                    style={{ width: '400px' }}
                                    multiline
                                    minRows={3}
                                    onChange={handleRemark}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        color="primary"
                                        onClick={handleCreateDropOff}
                                        startIcon={<SaveIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Create Drop-Off"} </Button>
                                </Box>

                            </Grid>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div >
    );
}

CreateDropoff.propTypes = {
    history: PropTypes.object,
    open: PropTypes.bool,
    callback: PropTypes.func
};

export default connect()(CreateDropoff);