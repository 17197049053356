import React, { useEffect, useState } from 'react';
import { makeStyles, Button, Chip, IconButton, Dialog, DialogContent, DialogActions, DialogTitle, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { checkZipCodeInZones, setShowAlert } from '../../actions/app';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    chipContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        width: '300px',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    }
}));


function PickupZoneDialog(props) {
    const { data, onClose, onSave, open, dispatch } = props;
    const [pickupZone, setPickupZone] = useState(data);
    const [zipCode, setZipCode] = useState('');
    const [zipCodes, setZipCodes] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        if (data) {
            const localData = { ...data };
            setPickupZone(localData);
            setZipCodes(localData.zip_codes ? localData.zip_codes : []);
        }
    }, [data, open]);


    const handleClose = () => {
        setZipCode("");
        onClose(false);
    }
    const handleSave = () => {
        pickupZone["zip_codes"] = zipCodes;
        setZipCode("");
        onSave(pickupZone)
    }

    const handleOnChangePickupZone = (e) => {
        var name = e.target.value;
        setPickupZone({ ...pickupZone, 'name': name });
    }

    const handleChangeZipCode = (e) => {
        var v = e.target.value;
        // only allow number 0-9
        var reg = /^\d+$/;
        if (!reg.test(v)) return;
        setZipCode(v);
    }

    const handleDeleteZipCode = (value) => {
        const _zipCodes = zipCodes.filter(word => word !== value);
        setZipCodes(_zipCodes);
    }
    const handleAddZipCode = () => {
        if (!zipCode || zipCode.length < 5) {
            dispatch(setShowAlert({ show: true, msg: "invalid zip code", color: "red" }));
            return
        }
        const _zipCodes = [...zipCodes];

        if (data.id) {
            dispatch(checkZipCodeInZones(zipCode, (_exist) => {
                if (!_exist) {
                    _zipCodes.push(zipCode);
                    setZipCode("");
                    setZipCodes(_zipCodes);
                }
            }));
        } else {
            dispatch(checkZipCodeInZones(zipCode, (_exist) => {
                if (!_exist) {
                    if (_zipCodes.includes(zipCode)) {
                        dispatch(setShowAlert({ show: true, msg: "zip code is already exist", color: "red" }));
                        return
                    }
                    _zipCodes.push(zipCode);
                    setZipCode("");
                    setZipCodes(_zipCodes);
                }
            }));

        }




    }

    zipCodes.sort((a, b) => {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    });

    return <div>
        <div style={{ textAlign: 'start' }}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Pickup Zone"}</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
                        <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Pickup Zone'}</Typography>
                        <TextField
                            variant="outlined"
                            autoComplete="off"
                            size={"small"}
                            value={pickupZone != null ? pickupZone['name'] : '' || ''}
                            style={{ width: '200px' }}
                            onChange={(e) => handleOnChangePickupZone(e)}
                        />
                    </div>
                    <div style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
                        <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Zip Codes'}</Typography>
                        <TextField
                            variant="outlined"
                            autoComplete="off"
                            size={"small"}
                            value={zipCode ? zipCode : ''}
                            style={{ width: '100px' }}
                            onChange={(e) => handleChangeZipCode(e)}
                        />
                        <IconButton color="primary" size="small" title='Add zip code'
                            onClick={handleAddZipCode}
                        > <AddCircleOutlineIcon /></IconButton>
                    </div>
                    <div className={classes.chipContainer} style={{ display: "flex", height: '37px', marginBottom: '10px', justifyContent: 'left' }}>
                        {zipCodes.map((value, i) => {
                            return <Chip key={i} label={value} onDelete={() => handleDeleteZipCode(value)} color="primary" variant="outlined"
                                deleteIcon={<IconButton title="Remove zip code"><RemoveCircleOutlineIcon /></IconButton>} />
                        })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    </div>;
}

PickupZoneDialog.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func
}

export default connect()(PickupZoneDialog);