import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { updateUserEmail, verifyUserEmail } from '../../actions/user';
import theme from '../../theme';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';
import LocalOverlay from '../local_overlay';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: '10px 10px 20px 10px'
  },
  table: {
    padding: "10px 20px 10px 20px"
  },
}));

const UpdateEmail = props => {
  const classes = useStyles();
  const { dispatch, history, title } = props;
  const selector = useSelector(state => state.app);
  const [user, setUser] = useState(selector.user);
  const stateIsUpdating = useSelector(state => state.app.isUpdating);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [userEmail, setEmail] = React.useState('');

  useEffect(() => {
    setUser(selector.user);
  }, [selector.user]);

  const handleUpdateEmail = () => {
    var value = {
      "id": user.id,
      "email": userEmail
    };

    dispatch(updateUserEmail(value, (result) => {
      if (result.email_verification === "Verified") {
        history.goBack();
      } else {
        setOpen(true);
      }
    }));
  }

  const handleEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleVerifyEmail = (e) => {
    setLoading(true);
    var value = { "id": user.id, "email": userEmail, "email_verification_code": user.email_verification_code };

    dispatch(verifyUserEmail(value, () => {
      setLoading(false);
      history.goBack();
    }));
  }

  const handleVerifyCode = (e) => {
    setUser({ ...user, 'email_verification_code': e.target.value });
  }

  const handleBack = () => {
    history.goBack();
  }

  return (
    <LocalOverlay isActive={loading}>
      <div className={classes.root}>
        <Box display="flex" p={1}>
          <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
          <Box flexGrow={1}>
            <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
        </Box>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td>
                <div style={{ marginBottom: '10px' }}>
                  <Box display="flex">
                    <TextField
                      variant="outlined"
                      autoComplete="off"
                      label="Email"
                      size={"small"}
                      placeholder=""
                      value={userEmail !== undefined ? userEmail || '' : ''}
                      style={{ width: '200px', marginRight: "5px" }}
                      onChange={handleEmail}
                    />
                  </Box>
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>
                <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                  <Box >
                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                      color="primary"
                      onClick={handleUpdateEmail}
                      startIcon={<SaveIcon />}
                    >{stateIsUpdating ? "Updating..." : "Update email"} </Button>
                  </Box>

                </Grid>
              </td>
            </tr>
          </tbody>
        </table>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Enter Verification Code"}</DialogTitle>
          <DialogContent>
            <div style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
              <TextField
                variant="outlined"
                label="Verification Code"
                autoComplete="off"
                size={"small"}
                value={user.email_verification_code ? user.email_verification_code : ""}
                style={{ width: '200px' }}
                onChange={handleVerifyCode}
              />

            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleVerifyEmail} color="primary" autoFocus>
              Verify
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </LocalOverlay>
  );
};

UpdateEmail.propTypes = {
  title: PropTypes.string
};

export default connect()(UpdateEmail);
