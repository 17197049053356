import { Box, IconButton, Button, Typography, Grid, Paper, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import RefreshIcon from '@material-ui/icons/Refresh';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import theme from '../../theme';
import { convertISOToDate, getTimeFormDate, removeFCSString } from '../../util';
import AddressCard from '../address_card';
import { getDropoffDetail } from '../../actions/dropoff';
import PrintDropoffQrcode from './print_dropoff_qrcode';
import { QrcodeIcon } from '../../icons';
import ShowPhotoDialog from '../show_photo_dialog';

const useStyles = makeStyles(th => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
}));

const MyDropoffDetail = (props) => {
    const { title, history, dispatch, smallScreen } = props;
    const classes = useStyles();
    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const selector = useSelector(state => state.dropoff);
    const [dropoffDetail, setDropoffDetail] = React.useState(selector.dropoffDetail);
    const [open, setOpen] = React.useState(false);
    const [selectedPhoto, setSelectedPhoto] = React.useState(dropoffDetail['photo_urls'] ? dropoffDetail['photo_urls'][0] : "#");

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var dropoffId = pathArr[2];

    const handleBack = () => {
        history.goBack();
    }

    useEffect(() => {
        dispatch(getDropoffDetail(dropoffId));
    }, [dispatch, dropoffId]);

    useEffect(() => {
        setDropoffDetail(selector.dropoffDetail);
        var data = selector.dropoffDetail;
    }, [selector]);


    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <div className={classes.root}>
            <Box display="flex" p={1} >
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table style={{ padding: smallScreen ? '10px' : '30px' }}>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{dropoffDetail.dropoff_number !== undefined ? dropoffDetail.dropoff_number : ''}</Typography>
                                </Box>
                                <Box display="flex" >
                                    <Typography style={{ minWidth: "30px", padding: '2px', color: 'grey', textAlign: 'left', border: '#807979 1px solid', borderRadius: '5px' }}>{dropoffDetail.status !== undefined ? dropoffDetail.status : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Customer'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{dropoffDetail.customer_fcs_id !== undefined ? removeFCSString(dropoffDetail.customer_fcs_id) : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{dropoffDetail.customer_name !== undefined ? dropoffDetail.customer_name : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Staff'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{dropoffDetail.receiving_staff_name ? dropoffDetail.receiving_staff_name : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Remark'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ minWidth: '150px', padding: '10px', textAlign: 'left', whiteSpace: 'pre-line', border: '1px solid grey', borderRadius: '5px' }}>{dropoffDetail.customer_remark ? dropoffDetail.customer_remark : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Photos'}</Typography>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: '5px', flexWrap: 'wrap', }}>
                                    {
                                        dropoffDetail['photo_urls'] ? dropoffDetail['photo_urls'].map((i, index) =>
                                            <Box key={index} style={{ marginRight: '5px', marginBottom: "5px" }}>
                                                <img id="output" alt={i.name}
                                                    style={{ padding: '10px', height: "100px", width: "100px", border: "1px solid lightgrey" }}
                                                    src={i}
                                                    onClick={(e) => {
                                                        setSelectedPhoto(i);
                                                        setOpen(true);
                                                    }
                                                    } /></Box>
                                        )
                                            : <span />}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <ShowPhotoDialog
                open={open}
                selectedPhoto={selectedPhoto}
                photoUrls={dropoffDetail.photo_urls}
                onClose={(v) => { setOpen(false) }}
                onChangeSelectedPhoto={(v) => { setSelectedPhoto(v) }}
            ></ShowPhotoDialog>
        </div>
    );
};

MyDropoffDetail.propTypes = {
    pDetail: PropTypes.object,
    title: PropTypes.string
};

export default withRouter(connect()(MyDropoffDetail));
