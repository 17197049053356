import { Box, Card, CardContent, Grid } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { trackPackages } from '../../actions/tracking';
import theme from '../../theme';
import { formatDateToLocal } from "../../util";


class TrackPackageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      open: false,
      photo_urls: [],
      selected_photo: ''
    };

  }

  updateInput = (e) => {
    this.setState({ value: e });
    console.log('value => ', this.state.value);

  }

  handelTrackPackages = () => {
    let { dispatch } = this.props;
    dispatch(trackPackages(this.state.value));
  }

  handleClickOpen = (e, urls,index) => {
    console.log('handleClickOpen');
    this.setState({ open: !this.state.open, photo_urls: urls, selected_photo: urls[index] });
  };

  handleClose = (e) => {
    this.setState({ open: !this.state.open });
  };

  handleSelectedPhoto = (e, imgUrl) => {
    this.setState({ selected_photo: imgUrl });
  };

  handleClick = (event, id) => {
    let { history } = this.props;
    event.preventDefault();
    var pathName = '/package-detail/' + id;
    history.push(pathName);
  };


  render() {
    const styles = {
      root: {
        flexGrow: 1,
      },
      button: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        width: 150,
        height: 55
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
      headText: {
        fontSize: 14,
        color: "grey",
        textAlign: 'left',
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    };

    const DialogTitle = withStyles(styles)((props) => {
      const { children, classes, onClose, ...other } = props;
      return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
          <Typography variant="h6">{children}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
    });

    const DialogContent = withStyles((theme) => ({
      root: {
        padding: theme.spacing(2),
      },
    }))(MuiDialogContent);

    var packages = this.props.packages;

    return !packages ? <div></div> : (
      <div className="package-list">
        {packages.map((p) => {
          var photoUrls=[];
          if (p['photo_urls']) {
            if (Array.isArray(p['photo_urls'])){
              photoUrls=p['photo_urls'];
            }else if(typeof p['photo_urls'] === 'string' || p['photo_urls'] instanceof String) {
              try{
              photoUrls=JSON.parse(p['photo_urls']);              
              }catch(e){}
            }
          }

          return <Card className="package-list-row" key={p.id}>
            <CardContent style={{ padding: '10px' }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
                style={styles.root}
              >
                <Grid item xs={12} sm={4}>
                  <div style={{ marginBottom: "5px", textAlign: 'left' }}>
                    <Typography style={styles.headText}>Tracking number</Typography>
                    <Typography>{p['remark'] === 'Not found' ? p['tracking_id'] : <a href={"/package-detail/" + p["id"]} target={"_blank"}> {p['tracking_id']}</a>}</Typography>
                  </div>
                  <div style={{ marginBottom: "5px", textAlign: 'left' }}>
                    <Typography style={styles.headText}>Received date</Typography>
                    <Typography style={p['remark'] === 'Not found' ? { color: "red", fontSize: 14 } : { fontSize: 14 }}> {formatDateToLocal(Date.parse(p['received_date']), false)}</Typography>
                  </div>

                  <div style={{ marginBottom: "5px", textAlign: 'left' }}>
                    <Typography style={styles.headText}>Remark</Typography>
                    <Typography
                      style={p['remark'] === 'Not found' ? { color: "red", fontSize: 14 } : { fontSize: 14 }}>
                      {p['remark']}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Grid container spacing={3}>
                    {photoUrls.map((value, index) => (
                      <Grid key={"img-" + index} item>
                        <Box onClick={(e) => this.handleClickOpen(e,photoUrls,index)} >
                          <img src={value} className="item-img" alt="logo" style={{ width: '100px', height: '100px' }} /></Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        })}
        <Dialog maxWidth="lg" aria-labelledby="customized-dialog-title" open={this.state.open}>
          <DialogTitle id="customized-dialog-title" onClose={(e) => this.handleClose()} >
            Photos
          </DialogTitle>
          <DialogContent dividers>
            <Grid item xs={12}>
              <Grid>
                <img src={this.state.selected_photo} className="show-img" alt="logo" />
              </Grid>
              <br />
              <Grid container spacing={3}>
                {this.state.photo_urls.map((value) => (
                  <Grid key={value} item>
                    <Box className="square" > <img src={value} className="thumnail-img" alt="logo" onClick={(e) => this.handleSelectedPhoto(e, value)} /></Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

TrackPackageList.propTypes = {
  packages: PropTypes.array.isRequired,
  history: PropTypes.object,
}

export default withRouter(TrackPackageList);