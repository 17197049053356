import { combineReducers } from "redux";
import tracking from "./tracking";
import app from "./app";
import explore from "./explore";
import user from "./user";
import pickup from "./pickup";
import invoice from "./invoice";
import report from "./report";
import template from "./template";
import sms from "./sms";
import dropoff from "./dropoff";

export default combineReducers({
    tracking,
    app,
    explore,
    user,
    pickup,
    invoice,
    report,
    template,
    sms,
    dropoff
});