import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React from 'react';
import { getCityStateZipCode, getFullAddress } from '../../util';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { formatDateToLocal, removeFCSString } from '../../util';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
    },
    section: {
        margin: 0,
        padding: 0,
        flexGrow: 1
    },
    textSection: {
        margin: 0,
        padding: 0,
        paddingLeft: 1,
        fontFamily: "Times-Bold",
        fontSize: '9px',
        flexDirection: 'column',
        flexWrap: "wrap",
        width: 98,
    },
    QRImage: {
        width: '70',
        height: '70',
    },
    Label: {
        fontSize: '8px',
    },
    text1: { fontSize: '13px' },
    text2: { fontSize: '11px' }
});

const PdfPage = (props) => {
    var { dropoff, dataURL } = props;
    var url = document.getElementById(dataURL).toDataURL();
    return (
        <Document>
            <Page style={styles.page} size={[170, 72]}>
                <View style={styles.section}>
                    <Image AllowDangerousPaths src={url} style={styles.QRImage}></Image>
                </View>
                <View style={styles.textSection} wrap>
                    <Text wrap style={styles.text1}>{dropoff.dropoff_number}</Text>
                    <Text wrap style={styles.text2}>{dropoff.customer_name}</Text>
                    <Text wrap style={styles.text2}>{formatDateToLocal(dropoff.created_date,false)}</Text>
                </View>
            </Page>
        </Document>
    );
}

const PrintDropoffQrcode = (props) => {
    var { dropoff, onClose, open } = props;
    var origin = window.location.origin;
    // var pickupUrl = origin + '/pickup-info/' + pickup.pickup_number;
    var pickupUrl = dropoff.dropoff_number;
    console.log("dropoff:", dropoff);

    const handleClose = () => {
        onClose(false);
        return false;
    }
    return (
        <div style={{ display: 'block' }} >
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <div id="print-qrcode">
                        <div style={{ width: 200 }}>
                            <div style={{ float: "left" }}>
                                <QRCode id="qrcode-doc" value={pickupUrl} size={100} level={"H"} includeMargin={false} renderAs="canvas" />
                            </div>
                            <div id="text-box" style={{ fontSize: "12px", paddingLeft: "5px", width: 80, float: "left", textAlign: "left" }}>
                                <p style={{ margin: 1, fontSize: "18px" }}>{dropoff.dropoff_number}</p>
                                <p style={{ margin: 1, fontSize: "16px" }}>{formatDateToLocal(dropoff.created_date,false)}</p>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    <PDFDownloadLink document={<PdfPage  {...props} dataURL={"qrcode-doc"} />} fileName={dropoff.dropoff_number + ".pdf"} style={{ textDecoration: "none" }}>
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' :
                                <Button color="primary">
                                    Print
                                </Button>
                        }
                    </PDFDownloadLink>
                </DialogActions>
                <iframe id="printingQrcodeFrame" style={{
                    height: '0px',
                    width: '0px',
                    position: 'absolute'
                }}
                    title={'qrcode'}
                ></iframe>
            </Dialog>
        </div>
    );
}

PrintDropoffQrcode.propTypes = {
    pickup: PropTypes.object
};

export default PrintDropoffQrcode;
