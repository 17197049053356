
import firebase from "../firebase_config";
import { config } from "../firebase_config";
import 'firebase/firestore';
import { formatDateToUTC } from "../util";
import { SET_IS_UPDATING, SET_SHOW_ALERT } from "./app";

export const GET_EXPLORE_PACKAGES = "GET_EXPLORE_PACKAGES";
export const GET_EXPLORE_ROW_COUNT = "GET_EXPLORE_ROW_COUNT";
export const GET_EXPLORE_PACKAGE_DETAIL = 'GET_EXPLORE_PACKAGE_DETAIL';
export const GET_EXPLORE_FILTERS = "GET_EXPLORE_FILTERS";
export const CHANGE_EXPLORE_ORDER = "CHANGE_EXPLORE_ORDER";
export const CHANGE_EXPLORE_PAGE = "CHANGE_EXPLORE_PAGE";
export const CLEAR_SELECTED_USER = "CLEAR_SELECTED_USER";
export const GET_SELECTED_USER = "GET_SELECTED_USER";
export const SET_PACKAGE_SEARCH_TERM = "SET_PACKAGE_SEARCH_TERM";

export const SET_MYPACKAGE_SEARCH_TERM = "SET_MYPACKAGE_SEARCH_TERM";

export const GET_MYPACKAGES = "GET_MYPACKAGES";
export const GET_MYPACKAGES_ROW_COUNT = "GET_MYPACKAGES_ROW_COUNT";
export const CHANGE_MYPACKAGES_ORDER = "CHANGE_MYPACKAGES_ORDER";
export const CHANGE_MYPACKAGES_PAGE = "CHANGE_MYPACKAGES_PAGE";

export const GET_RECENT_PACKAGES = "GET_RECENT_PACKAGES";

var _message = '';
var _color = '';

export const getPackageDetail = (id) => (dispatch, getState) => {
    dispatch({
        type: GET_EXPLORE_PACKAGE_DETAIL,
        payload: {
            packageDetail: {}
        }
    })
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            firebase.firestore().collection("packages").doc(id)
                .get()
                .then(function (doc) {
                    var packageDetail = doc.data();
                    packageDetail['id'] = doc.id;
                    dispatch({
                        type: GET_EXPLORE_PACKAGE_DETAIL,
                        payload: {
                            packageDetail
                        }
                    })
                }).catch(function (error) {
                    console.log("Error getting documents: ", error);
                });
        }
    });
}

export const getExploreDataSort = (order) => (dispatch, getState) => {
    dispatch(getExploreData(getState().explore.filters, order));
}

export const getExploreData = (filters, order) => (dispatch, getState) => {
    dispatch({
        type: GET_EXPLORE_PACKAGES,
        payload: {
            packages: []
        }
    });
    if (!order) {
        order = getState().explore.order;
    }
    if (!filters) {
        filters = getState().explore.filters;
    }
    dispatch(changeExploreOrder(order));
    dispatch(setFilters(filters));

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;

        var offset = getState().explore.page * getState().app.rowsPerPage;
        var limit = getState().app.rowsPerPage;
        var sorts = [];
        if (order && order['field_name'] && order['field_name'] !== ""
            && order['direction'] && order['direction'] !== "") {
            sorts = [order['field_name'] + " " + order['direction']];
        }
        var _filters = [];
        _filters.push({ 'field': 'is_deleted', 'compare': "=", 'value': "false" });

        // if user is selected, filter by user
        var _user = getState().explore.user;
        if (_user.id) {
            var _orfilters = [];
            _orfilters.push({ 'field': 'user_id', 'compare': "=", 'value': _user.id });
            _orfilters.push({ 'field': 'sender_id', 'compare': "=", 'value': _user.id });
            _filters.push({ 'or_group': true, 'filters': _orfilters });
        }
        if (filters) {
            for (var i = 0; i < filters.length; i++) {
                var filter = filters[i];
                if (filter.key === 'tracking_id' && filter.value && filter.value !== '') {
                    var _trackingId = filter.value;
                    if (filter.value.indexOf("*") > -1) {
                        _trackingId = _trackingId.replace(/\*/g, '%');
                    } else {
                        _trackingId = "%" + _trackingId + "%";
                    }
                    _filters.push({ 'field': 'tracking_id', 'compare': "like", 'value': _trackingId });
                }
                if (filter.key === 'status' && filter.value && filter.value !== '') {
                    _filters.push({ 'field': 'status', 'compare': "=", 'value': filter.value });
                }
                if (filter.key === 'from_received_date' && filter.value && filter.value !== '') {
                    _filters.push({ 'field': 'received_date', 'compare': ">=", 'value': formatDateToUTC(new Date(filter.value)) });
                }

                if (filter.key === 'to_received_date' && filter.value && filter.value !== '') {
                    var d = new Date(filter.value);
                    d.setDate(d.getDate() + 1);
                    var df = formatDateToUTC(d);
                    _filters.push({ 'field': 'received_date', 'compare': "<=", 'value': df });
                }

                if (filter.key === 'from_updated_date' && filter.value && filter.value !== '') {
                    _filters.push({ 'field': 'updated_date', 'compare': ">=", 'value': formatDateToUTC(new Date(filter.value)) });
                }

                if (filter.key === 'to_updated_date' && filter.value && filter.value !== '') {
                    var ud = new Date(filter.value);
                    ud.setDate(ud.getDate() + 1);
                    var udUTC = formatDateToUTC(ud);
                    _filters.push({ 'field': 'updated_date', 'compare': "<=", 'value': udUTC });
                }
            }
        }

        var data = { limit: limit, offset: offset, sorts: sorts, filters: _filters };
        fetch(config.fcsReportUrl + "/api/data/packages", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var packages = [];
                if (result && result.data) {
                    result.data.forEach(function (p) {
                        p["sn"] = ++offset;
                        packages.push(p);
                    });
                }
                dispatch({
                    type: GET_EXPLORE_PACKAGES,
                    payload: {
                        packages
                    }
                });
            }).catch((e) => {
                console.log("error:", e);
            });

        fetch(config.fcsReportUrl + "/api/row/packages", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var count = 0;
                if (result && result.data) {
                    count = result.data;
                }
                dispatch({
                    type: GET_EXPLORE_ROW_COUNT,
                    count
                });
            }).catch((e) => {
                console.log("error:", e);
            });
    });
}

export const downloadData = () => (dispatch, getState) => {
    var _fields = ["tracking_id", "strftime('%m/%d/%Y %H:%M ET',datetime(received_date,'-5 hours'))", 
    "strftime('%m/%d/%Y %H:%M ET',datetime(updated_date,'-5 hours'))", "status"];
    var _headers = ["Tracking ID", "Received Date", "Updated Date", "Status"];
    var _sorts = [];
    var _filters = [];
    _filters.push({ 'field': 'is_deleted', 'compare': "=", 'value': "false" });

    // if user is selected, filter by user
    var _user = getState().explore.user;
    if (_user.id) {
        var _orfilters = [];
        _orfilters.push({ 'field': 'user_id', 'compare': "=", 'value': _user.id });
        _orfilters.push({ 'field': 'sender_id', 'compare': "=", 'value': _user.id });
        _filters.push({ 'or_group': true, 'filters': _orfilters });
    }

    var filters = getState().explore.filters;
    if (filters) {
        for (var i = 0; i < filters.length; i++) {
            var filter = filters[i];
            if (filter.key === 'tracking_id' && filter.value && filter.value !== '') {
                var _trackingId = filter.value;
                if (filter.value.indexOf("*") > -1) {
                    _trackingId = _trackingId.replace(/\*/g, '%');
                } else {
                    _trackingId = "%" + _trackingId + "%";
                }
                _filters.push({ 'field': 'tracking_id', 'compare': "like", 'value': _trackingId });
            }
            if (filter.key === 'status' && filter.value && filter.value !== '') {
                _filters.push({ 'field': 'status', 'compare': "=", 'value': filter.value });
            }

            if (filter.key === 'from_received_date' && filter.value && filter.value !== '') {
                _filters.push({ 'field': 'received_date', 'compare': ">=", 'value': formatDateToUTC(new Date(filter.value)) });
            }

            if (filter.key === 'to_received_date' && filter.value && filter.value !== '') {
                var d = new Date(filter.value);
                d.setDate(d.getDate() + 1);
                var df = formatDateToUTC(d);
                _filters.push({ 'field': 'received_date', 'compare': "<=", 'value': df });
            }

            if (filter.key === 'from_updated_date' && filter.value && filter.value !== '') {
                _filters.push({ 'field': 'updated_date', 'compare': ">=", 'value': formatDateToUTC(new Date(filter.value)) });
            }

            if (filter.key === 'to_updated_date' && filter.value && filter.value !== '') {
                var ud = new Date(filter.value);
                ud.setDate(ud.getDate() + 1);
                var udUTC = formatDateToUTC(ud);
                _filters.push({ 'field': 'updated_date', 'compare': "<=", 'value': udUTC });
            }
        }
    }
    var order = getState().explore.order;
    if (order && order['field_name'] && order['field_name'] !== ""
        && order['direction'] && order['direction'] !== "") {
        _sorts = [order['field_name'] + " " + order['direction']];
    }

    var data = {
        "fields": _fields,
        "headers": _headers,
        "sorts": _sorts,
        "filters": _filters,
        "download_file_type": "CSV"
    }

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsReportUrl + "/api/download/packages", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/csv',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.blob())
            .then((blob) => {

                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                var fileName = 'packages_report.csv';
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }).catch((e) => {
                console.log("error:", e);
            });

    });
}

export const getSelectedUser = (id) => (dispatch, getState) => {
    dispatch({
        type: GET_SELECTED_USER,
        user: {}
    });

    firebase.firestore().collection("users").doc(id)
        .get()
        .then(function (doc) {
            var _user = doc.data();
            _user['id'] = doc.id;

            dispatch({
                type: GET_SELECTED_USER,
                user: _user
            })
            dispatch(getExploreData());

        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });

}

export const clearSelectedUser = (user) => (dispatch, getState) => {
    dispatch({
        type: CLEAR_SELECTED_USER,
        user
    });
};

export const setFilters = (filters) => (dispatch, getState) => {
    dispatch({
        type: GET_EXPLORE_FILTERS,
        filters
    })
};

export const changeExploreOrder = (order) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_EXPLORE_ORDER,
        order
    });
}

export const changeExplorePage = (page) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_EXPLORE_PAGE,
        page
    });
}

export const changeMyPakagesOrder = (order) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_MYPACKAGES_ORDER,
        order
    });
}

export const changeMyPakagesPage = (page) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_MYPACKAGES_PAGE,
        page
    });
}

export const getMyPackagesData = (order) => (dispatch, getState) => {
    if (!order) {
        order = getState().explore.myPackagesOrder;
    }
    var _user = getState().app.user;
    if (!_user || !(_user.id)) return;

    dispatch(changeMyPakagesOrder(order));

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;

        var offset = getState().explore.myPackagesPage * getState().app.rowsPerPage;
        var limit = getState().app.rowsPerPage;
        var sorts = [];
        if (order && order['field_name'] && order['field_name'] !== ""
            && order['direction'] && order['direction'] !== "") {
            sorts = [order['field_name'] + " " + order['direction']];
        }
        var _filters = [];

        var _orfilters = [];
        _orfilters.push({ 'field': 'user_id', 'compare': "=", 'value': _user.id });
        _orfilters.push({ 'field': 'sender_id', 'compare': "=", 'value': _user.id });
        _filters.push({ 'field': 'is_deleted', 'compare': "=", 'value': "false" });
        _filters.push({ 'or_group': true, 'filters': _orfilters });

        var data = { limit: limit, offset: offset, sorts: sorts, filters: _filters };
        fetch(config.fcsReportUrl + "/api/data/packages", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var packages = [];
                if (result && result.data) {
                    result.data.forEach(function (p) {
                        p["sn"] = ++offset;
                        packages.push(p);
                    });
                }
                dispatch({
                    type: GET_MYPACKAGES,
                    packages

                });
            }).catch((e) => {
                console.log("error:", e);
            });

        fetch(config.fcsReportUrl + "/api/row/packages", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var count = 0;
                if (result && result.data) {
                    count = result.data;
                }
                dispatch({
                    type: GET_MYPACKAGES_ROW_COUNT,
                    count
                });
            }).catch((e) => {
                console.log("error:", e);
            });
    });
}

export const getRecentPackages = async (dispatch, userID) => {
    // Get recent five packages for current user
    // where user_id or sender_is equal to current user id
    try {
        var packages = [];
        var packageIds = [];
        let packageSnaps=await firebase.firestore().collection("packages")
            .where("user_id", "==", userID).where("is_deleted", "==", false)
            .orderBy('update_time', 'desc').limit(5).get();
        packageSnaps.forEach(function (doc) {
            var p = doc.data();
            p['id'] = doc.id;
            packageIds.push(doc.id);
            packages.push(p);
        });
        packageSnaps=await firebase.firestore().collection("packages")
            .where("sender_id", "==", userID).where("is_deleted", "==", false)
            .orderBy('update_time', 'desc').limit(5).get();
        packageSnaps.forEach(function (doc) {
            var p = doc.data();
            p['id'] = doc.id;
            if(packageIds.includes(doc.id)) {
                return;
            }
            packages.push(p);
        });
        packages.sort((a,b)=>b["update_time"]-a["update_time"]);
        packages=packages.slice(0,5);

        dispatch({
            type: GET_RECENT_PACKAGES,
            payload: packages
        });
    }catch(error){
        console.log("Error getting documents: ", error);
    }
}

export const setMyPackageSearchTerm = (term) => (dispatch, getState) => {
    if (firebase.auth().currentUser) {
        dispatch(searchMyPackages(term));
        return;
    }
    dispatch({
        type: SET_MYPACKAGE_SEARCH_TERM,
        term
    });
}

export const searchMyPackages = (term) => (dispatch, getState) => {
    if (!!!firebase.auth().currentUser) return;
    if (!term || term === "") {
        dispatch(getMyPackagesData());
        return;
    }

    var _filters = [];
    var _user = getState().app.user;
    if (!_user || !(_user.fcs_id)) return;
    var _orfilters = [];
    _orfilters.push({ 'field': 'fcs_id', 'compare': "=", 'value': _user.fcs_id });
    _orfilters.push({ 'field': 'sender_fcs_id', 'compare': "=", 'value': _user.fcs_id });
    _filters.push({ 'or_group': true, 'filters': _orfilters });
    var b64 = btoa(JSON.stringify(_filters));
    var filtersEsc = encodeURIComponent(b64);

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsReportUrl + "/api/fts_filter/packages/" + term + "/10/0/" + filtersEsc, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
        }).then(response => response.json())
            .then((result) => {
                var packages = [];
                if (result && result.data) {
                    let index = 0;
                    result.data.forEach(function (u) {
                        u["sn"] = ++index;
                        packages.push(u);
                    });
                }
                dispatch({
                    type: GET_MYPACKAGES,
                    packages
                });
                var count = packages.length;
                dispatch({
                    type: GET_MYPACKAGES_ROW_COUNT,
                    count
                });
            }).catch((e) => {
                console.log("error:", e);
            });

    });
}


export const updatePackageUsers = (pkg, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/packages/users", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(pkg)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "package update successfully!";
                    _color = "green";
                    return onSuccess();
                } else {
                    _message = result['message'];
                    _color = "red";
                }
                // dispatch(getPickupsData());
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
            });
    });
}