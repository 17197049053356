import { Box, Divider, IconButton, Button, Typography, Grid, Paper, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import RefreshIcon from '@material-ui/icons/Refresh';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import theme from '../../theme';
import { convertISOToDate, getTimeFormDate, removeFCSString } from '../../util';
import AddressCard from '../address_card';
import { getDropoffDetail } from '../../actions/dropoff';
import PrintDropoffQrcode from './print_dropoff_qrcode';
import { QrcodeIcon } from '../../icons';
import ShowPhotoDialog from '../show_photo_dialog';

const useStyles = makeStyles(th => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
}));

const DropoffDetail = (props) => {
    const { title, history, dispatch, smallScreen } = props;
    const classes = useStyles();
    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const selector = useSelector(state => state.dropoff);
    const [dropoffDetail, setDropoffDetail] = React.useState(selector.dropoffDetail);
    const [open, setOpen] = React.useState(false);
    const [selectedPhoto, setSelectedPhoto] = React.useState(dropoffDetail['photo_urls'] ? dropoffDetail['photo_urls'][0] : "#");
    const [qrCodeData, setQrCodeData] = React.useState(null);
    const [showQrcode, setShowQrcode] = React.useState(false);
    const _privileges = useSelector(state => state.app.privileges);

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var dropoffId = pathArr[2];
    var onlyComplete = (pathArr.length > 3 ? pathArr[3] : "") === "oc";

    const handleBack = () => {
        history.goBack();
    }

    useEffect(() => {
        dispatch(getDropoffDetail(dropoffId));
    }, [dispatch, dropoffId]);

    useEffect(() => {
        setDropoffDetail(selector.dropoffDetail);
        var data = selector.dropoffDetail;
        if (data && data.id) {
            setQrCodeData({ ...data, created_date: convertISOToDate(data.created_date) });
        }
    }, [selector]);

    const handleComplete = () => {
        history.push('/dropoff-complete/' + dropoffId);
    }

    const handleEdit = () => {
        history.push('/dropoff-edit/' + dropoffId);
    }

    const handleCancel = () => {
        history.push('/dropoff-cancel/' + dropoffId);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOnQrcodeDialogClose = () => {
        setShowQrcode(false);
    };

    const handlePrintQrcode = (e) => {
        e.stopPropagation();
        setShowQrcode(true);
    }

    const isAdmin = () => {
        return _privileges.includes('admin') || _privileges.includes('sa');
    }

    return (
        <div className={classes.root}>
            <Box display="flex" p={1} >
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table style={{ padding: smallScreen ? '10px' : '30px' }}>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{dropoffDetail.dropoff_number !== undefined ? dropoffDetail.dropoff_number : ''}</Typography>
                                    <QrcodeIcon onClick={e => handlePrintQrcode(e)} style={{ width: "20px", height: "20px", cursor: "pointer" }} />
                                </Box>
                                <Box display="flex" >
                                    <Typography style={{ minWidth: "30px", padding: '2px', color: 'grey', textAlign: 'left', border: '#807979 1px solid', borderRadius: '5px' }}>{dropoffDetail.status !== undefined ? dropoffDetail.status : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Customer'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{dropoffDetail.customer_fcs_id !== undefined ? removeFCSString(dropoffDetail.customer_fcs_id) : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{dropoffDetail.customer_name !== undefined ? dropoffDetail.customer_name : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Staff'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{dropoffDetail.receiving_staff_name ? dropoffDetail.receiving_staff_name : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Customer Remark'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ minWidth: '150px', padding: '10px', textAlign: 'left', whiteSpace: 'pre-line', border: '1px solid grey', borderRadius: '5px' }}>{dropoffDetail.customer_remark ? dropoffDetail.customer_remark : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Remark'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ minWidth: '150px', padding: '10px', textAlign: 'left', whiteSpace: 'pre-line', border: '1px solid grey', borderRadius: '5px' }}>{dropoffDetail.remark ? dropoffDetail.remark : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Photos'}</Typography>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: '5px', flexWrap: 'wrap', }}>
                                    {
                                        dropoffDetail['photo_urls'] ? dropoffDetail['photo_urls'].map((i, index) =>
                                            <Box key={index} style={{ marginRight: '5px', marginBottom: "5px" }}>
                                                <img id="output" alt={i.name}
                                                    style={{ padding: '10px', height: "100px", width: "100px", border: "1px solid lightgrey" }}
                                                    src={i}
                                                    onClick={(e) => {
                                                        setSelectedPhoto(i);
                                                        setOpen(true);
                                                    }
                                                    } /></Box>
                                        )
                                            : <span />}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                {dropoffDetail.status === "received" ? <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        color="primary"
                                        onClick={handleEdit}
                                        startIcon={<EditIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Edit Drop-Off"} </Button>
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        color="primary"
                                        onClick={handleComplete}
                                        startIcon={<SaveIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Complete Drop-Off"} </Button>
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', backgroundColor: '#d83d0f', color: 'white' }}
                                        onClick={handleCancel}
                                        startIcon={<CancelIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Cancel Drop-Off"} </Button>
                                </Box> : <span />}
                            </Grid>
                        </td>
                    </tr>
                </tbody>
            </table>
            {dropoffDetail.status === "completed" && isAdmin() ?
                <div style={{ paddingTop: '30px' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left' }}>{'Admin Only'}</Typography>
                    <Divider style={{ backgroundColor: 'lightgrey' }}></Divider>
                    <table style={{ padding: smallScreen ? '10px' : '15px' }}>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: 'left' }}>
                                    <Box>
                                        <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', backgroundColor: '#d83d0f', color: 'white' }}
                                            onClick={handleCancel}
                                            startIcon={<CancelIcon />}
                                        >{stateIsUpdating ? "Updating..." : "Cancel Drop-Off"} </Button>
                                    </Box>
                                </td>
                            </tr></tbody></table>
                </div> : <span />
            }

            <ShowPhotoDialog
                open={open}
                selectedPhoto={selectedPhoto}
                photoUrls={dropoffDetail.photo_urls}
                onClose={(v) => {  setOpen(false) }}
                onChangeSelectedPhoto={(v) => { setSelectedPhoto(v) }}
            ></ShowPhotoDialog>
            {qrCodeData && showQrcode ? <PrintDropoffQrcode dropoff={qrCodeData} open={showQrcode} onClose={handleOnQrcodeDialogClose} /> : <span />}
        </div >
    );
};

DropoffDetail.propTypes = {
    pDetail: PropTypes.object,
    title: PropTypes.string
};

export default withRouter(connect()(DropoffDetail));
