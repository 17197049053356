import { Box, IconButton, Button, Typography, Grid, Paper, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import RefreshIcon from '@material-ui/icons/Refresh';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { getPickupDetail } from '../../actions/pickup';
import theme from '../../theme';
import { convertISOToDate, getTimeFormDate, removeFCSString } from '../../util';
import AddressCard from '../address_card';
import ShowPhotoDialog from '../show_photo_dialog';

const useStyles = makeStyles(th => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
}));

const PickupDetail = (props) => {
    const { title, history, dispatch, smallScreen } = props;
    const classes = useStyles();
    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const selector = useSelector(state => state.pickup);
    const [pickupDetail, setPickupDetail] = React.useState(selector.pickupDetail);
    const [open, setOpen] = React.useState(false);
    const [selectedPhoto, setSelectedPhoto] = React.useState(pickupDetail['photo_urls'] ? pickupDetail['photo_urls'][0] : "#");

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var pickupId = pathArr[2];
    var onlyComplete = (pathArr.length > 3 ? pathArr[3] : "") === "oc";

    const handleBack = () => {
        history.goBack();
    }

    useEffect(() => {
        dispatch(getPickupDetail(pickupId));
    }, [dispatch, pickupId]);

    useEffect(() => {
        setPickupDetail(selector.pickupDetail);
    }, [selector]);

    const handleRescheduled = () => {
        history.push('/pickup-reschedule/' + pickupId);
    }

    const handleComplete = () => {
        history.push('/pickup-complete/' + pickupId);
    }

    const handleConfirm = () => {
        history.push('/pickup-confirm/' + pickupId);
    }

    const handleEdit = () => {
        history.push('/pickup-edit/' + pickupId);
    }

    const handleCancel = () => {
        history.push('/pickup-cancel/' + pickupId);
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <Box display="flex" p={1} >
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table style={{ padding: smallScreen ? '10px' : '30px' }}>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Number'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{pickupDetail.pickup_number !== undefined ? pickupDetail.pickup_number : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{pickupDetail.status !== undefined ? pickupDetail.status : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Zone'}</Typography>
                                <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.zone_name !== undefined ? pickupDetail.zone_name : ''}</Typography>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Date'}</Typography>
                                <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.pickup_date !== undefined ? convertISOToDate(pickupDetail.pickup_date) : ''}</Typography>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', marginBottom: '5px', color: 'grey', textAlign: 'left' }}>{'Pickup Time'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.pickup_time_from ? getTimeFormDate((pickupDetail.pickup_time_from).toDate()) : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.pickup_time_to ? getTimeFormDate((pickupDetail.pickup_time_to).toDate()) : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Customer'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.customer_fcs_id !== undefined ? removeFCSString(pickupDetail.customer_fcs_id) : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.customer_name !== undefined ? pickupDetail.customer_name : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Staff'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{pickupDetail.pickup_staff_name ? pickupDetail.pickup_staff_name : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Customer Remark'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ minWidth: '150px', padding: '10px', textAlign: 'left', whiteSpace: 'pre-line', border: '1px solid grey', borderRadius: '5px' }}>{pickupDetail.customer_remark ? pickupDetail.customer_remark : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Remark'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ minWidth: '150px', padding: '10px', textAlign: 'left', whiteSpace: 'pre-line', border: '1px solid grey', borderRadius: '5px' }}>{pickupDetail.remark ? pickupDetail.remark : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Pickup Address'}</Typography>
                                {pickupDetail.pickup_address !== undefined ? <div style={{ display: 'inline-flex', marginTop: '5px' }}>
                                    <Paper
                                        style={{ marginRight: '5px', marginBottom: '5px', width: '280px', height: '165px', border: '1px solid lightgray' }}
                                    >
                                        <AddressCard address={pickupDetail.pickup_address}
                                            editIcon={false}
                                            deleteIcon={false}
                                            makeDefaultIcon={false}
                                        />
                                    </Paper>
                                </div> : <div />}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Photos'}</Typography>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: '5px', flexWrap: 'wrap', }}>
                                    {
                                        pickupDetail['photo_urls'] ? pickupDetail['photo_urls'].map((i, index) =>
                                            <Box key={index} style={{ marginRight: '5px', marginBottom: "5px" }}>
                                                <img id="output" alt={i.name}
                                                    style={{ padding: '10px', height: "100px", width: "100px", border: "1px solid lightgrey" }}
                                                    src={i}
                                                    onClick={(e) => {
                                                        setSelectedPhoto(i);
                                                        setOpen(true);
                                                    }
                                                    } /></Box>
                                        )
                                            : <span />}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                {pickupDetail.status === "requested" ? <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        color="primary"
                                        onClick={handleEdit}
                                        startIcon={<EditIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Edit Pickup"} </Button>
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        color="primary"
                                        onClick={handleConfirm}
                                        startIcon={<SaveIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Confirm Pickup"} </Button>
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', backgroundColor: '#d83d0f', color: 'white' }}
                                        onClick={handleCancel}
                                        startIcon={<CancelIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Cancel Pickup"} </Button>
                                </Box> : <span />}

                                {pickupDetail.status === "confirmed" && !onlyComplete ? <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        onClick={handleRescheduled}
                                        color="primary"
                                        startIcon={<RefreshIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Reschedule Pickup"} </Button>
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        onClick={handleComplete}
                                        color="primary"
                                        startIcon={<DoneAllIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Complete Pickup"} </Button>
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', backgroundColor: '#d83d0f', color: 'white' }}
                                        onClick={handleCancel}
                                        startIcon={<CancelIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Cancel Pickup"} </Button>
                                </Box> : <span />}

                                {pickupDetail.status === "rescheduled" && !onlyComplete ? <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        onClick={handleRescheduled}
                                        color="primary"
                                        startIcon={<RefreshIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Reschedule Pickup"} </Button>
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        onClick={handleComplete}
                                        color="primary"
                                        startIcon={<DoneAllIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Complete Pickup"} </Button>
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', backgroundColor: '#d83d0f', color: 'white' }}
                                        onClick={handleCancel}
                                        startIcon={<CancelIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Cancel Pickup"} </Button>
                                </Box> : <span />}

                                {(pickupDetail.status === "confirmed" || pickupDetail.status === "rescheduled")
                                    && onlyComplete ? <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        onClick={handleComplete}
                                        color="primary"
                                        startIcon={<DoneAllIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Complete Pickup"} </Button>
                                </Box> : <span />}
                            </Grid>
                        </td>
                    </tr>

                </tbody>
            </table>

            <ShowPhotoDialog
                open={open}
                selectedPhoto={selectedPhoto}
                photoUrls={pickupDetail.photo_urls}
                onClose={(v) => {  setOpen(false) }}
                onChangeSelectedPhoto={(v) => { setSelectedPhoto(v) }}
            ></ShowPhotoDialog>
        </div >

    );
};

PickupDetail.propTypes = {
    pDetail: PropTypes.object,
    title: PropTypes.string
};

export default withRouter(connect()(PickupDetail));
