import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dialog, DialogContent, DialogActions, DialogTitle, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { convertDateString } from '../../util';

function PickupDateDialog(props) {
    const { data, title, zones, onClose, onSave, open } = props;
    const [pickupDate, setPickupDate] = useState(data);

    useEffect(() => {
        setPickupDate(data);
    }, [data]);

    const handleChangeSelectedZones = (event, value) => {
        event.preventDefault();
        var _zones = pickupDate.zones !== undefined ? [...pickupDate.zones] : [];
        console.log('toggle data zones=> ', _zones)
        if (_zones === undefined) {
            var arr = [];
            arr.push(value);
            _zones = arr;
        } else {
            var chk = containsObject(value, _zones);
            if (chk) {
                for (var i = 0; i < _zones.length; i++) {
                    if (value.id === _zones[i].id) {
                        _zones.splice(i, 1);
                    }
                }
            } else {
                var obj = {
                    "id": value.id,
                    "name": value.name
                }
                _zones.push(obj);
            }
        }

        console.log('after toggle data zones=> ', _zones)
        setPickupDate({ ...pickupDate, 'zones': _zones });

    };

    const handleOnChangePickupDate = (e) => {
        var _date = e.target.value;
        var arr = _date.split('-');
        setPickupDate({ ...pickupDate, 'id': arr.join('') });
    }
    const handleOnChangeRemark = (e) => {
        var remark = e.target.value;
        setPickupDate({ ...pickupDate, 'remark': remark });
    }
    function containsObject(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].id === obj.id) {
                return true;
            }
        }

        return false;
    }

    const handleClose = () => {
        onClose(false);
    }

    const handleSave = () => {
        onSave(pickupDate);
    }

    return <div>
        <div style={{ textAlign: 'start' }}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
                        <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Pickup Date'}</Typography>
                        <TextField
                            variant="outlined"
                            autoComplete="off"
                            size={"small"}
                            type="date"
                            value={pickupDate !== undefined ? convertDateString(pickupDate['id']) : "" || ''}
                            style={{ width: '200px' }}
                            onChange={(e) => handleOnChangePickupDate(e)}
                        />
                    </div>
                    <div style={{ display: "flex", height: '37px', marginBottom: '10px' }}>
                        <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '8px' }}>{'Remark'}</Typography>
                        <TextField
                            variant="outlined"
                            autoComplete="off"
                            size={"small"}
                            type="text"
                            value={pickupDate ? pickupDate['remark'] : "" || ''}
                            style={{ width: '200px' }}
                            onChange={(e) => handleOnChangeRemark(e)}
                        />
                    </div>
                    <div style={{ display: "flex", marginBottom: '10px' }}>
                        <Typography style={{ paddingRight: '10px', color: 'grey', width: '100px', paddingTop: '18px' }}>{'Zones'}</Typography>
                        <List>
                            {zones.map((value) => {
                                const labelId = `checkbox-list-label-${value.id}`;

                                if (pickupDate !== undefined && pickupDate.zones !== undefined) {

                                    var chk = containsObject(value, pickupDate.zones);
                                    if (chk) {

                                        return (
                                            <ListItem key={value.id} dense button
                                                onClick={(e) => handleChangeSelectedZones(e, value)}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        tabIndex={-1}
                                                        color="primary"
                                                        checked
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={`${value.name}`} />

                                            </ListItem>
                                        );
                                    } else {
                                        return (
                                            <ListItem key={value.id} dense button
                                                onClick={(e) => handleChangeSelectedZones(e, value)}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        tabIndex={-1}
                                                        checked={false}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={`${value.name}`} />

                                            </ListItem>
                                        );
                                    }
                                } else {

                                    return (
                                        <ListItem key={value.id} dense button
                                            onClick={(e) => handleChangeSelectedZones(e, value)}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    tabIndex={-1}
                                                    checked={false}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={`${value.name}`} />

                                        </ListItem>
                                    );
                                }

                            })}
                        </List>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    </div>;
}

PickupDateDialog.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    zones: PropTypes.array,
    data: PropTypes.object,
    onClose: PropTypes.func,
    onSave: PropTypes.func
}

export default connect()(PickupDateDialog);