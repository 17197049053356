import React from "react";
import Link from '@material-ui/core/Link';
import { connect, useSelector } from 'react-redux';

const Version = () => { 
  const login = useSelector(state => state.app.isLogin);

  return (  
  <div className="version">
    <p>v1.3.6+53</p>
    {login?<div/>:<Link href="/login">login</Link>}    
  </div>
)};

export default (connect()(Version));
