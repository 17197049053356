import { Box, Button, Grid, IconButton, TextField, Input, Typography, NativeSelect, FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import palette from '../../theme/palette';
import { addNewddress, getAddressDetail, getUserDetail, updateAddress } from '../../actions/user';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { GoogleApiWrapper } from 'google-maps-react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '30px'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    }, button: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        width: 150,
        height: 55
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    table: {
        padding: "10px 20px 10px 20px"
    },
    textField: {
        border: "none"
    },
    addressSearch: {
        borderRadius: "4px",
        mozBorderRadius: "4px",
        webkitBorderRadius: "4px",
        backgroundColor: "#f5f5f5",
        borderColor: "rgba(0, 0, 0, 0.23)",
        borderWidth: "1px",
        padding: "4px",
        paddingLeft: "14px"
    },
}));


function NewAddressPage(props) {
    const classes = useStyles();
    const { google, dispatch, title, history } = props;

    const selector = useSelector(state => state.user);
    const countries = useSelector(state => state.app.countries);
    const countrie_names = useSelector(state => state.app.countrie_names);
    const defalutCountry = useSelector(state => state.app.defalutCountry);

    const [userDetail, setUserDetail] = React.useState(selector.userDetail);
    const [address, setAddress] = React.useState(selector.address);
    const [data, setData] = React.useState({});
    const stateIsUpdating = useSelector(state => state.app.isUpdating);

    const [error, setError] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [addressLine1, setAddressLine1] = React.useState("");
    const [addressObj, setAddressObj] = React.useState(null);

    const inputRef = React.useRef();

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var userId = pathArr[2];
    var addressId = pathArr[3];

    useEffect(() => {
        setAddress(selector.address);
        setUserDetail(selector.userDetail);
        setData(address);
        if (address && address.address_line1) {
            setAddressLine1(address.address_line1);
        }
    }, [selector, address, addressId]);

    useEffect(() => {
        if (userId !== undefined) {
            dispatch(getUserDetail(userId));
            dispatch(getAddressDetail(userId, addressId));
        }
    }, [dispatch, addressId, userId]);

    let autocomplete = null;
    const autoCompleteRef = useRef(null);

    useEffect(() => {
        const options = {
            // componentRestrictions: { country: "us" },
            strictBounds: false,
        };
        autocomplete = new google.maps.places.Autocomplete(autoCompleteRef.current, options);
        autocomplete.addListener("place_changed", fillInAddress);
    }, []);

    const handleBack = () => {
        history.goBack();
    }

    const handleSave = () => {
        data['user_id'] = userDetail.id;

        let isError = false;
        var errorMsg = [...errorMessages];
        if (!data.full_name) {
            isError = true;
            setError(true);
            errorMsg['fullName'] = "Full name is required";
            setErrorMessages(errorMsg);
        }

        if (!data.phone_number) {
            isError = true;
            setError(true);
            errorMsg['phoneNumber'] = "Phone number is required";
            setErrorMessages(errorMsg);
        }

        if (!data.country) {
            isError = true;
            setError(true);
            errorMsg['country'] = "Country is required";
            setErrorMessages(errorMsg);
        }

        if (!data.address_line1) {
            isError = true;
            setError(true);
            errorMsg['addressLine1'] = "Address line1 is required";
            setErrorMessages(errorMsg);
        }

        if (!data.city) {
            isError = true;
            setError(true);
            errorMsg['city'] = "City is required";
            setErrorMessages(errorMsg);
        }

        if (!data.state) {
            isError = true;
            setError(true);
            errorMsg['state'] = "State is required";
            setErrorMessages(errorMsg);
        }

        if (!data.zip_code) {
            isError = true;
            setError(true);
            errorMsg['zipCode'] = "Zip code is required";
            setErrorMessages(errorMsg);
        }

        if (!isError) {
            setError(false);
            setErrorMessages([]);
            if (addressId !== undefined) {
                dispatch(updateAddress(data, () => {
                    history.goBack();
                }));
            } else {
                dispatch(addNewddress(data, () => {
                    history.goBack();
                }));
            }
        }
    }

    const handleFullName = (e) => {
        setData({ ...data, 'full_name': e.target.value });
    }

    const handlePhoneNumber = (p) => {
        setData({ ...data, 'phone_number': "+" + p });
    }

    const handleCountry = (e) => {
        setData({ ...data, 'country': e.target.value });
    }

    const handleAddressLine1 = (e) => {
        setAddressLine1(e.target.value);
    }

    const handleAddressLine2 = (e) => {
        setData({ ...data, 'address_line2': e.target.value });
    }

    const handleCity = (e) => {
        setData({ ...data, 'city': e.target.value });
    }

    const handleState = (e) => {
        setData({ ...data, 'state': e.target.value });
    }

    const handleZipCode = (e) => {
        setData({ ...data, 'zip_code': e.target.value });
    }


    const fillInAddress = () => {
        const place = autocomplete.getPlace();
        let address1 = "";
        let address2 = "";
        let postcode = "";
        let city = "";
        let state = "";

        if (place && place.address_components && place.address_components.length > 0) {
            for (const component of place.address_components) {
                const componentType = component.types[0];
                switch (componentType) {
                    case "street_number": {
                        address1 = `${component.long_name} ${address1}`;
                        break;
                    }
                    case "neighborhood": {
                        city = `${component.long_name}`;
                        break;
                    }
                    case "route": {
                        address1 += component.short_name;
                        break;
                    }
                    case "postal_code": {
                        postcode = `${component.long_name}${postcode}`;
                        break;
                    }
                    case "postal_code_suffix": {
                        postcode = `${postcode}`;
                        break;
                    }
                    case "locality":
                        city = component.long_name;
                        break;
                    case "administrative_area_level_3":
                        city = component.long_name;
                        break;
                    case "administrative_area_level_1": {
                        state = component.long_name;
                        break;
                    }
                }
            }

            setAddressObj({
                'address_line1': address1, 'address_line2': address2, "city": city,
                "state": state, 'zip_code': postcode,
            });
            setAddressLine1(address1);
        }
    }

    useEffect(() => {
        if (addressObj) {
            setData({
                ...data, 'address_line1': addressObj.address_line1,
                'address_line2': addressObj.address_line2,
                "city": addressObj.city,
                "state": addressObj.state, 'zip_code': addressObj.zip_code,
            });
            inputRef.current.focus();
        }
    }, [addressObj]);

    return (
        <div className={classes.root}>
            <Box display="flex" p={1}>
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table className={classes.table}>
                <tbody>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <FormControl error={error}>
                                    <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Full Name'}</Typography>
                                    <TextField
                                        variant="outlined"
                                        autoComplete="off"
                                        size={"small"}
                                        value={data !== undefined ? data.full_name || '' : ''}
                                        onChange={(e) => handleFullName(e)}
                                        style={{ width: '200px', border: error ? !data.full_name ? "1px solid red" : null : null, borderRadius: error ? "4px" : "4px" }}
                                    />
                                    {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['fullName']}</FormHelperText> : <span />}
                                </FormControl>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <FormControl error={error}>
                                    <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Phone Number'}</Typography>
                                    <PhoneInput
                                        value={data ? data.phone_number || '' : ''}
                                        country={defalutCountry}
                                        onlyCountries={countries}
                                        onChange={(e) => handlePhoneNumber(e)}
                                        style={{ border: error ? !data.phone_number ? "1px solid red" : null : null, borderRadius: error ? "4px" : "4px" }}
                                    />
                                    {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['phoneNumber']}</FormHelperText> : <span />}
                                </FormControl>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <FormControl>
                                    <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Select Country'}</Typography>
                                    <NativeSelect
                                        className={classes.textField}
                                        value={data ? data.country || '' : defalutCountry.toUpperCase()}
                                        onChange={(e) => handleCountry(e)}
                                        style={{ width: '200px', borderBottom: error ? !data.country ? "1px solid red" : "1px solid #ced4da" : "1px solid #ced4da", }}
                                    >
                                        <option aria-label="None" value="" ></option>
                                        {countrie_names.map((e) => {
                                            return <option key={e} value={e}>{e}</option>;
                                        })}
                                    </NativeSelect>
                                    {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['country']}</FormHelperText> : <span />}
                                </FormControl>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', paddingTop: '20px', paddingBottom: '20px' }}>
                            <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Address Line1'}</Typography>
                            <input ref={autoCompleteRef} className={classes.addressSearch} size={'50'} type="text"
                                style={{ lineHeight: "30px" }} placeholder=""
                                value={addressLine1} onChange={(e) => handleAddressLine1(e)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Address Line2'}</Typography>
                                <TextField
                                    inputRef={inputRef}
                                    variant="outlined"
                                    autoComplete="off"
                                    size={"small"}
                                    value={data !== undefined ? data.address_line2 || '' : ''}
                                    style={{ width: '400px' }}
                                    multiline
                                    onChange={(e) => handleAddressLine2(e)}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <FormControl error={error}>
                                    <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'City'}</Typography>
                                    <TextField
                                        variant="outlined"
                                        autoComplete="off"
                                        size={"small"}
                                        value={data !== undefined ? data.city || '' : ''}
                                        onChange={(e) => handleCity(e)}
                                        style={{ width: '200px', border: error ? !data.city ? "1px solid red" : null : null, borderRadius: error ? "4px" : "4px" }}
                                    />
                                    {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['city']}</FormHelperText> : <span />}
                                </FormControl>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <FormControl error={error}>
                                    <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'State'}</Typography>
                                    <TextField
                                        variant="outlined"
                                        autoComplete="off"
                                        size={"small"}
                                        value={data !== undefined ? data.state || '' : ''}
                                        style={{ width: '200px', border: error ? !data.state ? "1px solid red" : null : null, borderRadius: error ? "4px" : "4px" }}
                                        onChange={(e) => handleState(e)}
                                    />
                                    {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['state']}</FormHelperText> : <span />}
                                </FormControl>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <FormControl error={error}>
                                    <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Zip Code'}</Typography>
                                    <TextField
                                        variant="outlined"
                                        autoComplete="off"
                                        size={"small"}
                                        value={data && data.zip_code ? data.zip_code || '' : ''}
                                        style={{ width: '200px', border: error ? !data.zip_code ? "1px solid red" : null : null, borderRadius: error ? "4px" : "4px" }}
                                        onChange={(e) => handleZipCode(e)}
                                    />
                                    {error ? <FormHelperText style={{ color: "red" }}>{errorMessages['zipCode']}</FormHelperText> : <span />}
                                </FormControl>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ marginRight: '10px', marginBottom: '10px' }} color="primary"
                                        onClick={handleSave}
                                        startIcon={<SaveIcon />}
                                    > {stateIsUpdating ? "Updating..." : "Save"}</Button>
                                </Box>
                                <Box>
                                    <Button variant="contained" style={{ color: 'white', backgroundColor: 'grey', marginBottom: '10px' }}
                                        onClick={handleBack}
                                        startIcon={<CancelIcon />}
                                    > Cancel</Button>
                                </Box>
                            </Grid>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

NewAddressPage.propTypes = {
    history: PropTypes.object,
    title: PropTypes.string,
};

export default connect()(GoogleApiWrapper({
    apiKey: 'AIzaSyCKwDphFvOuM2xs2PilvMUs-K2eRuncr8U'
})(NewAddressPage));