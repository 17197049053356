import React, { useEffect } from 'react';
import { Typography, Grid, Box, makeStyles, IconButton, OutlinedInput, InputAdornment, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import BackIcon from '@material-ui/icons/ArrowBack';
import { connect, useSelector } from 'react-redux';
import theme from '../theme';
import { useLocation } from 'react-router';
import { searchCustomers, setConsignee, setCustomerSearchTerm, setSelectedCustomer, setSender } from '../actions/user';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    selectedAddressColor: {
        border: "2px solid green",
        marginRight: '5px',
        marginBottom: '5px',
    },
    unSelectedAddressColor: {
        border: "2px solid lightgray",
        marginRight: '5px',
        marginBottom: '5px',
    }
}));


function SearchCustomerPage(props) {
    const classes = useStyles();
    // type [general,sender,consignee]
    const { title, history, dispatch, type } = props;

    const customers = useSelector(state => state.user.customers);
    const [searchText, setSearchText] = React.useState("");
    const location = useLocation();

    useEffect(() => {
        var pathName = location.pathname;
        var pathArr = pathName.split('/');
        var term = pathArr[2];
        var txt = "";
        if (term && term !== "") {
            var de = decodeURIComponent(term);
            txt = atob(de);
            dispatch(setCustomerSearchTerm(term));
        } else {
            dispatch(searchCustomers(""));
        }
        setSearchText(txt);
    }, [location, dispatch])

    const handleOnChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    const handleSearch = (e) => {
        var b64 = btoa(searchText);
        var esc = encodeURIComponent(b64);
        dispatch(setCustomerSearchTerm(esc));
    }

    const handleClear = (e) => {
        setSearchText("");
        dispatch(searchCustomers(""));
    }

    const handleBack = () => {
        history.goBack();
    }

    const handleSelectedCustomer = (value) => {
        if (type == "sender") {
            dispatch(setSender(value));
        } else if (type == "consignee") {
            dispatch(setConsignee(value));
        } else {
            dispatch(setSelectedCustomer(value));
        }
        history.goBack();
    }

    return (

        <div className={classes.root}>
            <Box display="flex" p={1}>
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>

            <div style={{ padding: "30px" }}>
                <Grid container spacing={0} style={{}}>
                    <Box>
                        <OutlinedInput style={{ height: '40px' }}
                            id="standard-adornment-password"
                            value={searchText}
                            variant="outlined"
                            autoComplete="false"
                            placeholder={"Search customer"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility"
                                        onClick={handleSearch}>
                                        <SearchIcon />
                                    </IconButton>
                                    <IconButton aria-label="toggle password visibility"
                                        onClick={handleClear}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            onChange={handleOnChange}
                            onKeyDown={handleKeyDown}
                        ></OutlinedInput>
                    </Box>

                </Grid>
                <Grid item xs={12} sm={3} style={{ marginTop: "20px" }}>
                    {customers ? customers.map((c) => {
                        return <Grid item key={c.id}>
                            <Box display="flex" p={1} style={{ borderBottom: "1px solid lightgray" }}>
                                <Box style={{ textAlign: "left" }}>
                                    <Typography>{c.fcs_id}</Typography>
                                    <Typography>{c.user_name}</Typography>
                                    <Typography>{c.phone_number}</Typography>
                                </Box>
                                <Box flexGrow={1}>
                                    <Button color="primary" variant="outlined" size="small" style={{ textTransform: 'none', float: 'right' }}
                                        onClick={() => handleSelectedCustomer(c)}
                                    >Select customer</Button>
                                </Box>
                            </Box>

                        </Grid>;
                    })
                        : <Grid />}

                </Grid>
            </div>
        </div >
    );
}

SearchCustomerPage.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string
}

export default connect()(SearchCustomerPage);