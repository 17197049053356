import { Box, Button, CardContent, Grid, TextField, Typography, Link } from "@material-ui/core";
import { alpha, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { changeTab } from "../../actions/app";
import { trackPackages } from '../../actions/tracking';
import theme from '../../theme';
import { formatDateToLocal } from "../../util";
import TrackPackageList from "./track_package_list";

class TrackPackages extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: "",
      track: false,
      smallScreen: true
    };
  }

  componentDidMount() {
    var smallScreen = window.innerWidth <= 760;
    this.setState({ smallScreen: smallScreen });
    console.log('small screen => ', window.innerWidth <= 760);
  }

  updateInput = (e) => {
    var upper = e.toUpperCase();
    var nospace = upper.replace(new RegExp(' ', 'g'), '');
    this.setState({ value: nospace });
  }

  handelTrackPackages = () => {
    let { dispatch } = this.props;
    this.setState({ track: true });
    dispatch(trackPackages(this.state.value));
  }

  render() {
    const styles = {
      root: {
        minWidth: 150,
        flexGrow: 1,
      },
      card: {
        minWidth: 150,
        flexGrow: 1,
        padding: '10px 10px',
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      button: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        width: 120,
        height: 45,
      },
      smallButton: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        width: 110,
        height: 45
      },
      btnText: {
        fontSize: 18,
        textTransform: 'none',
      },
      list: {
        marginLeft: '10%',
        marginRight: '10%'
      },
      pos: {
        fontSize: 24,
        margin: 0,
      },
      search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        // padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '12ch',
          '&:focus': {
            width: '20ch',
          },
        },
      },
    };

    const handleJoin = () => {
      this.props.dispatch(changeTab(''));
      this.props.history.push("/join-invite");
    }

    // const handleRequestInvitation = () => {
    //   this.props.dispatch(changeTab(''));
    //   this.props.history.push("/request-invitation");
    // }

    const handlePackageClick = (event, id) => {
      event.preventDefault();
      var pathName = '/my-package-detail/' + id;
      this.props.history.push(pathName);
    };

    const handlePickupClick = (event, id) => {
      event.preventDefault();
      var pathName = '/my-pickup-detail/' + id;
      this.props.history.push(pathName);
    };

    const handleClickAllPackage = (event) => {
      event.preventDefault();
      this.props.history.push("/my-packages");
    }

    const handleClickAllPickup = (event) => {
      event.preventDefault();
      this.props.history.push("/my-pickups");
    }

    return (
      <div style={styles.root}>
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12}>
              {(this.props.login && this.props.status === "invited") ? <Box textAlign="left" fontWeight="fontWeightBold" color="primary">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={handleJoin}
                  style={{ textTransform: "none" }}
                >
                  Join Invited
                </Button>
              </Box> : <Box />}
            </Grid>
            <Grid item xs={12} sm={12}>
              {(this.props.login && this.props.status === 'signup') ? <Box textAlign="left" fontWeight="fontWeightBold" color="primary">
                <Typography style={styles.pos} color="primary" className="title">Please request for invite from <Link href={this.props.facebookUrl} style={{textDecoration:'underline'}} target={'_blank'}>FCS</Link>. </Typography>
                {/* <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={handleRequestInvitation}
                  style={{ textTransform: "none" }}
                >
                  Request Invitation
                </Button> */}
              </Box> : <Box />}
            </Grid>
            <Grid item xs={12} sm={9} className="text-field-item">
              <TextField id="outlined-basic" multiline rows={3} className="term" onChange={e => this.updateInput(e.target.value)} value={this.state.value}
                placeholder="Enter up to 30 tracking numbers (separated by commas or new line)" variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button variant="contained" style={styles.button} disabled={this.props.isLoading} onClick={this.handelTrackPackages}><span style={styles.btnText}>
                {this.props.isLoading ? "Searching..." : "Track"}
              </span></Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TrackPackageList packages={this.props.packages}/>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {this.props.login && this.props.status === "joined" ?
              <Grid item xs={12} sm={12}>

                <Box display="flex">
                  <Box flexGrow={1} textAlign="left" fontWeight="fontWeightBold" color="primary">
                    <Typography style={{ color: "grey" }} variant="subtitle1">My recent packages</Typography>
                  </Box>
                  <Box textAlign="left" fontWeight="fontWeightBold" color="primary" onClick={handleClickAllPackage}>
                    <Typography color="primary" variant="subtitle1" style={{ cursor: "pointer" }}>See all</Typography>
                  </Box>
                </Box>

                <div style={{ display: 'inline-flex', width: '100%', marginTop: '10px', flexWrap: 'wrap', }}>
                  {!this.props.recentPackages ? <div /> : this.props.recentPackages.map((value, index) => {
                    return <Grid item xs={12} sm={3} key={index}
                      style={{ width: '15%', textAlign: "left", marginRight: '5px', marginBottom: '5px', border: '1px solid lightgray', borderRadius: '4px' }}
                    >
                      <CardContent>
                        <Box display="flex" >
                          <Box flexGrow={1}>
                            <Typography gutterBottom variant="h5" component="h2">
                              {value.tracking_id}
                            </Typography>
                          </Box>
                          <Typography style={{ textDecoration: "underline", cursor: "pointer" }} onClick={(e) => handlePackageClick(e, value.id)}>
                            Detail
                          </Typography>
                        </Box>

                        <Typography variant="body2" color="textSecondary" component="p">
                          {formatDateToLocal(value['updated_date'].toDate())}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" style={value.remark === 'Not found' ? { color: "red", marginLeft: 0 } : { marginLeft: 0 }}>
                          {value.remark}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" style={value.remark === 'Not found' ? { color: "red", marginLeft: 0 } : { marginLeft: 0 }}>
                          {value.status}
                        </Typography>
                      </CardContent>
                    </Grid>
                  })}

                </div>
              </Grid> : <span />}
            {this.props.login && this.props.status === "joined" ?
              <Grid item xs={12} sm={12}>
                <Box display="flex">
                  <Box flexGrow={1} textAlign="left" fontWeight="fontWeightBold" color="primary">
                    <Typography style={{ color: "grey" }} variant="subtitle1">My recent requested pickups</Typography>
                  </Box>
                  <Box textAlign="left" fontWeight="fontWeightBold" color="primary" onClick={handleClickAllPickup}>
                    <Typography color="primary" variant="subtitle1" style={{ cursor: "pointer" }}>See all</Typography>
                  </Box>
                </Box>
                <div style={{ display: 'inline-flex', width: '100%', marginTop: '10px', flexWrap: 'wrap', }}>
                  {!this.props.recentPickups ? <div /> : this.props.recentPickups.map((pickup, index) => {

                    return <Grid item xs={12} sm={3} key={index} style={{ textAlign: "left", marginRight: '5px', marginBottom: '5px', border: '1px solid lightgray', borderRadius: '4px' }}>
                      <CardContent>
                        <Box display="flex" >
                          <Box flexGrow={1}>
                            <Typography gutterBottom variant="h5" component="h2">
                              {pickup.pickup_number}
                            </Typography>
                          </Box>
                          <Typography style={{ textDecoration: "underline", cursor: "pointer" }}
                            onClick={(e) => handlePickupClick(e, pickup.id)}>
                            Detail
                          </Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {formatDateToLocal(pickup.pickup_date.toDate())}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {pickup.status}
                        </Typography>
                      </CardContent></Grid>
                  })}

                </div>
              </Grid> : <span />}
          </Grid>

        </ThemeProvider>
      </div >
    )
  }
}

TrackPackages.propTypes = {
  packages: PropTypes.array.isRequired,
  recentPackages: PropTypes.array
}

function mapStateToProps(state, ownProps) {
  const packages = state.tracking.packages;
  const isLoading = state.tracking.isLoading;
  const recentPackages = state.explore.recentPackages;
  const recentPickups = state.pickup.recentPickups;
  const login = state.app.isLogin;
  const user = state.app.user;
  const status = state.app.status;
  const facebookUrl = state.app.setting ? state.app.setting['facebook_link'] : '';

  return {
    packages,
    isLoading,
    recentPackages,
    recentPickups,
    login,
    user,
    status,
    facebookUrl
  };
}

export default connect(mapStateToProps)(TrackPackages)