import {
    SET_DROPOFF_FORM_DATA,
    CLEAR_DROPOFF_FORM,
    GET_DROPOFF_DETAIL,
    GET_DROPOFFS,
    GET_DROPOFFS_ROW_COUNT,
    SET_DROPOFF,
    CHANGE_DROPOFF_ORDER,
    CHANGE_DROPOFF_PAGE,
    SET_DROPOFF_SEARCH_TERM,
    SET_DROPOFF_STATUS_FILTER,
    GET_MYDROPOFFS,
    GET_MYDROPOFF_ROW_COUNT,
    CHANGE_MYDROPOFF_ORDER,
    CHANGE_MYDROPOFF_PAGE,
} from "../actions/dropoff";

const statusListNoCancel = [
    'ACTIVES',
    'received',
];
const statusListActive = [
    'ACTIVES',
    'received',
];
const initialState = {
    dropoffs: [],
    dropoffDetail: {},
    count: 0,
    page: 0,
    order: { "field_name": "updated_date", "direction": "desc" },
    dropoffStatusFilter: statusListNoCancel,

    assignCount: 0,
    assignPage: 0,
    assignOrder: { "field_name": "updated_date", "direction": "desc" },
    assignDropoffs: [],
    assignDropoffStatusFilter: statusListActive,

    myDropoffCount: 0,
    myDropoffPage: 0,
    myDropoffOrder: { "field_name": "updated_date", "direction": "desc" },
    myDropoffs: [],


    mapDropoffs: [],
    recentDropoffs: [],

    dropoffFormData: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DROPOFF_FORM_DATA: {
            return {
                ...state,
                dropoffFormData: {...state.dropoffFormData,...action.dropoffFormData},
            };
        }
        case CLEAR_DROPOFF_FORM: {
            return {
                ...state,
                dropoffFormData: {},
            };
        }
        case GET_DROPOFFS: {
            return {
                ...state,
                dropoffs: action.dropoffs,
            };
        }
        case GET_DROPOFFS_ROW_COUNT: {
            return {
                ...state,
                count: action.count,
            };
        }
        case GET_DROPOFF_DETAIL: {
            return {
                ...state,
                dropoffDetail: action.dropoff,
            };
        }
        case CHANGE_DROPOFF_ORDER: {
            return {
                ...state,
                order: action.order,
            };
        }
        case CHANGE_DROPOFF_PAGE: {
            return {
                ...state,
                page: action.page
            }
        }
        case SET_DROPOFF: {
            return {
                ...state,
                dropoffDetail: action.dropoff,
            };
        }
        case SET_DROPOFF_SEARCH_TERM: {
            return {
                ...state,
                term: action.term,
            };
        }

        case GET_MYDROPOFFS: {
            return {
                ...state,
                myDropoffs: action.dropoffs,
            };
        }
        case CHANGE_MYDROPOFF_ORDER: {
            return {
                ...state,
                myDropoffOrder: action.order,
            };
        }
        case CHANGE_MYDROPOFF_PAGE: {
            return {
                ...state,
                myDropoffPage: action.page
            }
        }
        case GET_MYDROPOFF_ROW_COUNT: {
            return {
                ...state,
                myDropoffCount: action.count,
            };
        }
        case SET_DROPOFF_STATUS_FILTER: {
            return {
                ...state,
                dropoffStatusFilter: action.filter,
            };
        }
        default:
            return state;
    }
}
