import { Box, IconButton, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { clearAddress, deleteAddress, makeDefaultAddress, updateUserProfile } from '../actions/user';
import theme from '../theme';
import AddressCard from './address_card';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { removeFCSString } from '../util';
import { getLoginUserAddresses } from '../actions/app';
import firebase from 'firebase/app';
import { changePhoneNumber } from '../actions/app';
import { SET_SHOW_ALERT } from '../actions/app';

const useStyles = makeStyles(theme => ({
  root: {
  },
  avatar: {
    width: 60,
    height: 60,
    textAlign: 'left'
  },
  radio: {
    '&$checked': {
      color: '#0f47a1'
    }
  },
  checked: {},
  row: {
    borderBottom: '1px solid grey'
  }
}));

const Profile = props => {
  const classes = useStyles();
  const { dispatch, className, history, smallScreen, allowPhoneNumberUpdate } = props;
  const user = useSelector(state => state.app.user);
  const addresses = useSelector(state => state.app.addresses);
  const stateIsUpdating = useSelector(state => state.app.isUpdating);
  const [isEditable, setIsEditable] = useState(false);
  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isUpdating, setIsUpdating] = useState(false || stateIsUpdating);

  useEffect(() => {
    const unlisten = firebase.auth().onAuthStateChanged(
      authUser => {
        console.log("state change>>>", authUser);
        authUser
          ? setPhoneNumber(authUser.phoneNumber)
          : setPhoneNumber('');
      },
    );
    return () => {
      unlisten();
    }
  }, []);

  useEffect(() => {
    setIsUpdating(stateIsUpdating);
  }, [stateIsUpdating]);

  const handleUpdatePhoneNumber = async () => {
    if (!firebase.auth().currentUser) return;
    let currentUser = firebase.auth().currentUser;

    const newPhoneNumber = window.prompt('Enter your new phone number');
    if(!newPhoneNumber || newPhoneNumber==="") return;
    if (newPhoneNumber === currentUser.phoneNumber) {
      return alert("same phone number");
    }

    if (!window.appVerifier) {
      return alert("App verifier not defined!");
    }

    // if (!window.appVerifier) {
    //   appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    //     'size': 'invisible',
    //     callback: (response) => console.log('callback', response),
    //   });
    // }

    // await currentUser.reauthenticateWithPhoneNumber(currentUser.phoneNumber, appVerifier);
    try {
      setIsUpdating(true);
      const phoneProvider = new firebase.auth.PhoneAuthProvider();
      const id = await phoneProvider.verifyPhoneNumber(newPhoneNumber, window.appVerifier);
      const code = window.prompt('Enter your new phone sms code');
      const cred = firebase.auth.PhoneAuthProvider.credential(id, code);
      await currentUser.updatePhoneNumber(cred);
      dispatch(changePhoneNumber());
      setPhoneNumber(currentUser.phoneNumber)
    } catch (error) {
      console.log("change phone number error:", error);
      alert("Error change phone number: " + (error && error.message ? error.message : "unknow error"));
    } finally {
      setIsUpdating(false);
    }
  }

  useEffect(() => {
    if (user.id != null) {
      dispatch(getLoginUserAddresses(user.id));
      setUserName(user.user_name);
    }
  }, [user, dispatch, stateIsUpdating]);

  const handleNewAddress = () => {
    dispatch(clearAddress({}));
    history.push('/new-address');
  }

  const handleDeleteAddress = (v) => {
    var data = { "id": v.id, 'user_id': user.id };
    dispatch(deleteAddress(data, () => {
      dispatch(getLoginUserAddresses(user.id));
    }));
  }

  const handleEditAddress = (id) => {
    history.push('/address-detail/' + user.id + '/' + id);
  }

  const handleMakeDefault = (v) => {
    var data = { "id": v.id, 'user_id': user.id };
    dispatch(makeDefaultAddress(data, () => {
      dispatch(getLoginUserAddresses(user.id));
    }));
  }

  const handleUpdateEmail = () => {
    history.push('/email-update');
  }

  const handleName = (e) => {
    setUserName(e.target.value);
  }

  const handleUpdateUserProfile = (e) => {
    var value = { "id": user.id, "user_name": userName };
    dispatch(updateUserProfile(value, () => {
      setIsEditable(false);
    }));
  }

  const handleCancel = () => {
    setIsEditable(false);
  }
  return (
    <div
      className={clsx(classes.root, className)}
    >
      {smallScreen ? <span /> :
        <Box display="flex" p={1} >
          <Box flexGrow={1}>
            <Typography style={{ marginTop: '20px', fontSize: '16px', color: theme.palette.primary.main }}>{'My Profile'}</Typography></Box>
        </Box>}
      <table style={{ padding: smallScreen ? '10px' : '30px' }}>
        <tbody>
          <tr>
            <td>
              <div style={{ marginBottom: '10px' }}>
                <Box style={{ width: '130px' }}><Typography style={{ color: 'grey', textAlign: 'left' }}>{'FCS ID'}</Typography></Box>
                <Box display="flex">
                  <Typography style={{ color: 'black', textAlign: 'left' }}>{removeFCSString(user.fcs_id)}</Typography>
                </Box>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ marginBottom: '10px', }}>
                <Box style={{ width: '130px' }}><Typography style={{ color: 'grey', textAlign: 'left' }}>{'Name'}</Typography></Box>
                <Box display="flex">
                  {isEditable ? <TextField
                    variant="outlined"
                    autoComplete="off"
                    size={"small"}
                    placeholder=""
                    value={userName ? userName || '' : ''}
                    style={{ width: '200px', marginRight: "5px" }}
                    onChange={handleName}
                  /> : <Typography style={{ marginTop: '5px', paddingRight: "10px", color: 'black', textAlign: 'left' }}>{user.user_name}</Typography>}
                  {isEditable ? <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                    color="primary"
                    onClick={handleUpdateUserProfile}
                    startIcon={<SaveIcon />}
                  >{stateIsUpdating ? "Updating..." : "Save"} </Button> : <div />}
                  {isEditable ? <Button variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                    color="primary"
                    onClick={handleCancel}
                    startIcon={<CancelIcon />}
                  >{"Cancel"} </Button> : <div />}
                </Box>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ marginBottom: '10px' }}>
                <Box style={{ width: '130px' }}><Typography style={{ color: 'grey', textAlign: 'left' }}>{'Phone Number'}</Typography></Box>
                <Box display="flex">
                  <Typography style={{ color: 'black', textAlign: 'left', marginTop: '5px', paddingRight: "10px" }}>{phoneNumber}</Typography>
                  {allowPhoneNumberUpdate && false ?
                    <Button disabled={isUpdating} size="small" variant="contained" style={{ float: 'left', marginBottom: '10px', marginRight: '10px', textTransform: 'none' }}
                      onClick={handleUpdatePhoneNumber}
                    > {isUpdating ? "Updating..." : "Update my phone number"}</Button> : <span></span>
                  }
                </Box>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ marginBottom: '10px' }}>
                <Box style={{ width: '130px' }}><Typography style={{ color: 'grey', textAlign: 'left' }}>{'Email'}</Typography></Box>
                <Box display="flex">
                  <Typography style={{ color: 'black', textAlign: 'left', marginTop: '5px', paddingRight: "10px" }}>{user.email !== undefined ? user.email : ""}</Typography>
                  <EditIcon style={{ cursor: 'pointer' }} color="primary" size="small" onClick={handleUpdateEmail} />
                </Box>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'left', marginBottom: '10px', }}>
              <Grid container spacing={0} style={{}}>
                <Box >
                  <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Delivery/Pickup addresses'}</Typography>
                </Box>
                <Box>
                  <Button size="small" variant="contained" style={{ float: 'left', marginBottom: '10px', marginRight: '10px', textTransform: 'none' }}
                    onClick={handleNewAddress}
                    startIcon={<AddIcon />}
                  > Add address</Button>
                </Box>
              </Grid>
              <div style={{ display: 'inline-flex', width: '100%', marginTop: '5px', flexWrap: 'wrap', }}>
                {addresses ? addresses.map((value) => {
                  return <Paper key={value.id} style={{ marginRight: '5px', marginBottom: '5px', border: value.is_default ? '2px solid green' : '2px solid lightgray' }} >
                    <AddressCard address={value}
                      onMakeDefault={(v) => { handleMakeDefault(v) }}
                      onEdit={(v) => { handleEditAddress(v) }}
                      onDelete={(v) => { handleDeleteAddress(v) }}>

                    </AddressCard>
                  </Paper>
                }) : <span />
                }
              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};


function mapStateToProps(state, ownProps) {
  const allowPhoneNumberUpdate = state.app.setting ? state.app.setting['allow_phone_update'] : false;

  return {
    allowPhoneNumberUpdate,
  };
}
export default connect(mapStateToProps)(Profile)