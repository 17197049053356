import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';

import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import BackIcon from '@material-ui/icons/ArrowBack';
import { Box, makeStyles, NativeSelect, Grid, InputBase, withStyles, IconButton, TextField, FormControl, FormHelperText } from '@material-ui/core';
import theme from '../../theme';
import { connect, useSelector } from 'react-redux';
import { getPickupDates, getPickupZones } from '../../actions/app';
import AddressCard from '../address_card';
import { changeCustomerPickupAddress, getPickupDetail, setPickupData, updateAdminPickupRequest } from '../../actions/pickup';
import { convertISOToDate, removeFCSString } from '../../util';
import firebase from "../../firebase_config";
import { getCustomerAddresses, getCustomerDetail } from '../../actions/user';
import { getDropoffDetail, completeDropoff, uploadDropoffPhotos } from '../../actions/dropoff';
import { getStaffsData } from '../../actions/user';
import PrintDropoffQrcode from './print_dropoff_qrcode';
import { QrcodeIcon } from '../../icons';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
}));

function DropoffComplete(props) {
    const classes = useStyles();
    const { title, history, dispatch, smallScreen } = props;

    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const data = useSelector(state => state.dropoff.dropoffDetail);
    const [qrCodeData, setQrCodeData] = React.useState(null);

    const [photoUrls, setPhotoUrls] = React.useState([]);
    const [photos, setPhotos] = React.useState([]);
    const [displayPhotoUrls, setDisplayPhotoUrls] = React.useState([]);
    const [showQrcode, setShowQrcode] = useState(false);

    const [remark, setRemark] = useState();

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var dropoffId = pathArr[2];

    useEffect(() => {
        dispatch(getStaffsData());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getDropoffDetail(dropoffId));
    }, [dispatch, dropoffId]);

    useEffect(() => {
        if (data && data.id) {
            setRemark(data.remark);
            setPhotoUrls(data.photo_urls ? data.photo_urls : []);
            var values = (data.photo_urls ? data.photo_urls : []).map((v) => ({ id: v, is_url: true, url: v }));
            setDisplayPhotoUrls(values);

            setQrCodeData({ ...data, created_date: convertISOToDate(data.created_date) });
        }
    }, [data]);

    const handleRemark = (e) => {
        setRemark(e.target.value);
    }

    const handleBack = () => {
        dispatch(setPickupData({}));
        dispatch(changeCustomerPickupAddress({}));
        history.goBack();
    }

    const handleCompleteDropoff = () => {
        dispatch(uploadDropoffPhotos(dropoffId, photos, (uploadedUrls) => {
            console.log("photo url:", uploadedUrls);
            var _photoUrls = [...photoUrls];
            _photoUrls.push(...uploadedUrls);
            var value = {
                "id": data.id,
                "remark": remark,
                "photo_urls": _photoUrls
            };
            dispatch(completeDropoff(value, () => {
                history.goBack();
            }))
        }))
    }

    const handleImageChange = (event) => {
        let file = null;

        var fileList = event.target.files;
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i].type.match(/^image\//)) {
                file = fileList[i];
                break;
            }
        }

        var _photos = [...photos];
        if (file !== null) {
            _photos.push(file);
            setPhotos(_photos);
            var _displayPhotoUrls = [...displayPhotoUrls];
            _displayPhotoUrls.push({ id: file.name, is_url: false, file: file });
            setDisplayPhotoUrls(_displayPhotoUrls);
        }
    }
    const handleClickPhoto = (event) => {
        document.getElementById('photo-input').click();
    }

    const handleDeletePhoto = (e, item) => {
        e.preventDefault();
        if (item.is_url) {
            var _photoUrls = [...photoUrls];
            for (var i = 0; i < _photoUrls.length; i++) {
                if (item.url === _photoUrls[i]) {
                    _photoUrls.splice(i, 1);
                }
            }
            setPhotoUrls(_photoUrls);
        } else {
            var _photos = [...photos];
            for (var i = 0; i < _photos.length; i++) {
                if (item.name === _photos[i].name) {
                    _photos.splice(i, 1);
                }
            }
            setPhotos(_photos);
        }
        var _displayPhotoUrls = [...displayPhotoUrls];

        for (var i = 0; i < _displayPhotoUrls.length; i++) {
            if (item.id === _displayPhotoUrls[i].id) {
                _displayPhotoUrls.splice(i, 1);
            }
        }
        setDisplayPhotoUrls(_displayPhotoUrls);
    }

    const handleOnQrcodeDialogClose = () => {
        setShowQrcode(false);
    };

    const handlePrintQrcode = (e) => {
        e.stopPropagation();
        setShowQrcode(true);
    }

    return (

        <div className={classes.root}>
            <Box display="flex" p={1}>
                <Box><Button color="primary" onClick={handleBack} ><BackIcon /></Button></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table style={{ padding: smallScreen ? "5px" : '30px' }}>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Drop-Off Number'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{data.dropoff_number !== undefined ? data.dropoff_number : ''}</Typography>
                                    <QrcodeIcon onClick={e => handlePrintQrcode(e)} style={{ width: "20px", height: "20px", cursor: "pointer" }} />
                                </Box>
                                <Box display="flex" >
                                    <Typography style={{ padding: '2px', color: 'grey', textAlign: 'left', border :'#807979 1px solid', borderRadius:'5px' }}>{data.status !== undefined ? data.status : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Drop-Off Date'}</Typography>
                                <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{data.created_date !== undefined ? convertISOToDate(data.created_date) : ''}</Typography>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Customer Name'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{data.customer_name !== undefined ? data.customer_name : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left', color: "grey" }}>{data.customer_fcs_id !== undefined ? '(' + removeFCSString(data.customer_fcs_id) + ')' : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Phone Number'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{data.customer_phone_number !== undefined ? data.customer_phone_number : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', paddingTop: '30px' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Grid container spacing={0} style={{}}>
                                    <Box >
                                        <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Photos'}</Typography>
                                    </Box>
                                    <Box>
                                        <input
                                            style={{ padding: '10px', display: "none" }}
                                            type='file'
                                            accept="image/*"
                                            capture="environment"
                                            onChange={handleImageChange}
                                            id="photo-input"
                                        />
                                        <Button size="small" variant="contained" style={{ float: 'left', marginBottom: '10px', marginRight: '10px', textTransform: 'none' }}
                                            onClick={handleClickPhoto}
                                            startIcon={<AddIcon />}
                                        > Select Photo</Button>
                                    </Box>
                                </Grid>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: '5px', flexWrap: 'wrap', }}>                                        {
                                    displayPhotoUrls.map((i, index) =>
                                        <Box key={index} style={{ marginRight: '5px', marginBottom: "5px" }}>
                                            <div className={classes.parent}>
                                                <div className={classes.button}>
                                                    <IconButton size="small" style={{ color: "lightgrey" }} onClick={(e) => handleDeletePhoto(e, i)}><CancelIcon /></IconButton>
                                                </div>
                                                <img id="output" alt={i.name}
                                                    style={{ padding: '10px', height: "100px", width: "100px", border: "1px solid lightgrey" }}
                                                    src={i.is_url ? i.url : URL.createObjectURL(i.file)} />
                                            </div>
                                        </Box>
                                    )
                                }
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Remark'}</Typography>
                                <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    size={"small"}
                                    value={remark ? remark : '' || ''}
                                    style={{ width: '400px' }}
                                    multiline
                                    rows={3}
                                    onChange={handleRemark}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                <Box>
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        color="primary"
                                        onClick={handleCompleteDropoff}
                                        startIcon={<SaveIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Complete"} </Button>
                                </Box>
                            </Grid>
                        </td>
                    </tr>
                </tbody>
            </table>
            {qrCodeData && showQrcode ? <PrintDropoffQrcode dropoff={qrCodeData} open={showQrcode} onClose={handleOnQrcodeDialogClose} /> : <span />}
        </div>
    );
}

DropoffComplete.propTypes = {
    history: PropTypes.object,
    open: PropTypes.bool,
    callback: PropTypes.func
};

export default connect()(DropoffComplete);