import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewListIcon from '@material-ui/icons/ViewList';
import { connect, useSelector } from 'react-redux';
import { withCookies, useCookies } from 'react-cookie';

const ToggleViewButtons = (props) => {
  const { dispatch } = props;
  const [cookies, setCookie, removeCookie] = useCookies(['my-package-view']);
  let myPackageView = props.cookies.get('my-package-view');
  if (!myPackageView) {
    myPackageView = "list";
  }

  const handleFormat = (event, newView) => {
    if (newView == null) return;
    setCookie('my-package-view', newView, { path: '/' });
  };

  return (
    <ToggleButtonGroup size="small" value={myPackageView} exclusive onChange={handleFormat} aria-label="toggle view" style={{ paddingLeft: '5px' }}>
      <ToggleButton value="grid" aria-label="grid">
        <ViewComfyIcon fontSize="small" />
      </ToggleButton>
      <ToggleButton value="list" aria-label="list">
        <ViewListIcon fontSize="small" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default withCookies(connect()(ToggleViewButtons));
