import { Divider, Grid, Typography, Link, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { getPackageDetail } from '../../actions/explore';
import theme from '../../theme';
import { formatDateToLocal } from '../../util';
import BackIcon from '@material-ui/icons/ArrowBack';
import ShowPhotoDialog from '../show_photo_dialog';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { clearPackageUser } from '../../actions/user';

const useStyles = makeStyles(th => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        paddingLeft: '10px'
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
}));


function getReceivedDate(status) {
    var convertDate = '';
    if (status) {
        status.forEach((value, index) => {
            if (value['status'] === 'received') {
                var date = new Date(value['date'].toDate());
                convertDate = formatDateToLocal(date);
            }
        });
    }
    return convertDate;
}

const ExplorePackageDetail = (props) => {
    const { title, history, dispatch, smallScreen } = props;
    const classes = useStyles();
    const statePackageDetail = useSelector(state => state.explore.packageDetail);
    const [packageDetail, setPackage] = React.useState(statePackageDetail);
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [selectedPhoto, setSelectedPhoto] = React.useState(packageDetail['photo_urls'] ? packageDetail['photo_urls'][0] : "#");
    const _privileges = useSelector(state => state.app.privileges);
    const stateIsUpdating = useSelector(state => state.app.isUpdating);

    const [senderDisplay, setSenderDisplay] = React.useState({});
    const [consigneeDisplay, setConsigneeDisplay] = React.useState({});

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var packageId = pathArr[2];

    useEffect(() => {
        setPackage(statePackageDetail);
        if (statePackageDetail) {
            consigneeDisplay.fcs_id = statePackageDetail.fcs_id;
            consigneeDisplay.user_name = statePackageDetail.user_name;
            consigneeDisplay.phone_number = statePackageDetail.phone_number;
            senderDisplay.fcs_id = statePackageDetail.sender_fcs_id;
            senderDisplay.user_name = statePackageDetail.sender_name;
            senderDisplay.phone_number = statePackageDetail.sender_phone_number;
        }
    }, [statePackageDetail]);

    useEffect(() => {
        dispatch(getPackageDetail(packageId));
    }, [packageId, dispatch]);

    useEffect(() => {
        if (packageId !== packageDetail.id) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [packageId, packageDetail]);

    const handleBack = () => {
        history.goBack();
    }

    const isAdmin = () => {
        return _privileges.includes('admin') || _privileges.includes('sa');
    }

    const handleClickConsignee = (e) => {
        e.preventDefault();
        history.push("/search-consignee");
    }

    const handleClickSender = (e) => {
        e.preventDefault();
        history.push("/search-sender");
    }

    const handleEdit = (e) => {
        e.preventDefault();
        dispatch(clearPackageUser());
        history.push("/package-edit/" + packageId);
    }

    return (
        <div className={classes.root}>
            <Box display="flex" p={1} >
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{"Package Detail"}</Typography></Box>
            </Box>
            <Grid item xs={12} sm={6} style={{ paddingLeft: '10px' }}>
                <div style={{ marginBottom: "5px", textAlign: 'left' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left' }}>Tracking number</Typography>
                    <Typography style={{ marginLeft: 0 }}> {isLoading ? 'Loading...' : packageDetail['tracking_id'] ? packageDetail['tracking_id'] : "Not found"}</Typography>
                </div>
                <div style={{ marginBottom: "5px", textAlign: 'left', paddingTop: '15px' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left' }}>Received date</Typography>
                    <Typography style={packageDetail['remark'] === 'Not found' ? { color: "red", marginLeft: 0 } : { marginLeft: 0 }}> {getReceivedDate(packageDetail['all_status'])}</Typography>
                </div>
                <div style={{ marginBottom: "5px", textAlign: 'left', paddingTop: '15px' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left' }}>Remark</Typography>
                    <Typography
                        style={packageDetail['remark'] === 'Not found' ? { color: "red", marginLeft: 0 } : { marginLeft: 0 }}>
                        {packageDetail['remark']}
                    </Typography>
                </div>
                <div style={{ marginBottom: "5px", textAlign: 'left', paddingTop: '15px' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left' }}>Status</Typography>
                    <Typography
                        style={packageDetail['remark'] === 'Not found' ? { color: "red", marginLeft: 0 } : { marginLeft: 0 }}>
                        {packageDetail['status']}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingLeft: '10px', paddingTop: '15px' }}>
                <div style={{ marginBottom: "5px", textAlign: 'left' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left' }}>Photos</Typography>

                    <div style={{ display: 'inline-flex', width: '100%', marginTop: '5px', flexWrap: 'wrap', }}>

                        {
                            packageDetail['photo_urls'] ? packageDetail['photo_urls'].map((i, index) =>
                                <Box key={index} style={{ marginRight: '5px', marginBottom: "5px" }}>
                                    <img id="output" alt={i.name}
                                        style={{ padding: '10px', height: "150px", width: "150px", border: "1px solid lightgrey" }}
                                        src={i}
                                        onClick={(e) => {
                                            setSelectedPhoto(i);
                                            setOpen(true);
                                        }
                                        } /></Box>
                            )
                                : <span />}
                    </div>
                </div>
            </Grid>

            {isAdmin() && packageDetail && packageDetail.status === "processed" ?
                <div style={{ paddingTop: '30px' }}>
                    <Typography style={{ color: 'grey', textAlign: 'left' }}>{'Admin Only'}</Typography>
                    <Divider style={{ backgroundColor: 'lightgrey' }}></Divider>
                    <table style={{ padding: smallScreen ? '10px' : '15px' }}>
                        <tbody>
                            <tr>
                                <td style={{ paddingRight: '50px' }}><Typography style={{ paddingRight: '50px', color: 'grey', textAlign: 'left' }}>{'Consignee'}
                                </Typography>
                                </td>
                                <td><Typography style={{ paddingRight: '50px', color: 'grey', textAlign: 'left' }}>{'Sender'}
                                </Typography></td>
                            </tr>
                            <tr>
                                <td>
                                    <Box display="flex">
                                        <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{consigneeDisplay.fcs_id !== undefined ? consigneeDisplay.fcs_id : ''}</Typography>
                                    </Box>
                                </td>
                                <td>
                                    <Box display="flex">
                                        <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{senderDisplay.fcs_id !== undefined ? senderDisplay.fcs_id : ''}</Typography>
                                    </Box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Box display="flex">
                                        <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{consigneeDisplay.user_name !== undefined ? consigneeDisplay.user_name : ''}</Typography>
                                    </Box>
                                </td>
                                <td>
                                    <Box display="flex">
                                        <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{senderDisplay.user_name !== undefined ? senderDisplay.user_name : ''}</Typography>
                                    </Box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Box display="flex">
                                        <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{consigneeDisplay.phone_number !== undefined ? consigneeDisplay.phone_number : ''}</Typography>
                                    </Box>
                                </td>
                                <td>
                                    <Box display="flex">
                                        <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{senderDisplay.phone_number !== undefined ? senderDisplay.phone_number : ''}</Typography>
                                    </Box>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left' }}>
                                    <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                        <Box >
                                            <Button variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                                color="primary"
                                                onClick={handleEdit}
                                                startIcon={<EditIcon />}
                                            >{stateIsUpdating ? "Updating..." : "Edit"} </Button>
                                        </Box>

                                    </Grid>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                : <span />
            }

            <ShowPhotoDialog
                open={open}
                selectedPhoto={selectedPhoto}
                photoUrls={packageDetail.photo_urls}
                onClose={(v) => { setOpen(v) }}
                onChangeSelectedPhoto={(v) => { setSelectedPhoto(v) }}
            ></ShowPhotoDialog>
        </div>

    );
};

ExplorePackageDetail.propTypes = {
    pDetail: PropTypes.object
};

export default withRouter(connect()(ExplorePackageDetail));
