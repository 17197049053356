import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ShowPhotoDialog = (props) => {
    const { open, onClose, onChangeSelectedPhoto, photoUrls, selectedPhoto } = props;

    const handleClose = () => {
        onClose(false);
    }

    const handleSelectedPhoto = (value) => {
        onChangeSelectedPhoto(value);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={false}
            maxWidth="xl"            
            className="photo-dialog"
        >
            <DialogTitle id="alert-dialog-title" onClose={handleClose}>
                {"Photos"}
            </DialogTitle>
            <DialogContent style={{paddingTop:"0px"}}>
                <Grid item xs={12} justifyContent="center" container direction="row">
                    <Grid item style={{margin:"5px"}}>
                        <TransformWrapper>
                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                <React.Fragment>
                                    <Box display="flex" justifyContent="flex-start">
                                        <div className="tools">
                                            <button onClick={() => zoomIn()}>+</button>
                                            <button onClick={() => zoomOut()}>-</button>
                                            <button onClick={() => resetTransform()}>reset</button>
                                        </div>
                                    </Box>
                                    <TransformComponent>
                                        <img src={selectedPhoto} className="show-img" alt="logo" />
                                    </TransformComponent>
                                </React.Fragment>
                            )}
                        </TransformWrapper>
                    </Grid>
                    <br />
                    <Grid container>
                        {photoUrls ? photoUrls.map((value, index) => (
                            <Grid key={index} item className={value==selectedPhoto?"square-container-selected":"square-container"}>
                                <Box className="square" > <img src={value} className="thumnail-img" alt="logo"
                                    onClick={(e) => handleSelectedPhoto(value)} /></Box>
                            </Grid>
                        )) : <span />}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ShowPhotoDialog;