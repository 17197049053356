import {
    GET_USER_DETAIL,
    GET_USERS,
    GET_USER_ROW_COUNT,
    CHANGE_USER_ORDER,
    GET_USER_DELIVERY_ADDRESSES,
    CHANGE_USER_PAGE,
    SET_USER_SEARCH_TERM,
    GET_ADDRESS_DETAIL,
    GET_USER_PACKAGES,
    GET_USER_PACKAGES_COUNT,
    GET_STAFFS,
    GET_USER_PICKUPS,
    GET_USER_PICKUPS_COUNT,
    CLEAR_ADDRESS,
    GET_CUSTOMERS,
    GET_CUSTOMER_DELIVERY_ADDRESSES,
    SET_SELECTED_CUSTOMER,
    SET_SENDER,
    SET_CONSIGNEE,
    SET_CUSTOMER_ADDRESS,
    CLEAR_PACKAGE_USERS
} from "../actions/user";


const initialState = {
    users: [],
    userDetail: {},
    addresses: [],
    address: {},
    count: 0,
    page: 0,
    order: { "field_name": "updated_date", "direction": "desc" },
    currentPage: '',
    term: '',
    packages: [],
    staffs: [],
    pickups: [],
    customers: [],
    customerAddresses: [],
    selectedCustomer: {},
    selectedAddress: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USERS: {
            return {
                ...state,
                users: action.payload['users'],
            };
        }
        case GET_USER_ROW_COUNT: {
            return {
                ...state,
                count: action.count,
            };
        }
        case GET_USER_DETAIL: {
            return {
                ...state,
                userDetail: action.payload['userDetail'],
            };
        }
        case GET_USER_DELIVERY_ADDRESSES: {
            return {
                ...state,
                addresses: action.payload
            };
        }
        case CHANGE_USER_ORDER: {
            return {
                ...state,
                order: action.order,
            };
        }
        case CHANGE_USER_PAGE: {
            return {
                ...state,
                page: action.page,
            };
        }
        case SET_USER_SEARCH_TERM: {
            return {
                ...state,
                term: action.term,
            };
        }
        case GET_ADDRESS_DETAIL: {
            return {
                ...state,
                address: action.address,
            };
        }
        case GET_USER_PACKAGES: {
            return {
                ...state,
                packages: action.packages
            }
        }
        case GET_USER_PACKAGES_COUNT: {
            return {
                ...state,
                count: action.count
            }
        }
        case GET_STAFFS: {
            return {
                ...state,
                staffs: action.staffs
            }
        }
        case GET_USER_PICKUPS: {
            return {
                ...state,
                pickups: action.pickups
            }
        }
        case GET_USER_PICKUPS_COUNT: {
            return {
                ...state,
                count: action.count
            }
        }
        case CLEAR_ADDRESS: {
            return {
                ...state,
                address: action.address
            }
        }
        case GET_CUSTOMERS: {
            return {
                ...state,
                customers: action.customers
            }
        }
        case GET_CUSTOMER_DELIVERY_ADDRESSES: {
            return {
                ...state,
                customerAddresses: action.payload
            }
        }
        case SET_SELECTED_CUSTOMER: {
            return {
                ...state,
                selectedCustomer: action.customer
            }
        }
        case SET_SENDER: {
            return {
                ...state,
                sender: action.customer
            }
        }
        case CLEAR_PACKAGE_USERS: {
            return {
                ...state,
                sender: null,
                consignee: null
            }
        }
        case SET_CONSIGNEE: {
            return {
                ...state,
                consignee: action.customer
            }
        }
        case SET_CUSTOMER_ADDRESS: {
            return {
                ...state,
                selectedAddress: action.address
            }
        }
        default:
            return state;
    }
}
