import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import BackIcon from '@material-ui/icons/ArrowBack';
import { Box, makeStyles, NativeSelect, Grid, InputBase, withStyles, Paper, TextField } from '@material-ui/core';
import theme from '../../theme';
import { connect, useSelector } from 'react-redux';
import { getPickupDates, getPickupZones, getPickupZonesByZipCode } from '../../actions/app';
import AddressCard from '../address_card';
import { changePickupAddress, getPickupDetail, setPickupData, updatePickupRequest } from '../../actions/pickup';
import { convertStringToDate, formatDateToLocal, getDay } from '../../util';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
}));

function MyPickupEdit(props) {
    const classes = useStyles();
    const { title, history, dispatch, smallScreen } = props;

    const pickupSelector = useSelector(state => state.pickup);

    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const pickupDatesByZone = useSelector(state => state.app.pickupDatesByZone);
    const zoneByZipCode = useSelector(state => state.app.zoneByZipCode);

    const selectedPickupAddress = useSelector(state => state.pickup.pickupAddress);

    const [pickupAddress, setPickupAddress] = useState({});
    const [zoneDates, setZoneDates] = useState([]);


    const [pickupDate, setPickupDate] = useState();
    const [customerRemark, setCustomerRemark] = useState();

    const [data, setData] = useState(pickupSelector.pickupDetail);

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var pickupId = pathArr[2];

    useEffect(() => {
        dispatch(getPickupDetail(pickupId));
    }, [dispatch, pickupId]);

    useEffect(() => {
        setData(pickupSelector.pickupDetail);
    }, [pickupSelector]);

    useEffect(() => {
        if (data.id) {
            setCustomerRemark(data.customer_remark);
            setPickupDate(data.pickup_date ? (data.pickup_date).toDate() : null);
            if (data.pickup_address && !selectedPickupAddress.id) {

                setPickupAddress(data.pickup_address);
                dispatch(getPickupZonesByZipCode(data.pickup_address.zip_code));
            } else {
                setPickupAddress(selectedPickupAddress);
                dispatch(getPickupZonesByZipCode(selectedPickupAddress.zip_code));
            }
        }
    }, [data, selectedPickupAddress, dispatch]);

    useEffect(() => {
        var _dates = [];
        pickupDatesByZone.forEach(d => {
            var dbDate = convertStringToDate(d.id);
            _dates.push(dbDate);
        });
        setZoneDates(_dates);
    }, [pickupDatesByZone]);

    useEffect(() => {
        dispatch(getPickupZones());
        dispatch(getPickupDates());
    }, [dispatch]);

    const handleChangePickupDate = (e) => {
        var date = e.target.value;
        setPickupDate(new Date(date));
    }

    const handleRemark = (e) => {
        setCustomerRemark(e.target.value);
    }

    const handleBack = () => {
        dispatch(setPickupData({}));
        dispatch(changePickupAddress({}));
        history.goBack();
    }

    const handleUpdatePickup = () => {
        var value = {
            "id": data.id,
            "zone_id": zoneByZipCode.id,
            "pickup_date": pickupDate.toISOString(),
            "customer_remark": customerRemark,
            "address_id": pickupAddress.id
        }
        dispatch(updatePickupRequest(value, () => {
            history.goBack();
        }));
    }

    const handleChangeAddress = () => {
        history.push("/addresses");
    }

    return (

        <div className={classes.root}>
            <Box display="flex" p={1}>
                <Box><Button color="primary" onClick={handleBack} ><BackIcon /></Button></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table style={{ padding: smallScreen ? "5px" : '30px' }}>
                <tbody>
                    <tr style={{ height: '50px' }}>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Number'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{data.pickup_number !== undefined ? data.pickup_number : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{data.status !== undefined ? data.status : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <div>
                                    <Box style={{ display: 'inline-flex', marginTop: '5px' }}>
                                        <Typography style={{ color: 'grey', paddingRight: '30px' }}>{'Pickup Address'}</Typography>
                                        <Button size="small" variant="outlined" color="primary" style={{ float: 'left', textTransform: 'none' }}
                                            onClick={handleChangeAddress}
                                        >Select address</Button>
                                    </Box>
                                </div>
                                <div style={{ display: 'inline-flex', marginTop: '5px' }}>
                                    {pickupAddress ? <Paper key={pickupAddress.id} style={{ marginRight: '5px', marginBottom: '5px', width: '280px', height: '165px', border: '1px solid lightgray' }}>
                                        <AddressCard address={pickupAddress}
                                            editIcon={false}
                                            deleteIcon={false}
                                            makeDefaultIcon={false}
                                        />
                                    </Paper> : <span />}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Pickup Zone'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{zoneByZipCode && zoneByZipCode.name ? zoneByZipCode.name : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Pickup Date'}</Typography>
                                <NativeSelect
                                    id="pickup-date-select"
                                    value={pickupDate ? pickupDate : '' || ''}
                                    onChange={handleChangePickupDate}
                                    style={{ width: '200px' }}
                                    input={<BootstrapInput />}
                                >
                                    <option aria-label="None" value="" >Select</option>
                                    {zoneDates.map((d) => {
                                        return (<option name={d} value={d} key={d}>{formatDateToLocal(d, false) + '\t' + getDay(d)}</option>);
                                    })}
                                </NativeSelect>
                            </div>
                        </td>
                    </tr>

                    <tr style={{ height: '50px' }}>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Remark'}</Typography>
                                <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    size={"small"}
                                    value={customerRemark ? customerRemark : '' || ''}
                                    style={{ width: '400px' }}
                                    multiline
                                    rows={3}
                                    onChange={handleRemark}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', color: 'white' }}
                                        color="primary"
                                        onClick={handleUpdatePickup}
                                        startIcon={<SaveIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Update request pickup"} </Button>
                                </Box>

                            </Grid>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

MyPickupEdit.propTypes = {
    history: PropTypes.object,
    open: PropTypes.bool,
    callback: PropTypes.func
};

export default connect()(MyPickupEdit);
