import {
    Box,
    Button, Dialog,
    DialogActions, Grid,
    IconButton,
    TextField, Toolbar, Tooltip, Typography
} from "@material-ui/core";
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { lighten, makeStyles, ThemeProvider, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import { changeExplorePage, downloadData, getExploreData, setFilters } from '../../actions/explore';
import theme from '../../theme';
import { removeFCSString } from "../../util";
import ConfirmDialog from '.././confirm_dialog';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@material-ui/icons/Check';
import { withCookies,useCookies } from 'react-cookie';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewListIcon from '@material-ui/icons/ViewList';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    btn: {
        color: theme.palette.primary.main,
    },
    dot: {
        height: "8px",
        width: "8px",
        backgroundColor: "#e50404",
        borderRadius: "50%",
        display: "inline-block",
        position: "absolute",
        left: "30px",
        top: "25px",
    }
}));

const styles = {
    root: {
        flexGrow: 1,
    },
    button: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        width: 150,
        height: 55
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

};

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const ExploreToolbar = (props) => {
    const classes = useToolbarStyles();
    const { dispatch, selectUser } = props;
    const explore = useSelector(state => state.explore);
    const filters = useSelector(state => state.explore.filters);
    const selectableStatus = useSelector(state => state.explore.selectable_status);

    const [openDialog, setOpenDialog] = React.useState(false);

    const [trackingId, setTrackingId] = React.useState(explore.filters.tracking_id);
    const [status, setStatus] = React.useState(explore.filters.status);
    const [fromReceivedDate, setFromReceivedDate] = React.useState('');
    const [toReceivedDate, setToReceivedDate] = React.useState('');
    const [fromUpdatedDate, setFromUpdatedDate] = React.useState('');
    const [toUpdatedDate, setToUpdatedDate] = React.useState('');
    const [hasFilter, setHasFilter] = React.useState(false);
    const [showDownloadConfirmDialog, setShowDownloadConfirmDialog] = React.useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['packages-view']);
    let packagesView=props.cookies.get('packages-view');
    if (!packagesView){
        packagesView="list";
    }

    useEffect(() => {
        if (filters) {
            for (var i = 0; i < filters.length; i++) {
                var filter = filters[i];
                if (filter.key === 'tracking_id' && filter.value && filter.value !== '') {
                    setTrackingId(filter.value);
                }
                if (filter.key === 'status' && filter.value && filter.value !== '') {
                    setStatus(filter.value);
                }

                if (filter.key === 'from_received_date' && filter.value && filter.value !== '') {
                    setFromReceivedDate(filter.value);
                }

                if (filter.key === 'to_received_date' && filter.value && filter.value !== '') {
                    setToReceivedDate(filter.value);
                }

                if (filter.key === 'from_updated_date' && filter.value && filter.value !== '') {
                    setFromUpdatedDate(filter.value);
                }

                if (filter.key === 'to_updated_date' && filter.value && filter.value !== '') {
                    setToUpdatedDate(filter.value);
                }
            }
        }
        if (!filters || filters.length == 0) {
            setTrackingId("");
            setStatus("");
            setFromReceivedDate("");
            setToReceivedDate("");
            setFromUpdatedDate("");
            setToUpdatedDate("");
            setHasFilter(false);
        } else {
            setHasFilter(true);
        }
    }, [filters]);

    const handleClose = (e) => {
        setOpenDialog(false);
    };

    const handleDialog = (e) => {
        setOpenDialog(true);
    };

    const handleShowOpenDownloadConfirm = (e) => {
        setShowDownloadConfirmDialog(true);
    };
    const handleCancelDownload = (v) => {
        setShowDownloadConfirmDialog(false);
    }
    const handleDownload = () => {
        setShowDownloadConfirmDialog(false);
        dispatch(downloadData());
    }

    const handleApply = (e) => {
        var f = [];
        if (trackingId !== "") {
            f.push({ 'key': 'tracking_id', 'value': trackingId });
        }
        if (status !== "" && status != "All") {
            f.push({ 'key': 'status', 'value': status });
        }
        if (fromReceivedDate !== "") {
            f.push({ 'key': 'from_received_date', 'value': fromReceivedDate });
        }
        if (toReceivedDate !== "") {
            f.push({ 'key': 'to_received_date', 'value': toReceivedDate });
        }
        if (fromUpdatedDate !== "") {
            f.push({ 'key': 'from_updated_date', 'value': fromUpdatedDate });
        }
        if (toUpdatedDate !== "") {
            f.push({ 'key': 'to_updated_date', 'value': toUpdatedDate });
        }

        dispatch(changeExplorePage(0));

        dispatch(getExploreData(f));
        setOpenDialog(false);
    };

    const handleClear = (e) => {
        dispatch(changeExplorePage(0));

        setFromReceivedDate("");
        setToReceivedDate("");
        setFromUpdatedDate("");
        setToUpdatedDate("");
        setTrackingId("");
        setStatus("");

        dispatch(setFilters([]));
        dispatch(getExploreData());
        setOpenDialog(false);
    };

    const handleTrackingId = (e) => {
        console.log('value => ', e.target.value);
        setTrackingId(e.target.value);
    };

    const handleFromReceivedDate = (e) => {
        setFromReceivedDate(e.target.value);
    };

    const handleToReceivedDate = (e) => {
        setToReceivedDate(e.target.value);
    };

    const handleFromUpdatedDate = (e) => {
        setFromUpdatedDate(e.target.value);
    };

    const handleToUpdatedDate = (e) => {
        setToUpdatedDate(e.target.value);
    };

    const handleStatusClick = (e, s) => {
        setStatus(s)
    };

  
    const handleFormat = (event, newView) => {
      if (newView==null) return;
      setCookie('packages-view', newView, { path: '/' });
    };
  

    return (
        <ThemeProvider theme={theme}>
            <Toolbar
                className={clsx(classes.root)}
            >

                <Box textAlign="left" fontWeight="fontWeightBold" flexGrow={1}>
                    <Typography variant="h6" id="tableTitle" component="div" color="primary">
                    </Typography>
                    {selectUser.user_name !== undefined ?
                        <div>
                            <div style={{ display: 'flex', marginBottom: '5px' }}>
                                <Box style={{ width: '100px' }}><Typography style={{ color: 'grey', textAlign: 'left' }}>{'FCS ID'}</Typography></Box>
                                <Box display="flex">
                                    <Typography style={{ color: 'black', textAlign: 'left' }}>{removeFCSString(selectUser.fcs_id)}</Typography>
                                </Box>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Box style={{ width: '100px' }}><Typography style={{ color: 'grey', textAlign: 'left' }}>{'Name'}</Typography></Box>
                                <Box display="flex">
                                    <Typography style={{ color: 'black', textAlign: 'left' }}>{selectUser.user_name}</Typography>
                                </Box>
                            </div>
                        </div> : <span />}
                </Box>

                <div style={{ display: "flex",height: '40px' }}>
                    <ToggleButtonGroup size="small" value={packagesView} exclusive onChange={handleFormat} aria-label="toggle view" style={{ paddingLeft: '5px' }}>
                        <ToggleButton value="grid" aria-label="grid">
                            <ViewComfyIcon fontSize="small" />
                        </ToggleButton>
                        <ToggleButton value="list" aria-label="list">
                            <ViewListIcon fontSize="small" />
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <Tooltip title="Download">
                        <IconButton aria-label="download" onClick={(e) => handleShowOpenDownloadConfirm(e)}>
                            <GetAppIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Filter">
                        <IconButton aria-label="filter" onClick={(e) => handleDialog(e)}>
                            <FilterListIcon color="primary" />
                            <span className={clsx(hasFilter ? classes.dot : "")}></span>
                        </IconButton>
                    </Tooltip>
                    <Dialog maxWidth="lg" aria-labelledby="customized-dialog-title" open={openDialog}>
                        <DialogTitle id="customized-dialog-title" onClose={(e) => handleClose(e)} >
                            Filters
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid item xs={12}>
                                <Grid>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Box style={{ width: '130px' }}>
                                            <Typography style={{ paddingRight: '30px' }}>Tracking id</Typography>
                                        </Box>
                                        <TextField
                                            id="txt-tracking-id"
                                            value={trackingId || ""}
                                            variant="outlined"
                                            size={"small"}
                                            onChange={(e) => handleTrackingId(e)} />
                                    </div>
                                </Grid>
                                <br />
                                <Grid >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Box style={{ width: '130px' }}><Typography style={{ paddingRight: '30px' }}>Status</Typography></Box>
                                        <div>
                                            <Chip variant="outlined" size="small" icon={status != "" ? null : <CheckIcon />} label="All"
                                                onClick={(event) => handleStatusClick(event, "")} style={{ marginRight: '5px' }} />
                                            {selectableStatus.map((s, index) =>
                                                status == s ?
                                                    <Chip key={s} variant="outlined" size="small" icon={<CheckIcon />} label={s}
                                                        onClick={(event) => handleStatusClick(event, s)} style={{ marginRight: '5px' }} />
                                                    : <Chip key={s} variant="outlined" size="small" label={s}
                                                        onClick={(event) => handleStatusClick(event, s)} style={{ marginRight: '5px' }} />
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                                <br />
                                <Grid >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Box style={{ width: '130px' }}>
                                            <Typography style={{ paddingRight: '30px' }}>Received date</Typography>
                                        </Box>
                                        <div>
                                            <Typography>From</Typography>
                                            <TextField type="date"
                                                id="from-receiced-date"
                                                variant="outlined"
                                                size={"small"}
                                                value={fromReceivedDate}
                                                onChange={(e) => handleFromReceivedDate(e)} />
                                        </div>
                                        <Box style={{ width: '10px' }}></Box>
                                        <div>
                                            <Typography>To</Typography>
                                            <TextField type="date"
                                                id="to-receiced-date"
                                                variant="outlined"
                                                size={"small"}
                                                value={toReceivedDate}
                                                onChange={(e) => handleToReceivedDate(e)} />
                                        </div>

                                    </div>
                                </Grid>
                                <br />
                                <Grid >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Box style={{ width: '130px' }}><Typography style={{ paddingRight: '30px' }}>Updated date</Typography></Box>
                                        <div>
                                            <Typography>From</Typography>
                                            <TextField type="date"
                                                id="from-updated-date"
                                                variant="outlined"
                                                size={"small"}
                                                value={fromUpdatedDate || ""}
                                                onChange={(e) => handleFromUpdatedDate(e)}
                                            />
                                        </div>
                                        <Box style={{ width: '10px' }}></Box>
                                        <div>
                                            <Typography>To</Typography>
                                            <TextField type="date"
                                                id="to-updated-date"
                                                variant="outlined"
                                                size={"small"}
                                                value={toUpdatedDate || ""}
                                                onChange={(e) => handleToUpdatedDate(e)}
                                            />
                                        </div>

                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleClear} className={classes.btn}>
                                Clear
                            </Button>
                            <Button autoFocus onClick={handleApply} className={classes.btn}>
                                Apply
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Toolbar>
            {showDownloadConfirmDialog ? <ConfirmDialog
                msg={'Download data?'}
                openDialog={showDownloadConfirmDialog}
                confirmBtnLabel={"Download"}
                onCancel={(v) => handleCancelDownload(v)}
                onContinue={(v) => handleDownload(v)}></ConfirmDialog> : <div />}

        </ThemeProvider>
    );
};

ExploreToolbar.propTypes = {
    dispatch: PropTypes.func.isRequired,
    selectUser: PropTypes.object
};
export default withCookies(connect()(ExploreToolbar));
