
import firebase from "../firebase_config";
import { config } from "../firebase_config";
import 'firebase/firestore';
import {
    getUser,
    SET_IS_UPDATING,
    SET_SHOW_ALERT
} from "../actions/app";

export const GET_USERS = 'GET_USERS';
export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const GET_USER_DELIVERY_ADDRESSES = 'GET_USER_DELIVERY_ADDRESSES';
export const GET_USER_ROW_COUNT = "GET_USER_ROW_COUNT";
export const GET_USER_PACKAGES = "GET_USER_PACKAGES";
export const GET_USER_PACKAGES_COUNT = "GET_USER_PACKAGES_COUNT";
export const GET_USER_PICKUPS = "GET_USER_PICKUPS";
export const GET_USER_PICKUPS_COUNT = "GET_USER_PICKUPS_COUNT";
export const CHANGE_USER_ORDER = "CHANGE_USER_ORDER";
export const CHANGE_USER_PAGE = "CHANGE_USER_PAGE";
export const SET_USER_SEARCH_TERM = 'SET_USER_SEARCH_TERM';
export const GET_ADDRESS_DETAIL = 'GET_ADDRESS_DETAIL';
export const GET_STAFFS = 'GET_STAFFS';
export const CLEAR_ADDRESS = "CLEAR_ADDRESS";
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMER_DELIVERY_ADDRESSES = 'GET_CUSTOMER_DELIVERY_ADDRESSES';
export const SET_CUSTOMER_SEARCH_TERM = "SET_CUSTOMER_SEARCH_TERM";
export const SET_SELECTED_CUSTOMER = "SET_SELECTED_CUSTOMER";
export const SET_CUSTOMER_ADDRESS = "SET_CUSTOMER_ADDRESS";
export const SET_SENDER = "SET_SENDER";
export const SET_CONSIGNEE = "SET_CONSIGNEE";
export const CLEAR_PACKAGE_USERS = "CLEAR_PACKAGE_USERS";

var _message = '';
var _color = '';

export const getUserDetail = (id) => (dispatch, getState) => {
    dispatch({
        type: GET_USER_DETAIL,
        payload: {
            userDetail: {}
        }
    })
    dispatch({
        type: GET_USER_DELIVERY_ADDRESSES,
        payload: []
    });

    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            firebase.firestore().collection("users").doc(id)
                .get()
                .then(function (doc) {
                    var userDetail = doc.data();
                    userDetail['id'] = doc.id;
                    dispatch(getAddresses(doc.id));

                    dispatch({
                        type: GET_USER_DETAIL,
                        payload: { userDetail }
                    })

                }).catch(function (error) {
                    console.log("Error getting documents: ", error);
                });
        }
    });

}

export const getUsersData = (order) => (dispatch, getState) => {
    if (!!!getState().app.isLogin) return;
    if (!order) {
        order = getState().user.order;
    }
    dispatch(changeUsersOrder(order));

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        var offset = getState().user.page * getState().app.rowsPerPage;
        var limit = getState().app.rowsPerPage;
        var sorts = [];
        if (order && order['field_name'] && order['field_name'] !== ""
            && order['direction'] && order['direction'] !== "") {
            sorts = [order['field_name'] + " " + order['direction']];
        }

        var data = { limit: limit, offset: offset, sorts: sorts };
        fetch(config.fcsReportUrl + "/api/data/users", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var users = [];
                if (result && result.data) {
                    result.data.forEach(function (u) {
                        u["sn"] = ++offset;
                        users.push(u);
                    });
                }
                dispatch({
                    type: GET_USERS,
                    payload: {
                        users
                    }
                });
            }).catch((e) => {
                console.log("error:", e);
            });

        fetch(config.fcsReportUrl + "/api/row/users", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var count = 0;
                if (result && result.data) {
                    count = result.data;
                }
                dispatch({
                    type: GET_USER_ROW_COUNT,
                    count
                });
            }).catch((e) => {
                console.log("error:", e);
            });
    });
}

export const getUserPickups = (order) => (dispatch, getState) => {
    if (!order) {
        order = getState().pickup.order;
    }

    dispatch(changeUsersOrder(order));

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;

        var offset = getState().pickup.page * getState().app.rowsPerPage;
        var limit = getState().app.rowsPerPage;
        var sorts = [];
        if (order && order['field_name'] && order['field_name'] !== ""
            && order['direction'] && order['direction'] !== "") {
            sorts = [order['field_name'] + " " + order['direction']];
        }
        var _filters = [];
        var _user = getState().app.user;
        if (_user.id !== undefined) {
            _filters.push({ 'field': 'customer_id', 'compare': "==", 'value': _user.id });
        }

        var data = { limit: limit, offset: offset, sorts: sorts, filters: _filters };
        fetch(config.fcsReportUrl + "/api/data/pickups", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var pickups = [];
                if (result && result.data) {
                    result.data.forEach(function (p) {
                        p["sn"] = ++offset;
                        pickups.push(p);
                    });
                }
                dispatch({
                    type: GET_USER_PICKUPS,
                    pickups

                });
            }).catch((e) => {
                console.log("error:", e);
            });

        fetch(config.fcsReportUrl + "/api/row/pickups", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var count = 0;
                if (result && result.data) {
                    count = result.data;
                }
                dispatch({
                    type: GET_USER_PICKUPS_COUNT,
                    count
                });
            }).catch((e) => {
                console.log("error:", e);
            });
    });
}

export const searchUsers = (term) => (dispatch, getState) => {
    console.log("term", term);
    if (!!!firebase.auth().currentUser) return;
    if (!term || term === "") {
        dispatch(getUsersData());
        return;
    }
    console.log("search user");
    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsReportUrl + "/api/fts/users/" + term + "/10", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
        }).then(response => response.json())
            .then((result) => {
                var users = [];
                if (result && result.data) {
                    let index = 0;
                    result.data.forEach(function (u) {
                        u["sn"] = ++index;
                        users.push(u);
                    });
                }
                dispatch({
                    type: GET_USERS,
                    payload: {
                        users
                    }
                });
                var count = users.length;
                dispatch({
                    type: GET_USER_ROW_COUNT,
                    count
                });
            }).catch((e) => {
                console.log("error:", e);
            });

    });
}

export const updateUser = (user, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/users", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(user)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Update user successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const activateUser = (user, activate) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/enable_user", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify({ "id": user.id, "enabled": activate })
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = activate ? "Activate successful!" : "Deactivate successful!";
                    _color = "green";
                    dispatch(getUserDetail(user.id));
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
            });
    });
}

export const deleteUser = (user, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/invites", {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify({ "id": user.id })
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Delete successful!";
                    _color = "green";
                    // dispatch(getUserDetail(user.id));
                    if (onSuccess) {
                        onSuccess();
                    }
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
            });
    });
}


export const inviteUser = (user, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/invites", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(user)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Invite customer successful!";
                    _color = "green";
                    dispatch(getUsersData());
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const joinInvite = (onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/join_invite", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Join Invited successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const requestInvitation = (user, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/signup", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(user)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Request Invitation successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}


export const acceptUser = (user) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/accept_request", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify({ "id": user.id })
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Requested successful!";
                    _color = "green";
                    dispatch(getUserDetail(user.id));
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
            });
    });
}

export const updateUserProfile = (user, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/profile", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify({ "user_name": user.user_name })
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Updated profile successful!";
                    _color = "green";
                    getUser(dispatch, user.id);
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const updateUserEmail = (data, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/update_email", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Updated email successful!";
                    _color = "green";
                    getUser(dispatch, data.id);
                    return onSuccess(result['data']);
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
            });
    });
}

export const verifyUserEmail = (data, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/update_email_verification", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Updated verification email successful!";
                    _color = "green";
                    getUser(dispatch, data.id);
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}


export const getAddresses = (userId) => (dispatch, getState) => {
    dispatch({
        type: GET_USER_DELIVERY_ADDRESSES,
        payload: []
    });

    firebase.firestore().collection("users").doc(userId).collection('delivery_addresses').orderBy('update_time', 'desc')
        .get()
        .then(function (querySnapshot) {
            var _addresses = [];
            querySnapshot.forEach(function (doc) {
                var _address = doc.data();
                _address['id'] = doc.id;
                _addresses.push(_address);
            });

            dispatch({
                type: GET_USER_DELIVERY_ADDRESSES,
                payload: _addresses
            });
        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
}

export const updateAddress = (address, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/delivery_address", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(address)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Update address successful!";
                    _color = "green";
                    if (address.is_default) {
                        dispatch(makeDefaultAddress({ "id": address.id, "user_id": address.user_id }));
                    }
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const addNewddress = (address, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/delivery_address", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(address)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Add address successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const deleteAddress = (data, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/delivery_address", {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Delete address successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
            }).finally((e) => {
                dispatch(getAddresses(data.user_id));
                if (onSuccess) onSuccess();
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
            });
    });
}

export const makeDefaultAddress = (data, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/default_delivery_address", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Make default address successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch(getAddresses(data.user_id));
                if (onSuccess) onSuccess();

                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
            });
    });
}

export const getAddressDetail = (userId, addressId) => (dispatch, getState) => {
    dispatch({
        type: GET_ADDRESS_DETAIL,
        address: {}
    });

    firebase.firestore().collection("users").doc(userId).collection('delivery_addresses').doc(addressId)
        .get()
        .then(function (doc) {
            var address = doc.data();
            address['id'] = doc.id;
            dispatch({
                type: GET_ADDRESS_DETAIL,
                address: address
            });
        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
}

export const changeUsersOrder = (order) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_USER_ORDER,
        order
    });
}

export const changeUserPage = (page) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_USER_PAGE,
        page
    });
}

export const setUserSearchTerm = (term) => (dispatch, getState) => {
    if (firebase.auth().currentUser) {
        dispatch(searchUsers(term));
        return;
    }
    dispatch({
        type: SET_USER_SEARCH_TERM,
        term
    });
}

export const getStaffsData = () => (dispatch, getState) => {
    dispatch({
        type: GET_STAFFS,
        staffs: []
    });
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {

            firebase.firestore().collection("users").where("is_employee", "==", true)
                .get()
                .then(function (querySnapshot) {
                    var _staffs = [];
                    querySnapshot.forEach(function (doc) {
                        var _staff = doc.data();
                        _staff['id'] = doc.id;
                        _staffs.push(_staff);

                    });
                    dispatch({
                        type: GET_STAFFS,
                        staffs: _staffs
                    });
                }).catch(function (error) {
                    console.log("Error getting documents: ", error);
                });

        }
    });
}

export const getCustomerAddresses = (userId) => (dispatch, getState) => {
    dispatch({
        type: GET_CUSTOMER_DELIVERY_ADDRESSES,
        payload: []
    });


    firebase.firestore().collection("users").doc(userId).collection('delivery_addresses').orderBy('update_time', 'desc')
        .get()
        .then(function (querySnapshot) {
            var _addresses = [];
            querySnapshot.forEach(function (doc) {
                var _address = doc.data();
                _address['id'] = doc.id;
                _addresses.push(_address);
            });

            dispatch({
                type: GET_CUSTOMER_DELIVERY_ADDRESSES,
                payload: _addresses
            });
        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
}


export const clearAddress = (address) => (dispatch, getState) => {
    dispatch({
        type: CLEAR_ADDRESS,
        address
    });
}

export const setCustomerSearchTerm = (term) => (dispatch, getState) => {
    if (firebase.auth().currentUser) {
        dispatch(searchCustomers(term));
        return;
    }
    dispatch({
        type: SET_CUSTOMER_SEARCH_TERM,
        term
    });
}

export const searchCustomers = (term) => (dispatch, getState) => {
    console.log("term", term);
    if (!!!firebase.auth().currentUser) return;
    if (!term || term === "") {
        dispatch(getUsersData());
        return;
    }
    console.log("search user");
    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsReportUrl + "/api/fts/users/" + term + "/10", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
        }).then(response => response.json())
            .then((result) => {
                var customers = [];
                if (result && result.data) {
                    let index = 0;
                    result.data.forEach(function (u) {
                        u["sn"] = ++index;
                        customers.push(u);
                    });
                }
                dispatch({
                    type: GET_CUSTOMERS,
                    customers
                });
            }).catch((e) => {
                console.log("error:", e);
            });

    });
}

export const clearSearchCustomers = () => (dispatch, getState) => {
    dispatch({
        type: GET_CUSTOMERS,
        customers: []
    });
}

export const clearPackageUser = () => (dispatch, getState) => {
    dispatch({
        type: CLEAR_PACKAGE_USERS,
    });
}

export const setSelectedCustomer = (customer) => (dispatch, getState) => {
    dispatch({
        type: SET_SELECTED_CUSTOMER,
        customer
    });
}

export const setSender = (customer) => (dispatch, getState) => {
    dispatch({
        type: SET_SENDER,
        customer
    });
}

export const setConsignee = (customer) => (dispatch, getState) => {
    dispatch({
        type: SET_CONSIGNEE,
        customer
    });
}

export const setCustomerAddress = (address) => (dispatch, getState) => {
    dispatch({
        type: SET_CUSTOMER_ADDRESS,
        address
    });
}

export const getCustomerDetail = (id) => (dispatch, getState) => {
    dispatch({
        type: SET_SELECTED_CUSTOMER,
        customer: {}
    })

    firebase.firestore().collection("users").doc(id)
        .get()
        .then(function (doc) {
            var userDetail = doc.data();
            userDetail['id'] = doc.id;

            dispatch({
                type: SET_SELECTED_CUSTOMER,
                customer: userDetail
            })

        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
}