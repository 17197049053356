import {
    USER_LOGIN,
    CHANGE_TAB,
    CHANGE_ROW_PER_PAGE,
    GET_SETTING,
    GET_USER,
    SET_IS_UPDATING,
    SET_SHOW_ALERT,
    SET_PRIVILEGES,
    GET_PICKUP_DATES,
    GET_ZONES,
    GET_EMAILS,
    USER_STATUS,
    GET_DELIVERY_ADDRESSES,
    GET_PICKUP_DATES_BY_ZONE,
    GET_ZONE_BY_ZIP_CODE,
} from "../actions/app";

const initialState = {
    isLogin: false,
    setting: {},
    user: {},
    status: 'joined',
    invited: false,
    rowsPerPage: 10,
    currentPageId: '',
    isUpdating: false,
    showAlert: { show: false, msg: "", color: "black" },
    privileges: [],
    pickupDates: [],
    zones: [],
    emails: [],
    addresses: [],
    defalutCountry: 'us',
    countries: ['us', 'mm', 'sg', 'gb','th'],
    countrie_names: ['United States', 'Myanmar', 'Singapore', 'United Kingdom','Thailand'],

    pickupDatesByZone: [],
    zoneByZipCode: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_LOGIN: {
            return {
                ...state,
                isLogin: action.payload['isLogin'],
            }
        }
        case CHANGE_ROW_PER_PAGE: {
            return {
                ...state,
                rowsPerPage: action.rowsPerPage,
            };
        }
        case GET_SETTING: {
            const setting = action.payload.setting;
            return {
                ...state,
                setting: setting,
            };
        }
        case GET_USER: {
            return {
                ...state,
                user: action.payload.user,
            };
        }
        case CHANGE_TAB: {
            return {
                ...state,
                currentPageId: action.payload['currentPageId'],
            }
        }
        case SET_IS_UPDATING: {
            return {
                ...state,
                isUpdating: action.isUpdating,
            }
        }
        case SET_SHOW_ALERT: {
            return {
                ...state,
                showAlert: action.showAlert,
            }
        }
        case SET_PRIVILEGES: {
            return {
                ...state,
                privileges: action.privileges,
            }
        }
        case GET_PICKUP_DATES: {
            return {
                ...state,
                pickupDates: action.dates,
            }
        }
        case GET_ZONES: {
            return {
                ...state,
                zones: action.zones
            }
        }
        case GET_PICKUP_DATES_BY_ZONE: {
            return {
                ...state,
                pickupDatesByZone: action.dates,
            }
        }
        case GET_ZONE_BY_ZIP_CODE: {
            return {
                ...state,
                zoneByZipCode: action.zone
            }
        }
        case GET_EMAILS: {
            return {
                ...state,
                emails: action.emails
            }
        }
        case USER_STATUS: {
            return {
                ...state,
                status: action.status
            }
        }
        case GET_DELIVERY_ADDRESSES: {
            return {
                ...state,
                addresses: action.payload
            }
        }
        default:
            return state;
    }
}
