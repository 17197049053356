import React, { useEffect } from 'react';
import { Typography, Grid, Box, Button, makeStyles, Paper, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import BackIcon from '@material-ui/icons/ArrowBack';
import { connect, useSelector } from 'react-redux';
import theme from '../theme';
import AddressCard from './address_card';
import { changePickupAddress } from '../actions/pickup';
import { clearAddress, deleteAddress, getAddresses } from '../actions/user';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    selectedAddressColor: {
        border: "2px solid green",
        marginRight: '5px',
        marginBottom: '5px',
    },
    unSelectedAddressColor: {
        border: "2px solid lightgray",
        marginRight: '5px',
        marginBottom: '5px',
    }
}));


function AddressesPage(props) {
    const classes = useStyles();
    const { title, history, dispatch } = props;
    const addresses = useSelector(state => state.user.addresses);
    const user = useSelector(state => state.app.user);

    useEffect(() => {
        if (user.id !== undefined) {
            dispatch(getAddresses(user.id));
        }
    }, [user, dispatch]);

    const handleBack = () => {
        history.goBack();
    }

    const handleNewAddress = () => {
        dispatch(clearAddress({}));
        history.push('/new-address');
    }

    const handleSelectedChangeAddress = (value) => {
        dispatch(changePickupAddress(value));
        history.goBack();
    }

    const handleEditAddress = (id) => {
        history.push('/address-detail/' + user.id + '/' + id);
    }

    const handleDeleteAddress = (v) => {
        var data = { "id": v.id, 'user_id': user.id };
        dispatch(deleteAddress(data));
    }

    return (

        <div className={classes.root}>
            <Box display="flex" p={1}>
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>

            <div style={{ padding: "30px" }}>
                <Grid container spacing={0} style={{}}>
                    <Box>
                        <Button size="small" variant="outlined" color="primary" style={{ float: 'left', marginBottom: '10px', marginRight: '10px', textTransform: 'none' }}
                            onClick={handleNewAddress}
                            startIcon={<AddIcon />}
                        > Add address</Button>
                    </Box>
                </Grid>
                <div style={{ display: 'inline-flex', width: '100%', marginTop: '5px', flexWrap: 'wrap', }}>
                    {addresses ? addresses.map((value) => {
                        return <Paper key={value.id}
                            className={
                                (value.is_default) ? classes.selectedAddressColor : classes.unSelectedAddressColor}
                        >
                            <AddressCard address={value}
                                onEdit={(v) => { handleEditAddress(v) }}
                                onDelete={(v) => { handleDeleteAddress(v) }}
                                onSelectedChange={(v) => { handleSelectedChangeAddress(v) }}></AddressCard>
                        </Paper>
                    }) : <span />
                    }
                </div>
            </div>
        </div >
    );
}

AddressesPage.propTypes = {
    title: PropTypes.string
}

export default connect()(AddressesPage);