import { Box, Button, Typography, Grid, Paper, TextField } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { cancelPickupRequest, getPickupDetail } from '../../actions/pickup';
import theme from '../../theme';
import { convertISOToDate, removeFCSString } from '../../util';
import AddressCard from '../address_card';
import { cancelDropoffRequest, getDropoffDetail } from '../../actions/dropoff';

const useStyles = makeStyles(th => ({
    root: {
        height: '100%',
        padding: '10px 10px 20px 10px'
    },
    rootPhotos: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    style: {
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    box: {
        marginTop: '10%',
        marginRight: '30%',
        marginLeft: '30%'
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
}));

const DropoffCancel = (props) => {
    const { title, history, dispatch, smallScreen } = props;
    const classes = useStyles();
    const stateIsUpdating = useSelector(state => state.app.isUpdating);
    const selector = useSelector(state => state.dropoff);
    const [dropoffDetail, setDropoffDetail] = React.useState(selector.dropoffDetail);

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var dropoffId = pathArr[2];

    const handleBack = () => {
        history.goBack();
    }

    useEffect(() => {
        dispatch(getDropoffDetail(dropoffId));
    }, [dispatch, dropoffId]);

    useEffect(() => {
        setDropoffDetail(selector.dropoffDetail);
    }, [selector]);

    const handleCancelDropoffRequest = () => {
        var value = {
            "id": dropoffDetail.id,
            "remark": dropoffDetail.remark
        };
        console.log("handleCancelDropoffRequest:", value);
        dispatch(cancelDropoffRequest(value, () => {
            history.goBack();
        }))
    }

    const handleRemark = (e) => {
        console.log('handleRemark:', e.target.value);
        setDropoffDetail({ ...dropoffDetail, 'remark': e.target.value });
    }

    return (
        <div className={classes.root}>
            <Box display="flex" p={1} >
                <Box><Button color="primary" onClick={handleBack} ><BackIcon /></Button></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: theme.palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table style={{ padding: smallScreen ? "5px" : '30px' }}>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Drop-Off Number'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{dropoffDetail.dropoff_number !== undefined ? dropoffDetail.dropoff_number : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{dropoffDetail.status !== undefined ? dropoffDetail.status : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Drop-Off Date'}</Typography>
                                <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{dropoffDetail.created_date !== undefined ? convertISOToDate(dropoffDetail.created_date) : ''}</Typography>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Customer'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{dropoffDetail.customer_fcs_id !== undefined ? removeFCSString(dropoffDetail.customer_fcs_id) : ''}</Typography>
                                    <Typography style={{ paddingRight: '30px', textAlign: 'left' }}>{dropoffDetail.customer_name !== undefined ? dropoffDetail.customer_name : ''}</Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey' }}>{'Remark'}</Typography>
                                <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    size={"small"}
                                    value={dropoffDetail.remark !== undefined ? dropoffDetail.remark : ""}
                                    multiline
                                    rows={3}
                                    style={{ width: '400px' }}
                                    onChange={(e) => handleRemark(e)}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                <Box>
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', backgroundColor: '#d83d0f', color: 'white' }}
                                        onClick={handleCancelDropoffRequest}
                                        startIcon={<CancelIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Cancel Drop-Off"} </Button>
                                </Box>
                            </Grid>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div >

    );
};

DropoffCancel.propTypes = {
    pDetail: PropTypes.object,
    title: PropTypes.string
};

export default withRouter(connect()(DropoffCancel));
