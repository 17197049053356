
import firebase from "../firebase_config";
import { config } from "../firebase_config";
import 'firebase/firestore';
import { SET_IS_UPDATING, SET_SHOW_ALERT } from "./app";
import { v4 as uuidv4 } from 'uuid';

export const GET_DROPOFFS = 'GET_DROPOFFS';
export const GET_DROPOFFS_ROW_COUNT='GET_DROPOFFS_ROW_COUNT';
export const CHANGE_DROPOFF_ORDER = "CHANGE_DROPOFF_ORDER";
export const GET_DROPOFF_DETAIL = 'GET_DROPOFF_DETAIL';

export const SET_DROPOFF_FORM_DATA = 'SET_DROPOFF_FORM_DATA';
export const CLEAR_DROPOFF_FORM = 'CLEAR_DROPOFF_FORM';
export const CHANGE_DROPOFF_PAGE = "CHANGE_DROPOFF_PAGE";
export const CHANGE_MYDROPOFF_ORDER = "CHANGE_MYDROPOFF_ORDER";
export const CHANGE_MYDROPOFF_PAGE = "CHANGE_MYDROPOFF_PAGE";

export const GET_MYDROPOFFS = 'GET_MYDROPOFFS';
export const GET_MYDROPOFF_DETAIL = 'GET_MYDROPOFF_DETAIL';
export const GET_MYDROPOFF_ROW_COUNT = "GET_MYDROPOFF_ROW_COUNT";

export const SET_DROPOFF = 'SET_DROPOFF';
export const SET_DROPOFF_SEARCH_TERM = 'SET_DROPOFF_SEARCH_TERM';
export const SET_MYDROPOFF_SEARCH_TERM = 'SET_MYDROPOFF_SEARCH_TERM';

export const SET_DROPOFF_STATUS_FILTER = 'SET_DROPOFF_STATUS_FILTER';

var _message = '';
var _color = '';

export const setDropoffFormData = (dropoff) => (dispatch, getState) => {
    dispatch({
        type: SET_DROPOFF_FORM_DATA,
        dropoffFormData:dropoff
    });
}

export const clearDropoffForm = () => (dispatch, getState) => {
    dispatch({
        type: CLEAR_DROPOFF_FORM,
    })
}


export const createDropoff = (dropoff, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/dropoffs", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(dropoff)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Drop-off created successfully!";
                    _color = "green";
                    return onSuccess();
                } else {
                    _message = result['message'];
                    _color = "red";
                }
                // dispatch(getPickupsData());
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
            });
    });
}

export const getDropoffDetail = (id) => (dispatch, getState) => {
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            firebase.firestore().collection("dropoffs").doc(id)
                .get()
                .then(function (doc) {
                    var dropoff = doc.data();
                    dropoff['id'] = doc.id;
                    dispatch({
                        type: GET_DROPOFF_DETAIL,
                        dropoff
                    })
                }).catch(function (error) {
                    console.log("Error getting documents: ", error);
                });
        }
    });
}

export const getDropoffsData = (order) => (dispatch, getState) => {
    if (!order) {
        order = getState().dropoff.order;
    }
    dispatch(changeDropoffsOrder(order));

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        var offset = getState().dropoff.page * getState().app.rowsPerPage;
        var limit = getState().app.rowsPerPage;
        var sorts = [];
        if (order && order['field_name'] && order['field_name'] !== ""
            && order['direction'] && order['direction'] !== "") {
            sorts = [order['field_name'] + " " + order['direction']];
        }
        var status = getState().dropoff.dropoffStatusFilter;
        var filter = [];
        if (!status.includes("ALL")) {
            filter.push({ "field": "status", "compare": " in ", "value": status });
        }
        var data = { limit: limit, offset: offset, sorts: sorts, filters: filter };
        fetch(config.fcsReportUrl + "/api/data/dropoffs", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var dropoffs = [];
                if (result && result.data) {
                    result.data.forEach(function (u) {
                        u["sn"] = ++offset;
                        dropoffs.push(u);
                    });
                }
                dispatch({
                    type: GET_DROPOFFS,
                    dropoffs
                });
            }).catch((e) => {
                console.log("error:", e);
            });

        fetch(config.fcsReportUrl + "/api/row/dropoffs", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var count = 0;
                if (result && result.data) {
                    count = result.data;
                }
                dispatch({
                    type: GET_DROPOFFS_ROW_COUNT,
                    count
                });
            }).catch((e) => {
                console.log("error:", e);
            });
    });
}

export const getMyDropoffsData = (order) => (dispatch, getState) => {
    if (!order) {
        order = getState().dropoff.myDropoffOrder;
    }
    dispatch(changeMyDropoffOrder(order));

    firebase.auth().currentUser.getIdToken().then(token => {
        var _user = getState().app.user;
        if (!_user || !(_user.id)) {
            return;
        }
        if (!token || token === "") return;
        var offset = getState().dropoff.myDropoffPage * getState().app.rowsPerPage;
        var limit = getState().app.rowsPerPage;
        var sorts = [];
        if (order && order['field_name'] && order['field_name'] !== ""
            && order['direction'] && order['direction'] !== "") {
            sorts = [order['field_name'] + " " + order['direction']];
        }

        var _filters = [];
        var _user = getState().app.user;
        _filters.push({ 'field': 'customer_fcs_id', 'compare': "=", 'value': _user.fcs_id });

        var data = { limit: limit, offset: offset, sorts: sorts, filters: _filters };
        fetch(config.fcsReportUrl + "/api/data/dropoffs", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var dropoffs = [];
                if (result && result.data) {
                    result.data.forEach(function (u) {
                        u["sn"] = ++offset;
                        dropoffs.push(u);
                    });
                }
                dispatch({
                    type: GET_MYDROPOFFS,
                    dropoffs
                });
            }).catch((e) => {
                console.log("error:", e);
            });

        fetch(config.fcsReportUrl + "/api/row/dropoffs", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((result) => {
                var count = 0;
                if (result && result.data) {
                    count = result.data;
                }
                dispatch({
                    type: GET_MYDROPOFF_ROW_COUNT,
                    count
                });
            }).catch((e) => {
                console.log("error:", e);
            });
    });
}

export const changeDropoffsOrder = (order) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_DROPOFF_ORDER,
        order
    });
}

export const changeDropoffPage = (page) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_DROPOFF_PAGE,
        page
    });
}


export const changeMyDropoffOrder = (order) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_MYDROPOFF_ORDER,
        order
    });
}

export const changeMyDropoffPage = (page) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_MYDROPOFF_PAGE,
        page
    });
}

export const setDropoffData = (dropoff) => (dispatch, getState) => {
    dispatch({
        type: SET_DROPOFF,
        dropoff
    });
}

export const setDropoffSearchTerm = (term) => (dispatch, getState) => {
    if (firebase.auth().currentUser) {
        dispatch(searchDropoffs(term));
        return;
    }
    dispatch({
        type: SET_DROPOFF_SEARCH_TERM,
        term
    });
}

export const searchDropoffs = (term) => (dispatch, getState) => {
    if (!!!firebase.auth().currentUser) return;
    if (!term || term === "") {
        dispatch(getDropoffsData());
        return;
    }

    let index = 0;
    var b64 = decodeURIComponent(term);
    var _value = atob(b64);
    console.log("searchDropoffs:", _value);

    var _filters = [];
    _filters.push({ 'field': 'dropoff_number', 'compare': "LIKE", 'value': "%" + _value + "%" });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsReportUrl + "/api/fts/dropoffs/" + term + "/10", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
        }).then(response => response.json())
            .then((result) => {
                var dropoffs = [];
                var dropoffList = [];
                var _allDropoffs = [];


                if (result && result.data) {
                    result.data.forEach(function (u) {
                        dropoffs.push(u);
                    });
                }

                fetch(config.fcsReportUrl + "/api/data/dropoffs", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Token': token,
                        'Project-ID': config.fcsReportId,
                    },
                    body: JSON.stringify({ filters: _filters })
                }).then(response => response.json())
                    .then((result) => {
                        if (result && result.data) {
                            result.data.forEach(function (p) {
                                dropoffList.push(p);
                            });
                        }

                        var ids = new Set(dropoffs.map(d => d.id));
                        _allDropoffs = [...dropoffs, ...dropoffList.filter(d => !ids.has(d.id))];

                        _allDropoffs.forEach((_p) => {
                            _p["sn"] = ++index;
                        });
                        dispatch({
                            type: GET_DROPOFFS,
                            dropoffs: _allDropoffs
                        });
                        var count = _allDropoffs.length;
                        dispatch({
                            type: GET_DROPOFFS_ROW_COUNT,
                            count
                        });
                    }).catch((e) => {
                        console.log("error:", e);
                    });

            }).catch((e) => {
                console.log("error:", e);
            });

    });
}

export const setMyDropoffSearchTerm = (term) => (dispatch, getState) => {
    if (firebase.auth().currentUser) {
        dispatch(searchMyDropoffs(term));
        return;
    }
    dispatch({
        type: SET_MYDROPOFF_SEARCH_TERM,
        term
    });
}

export const searchMyDropoffs = (term) => (dispatch, getState) => {
    if (!!!firebase.auth().currentUser) return;
    if (!term || term === "") {
        dispatch(getMyDropoffsData());
        return;
    }

    var _filters = [];
    var _user = getState().app.user;
    if (!_user || !(_user.fcs_id)) return;
    _filters.push({ 'field': 'customer_fcs_id', 'compare': "=", 'value': _user.fcs_id });
    var b64 = btoa(JSON.stringify(_filters));
    var filtersEsc = encodeURIComponent(b64);

    let index = 0;
    var _b64 = decodeURIComponent(term);
    var _value = atob(_b64);
    console.log("searchMyDropoffs:", _value);

    var _wcfilters = [];
    _wcfilters.push({ 'field': 'customer_fcs_id', 'compare': "=", 'value': _user.fcs_id });
    _wcfilters.push({ 'field': 'dropoff_number', 'compare': "LIKE", 'value': "%" + _value + "%" });


    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsReportUrl + "/api/fts_filter/dropoffs/" + term + "/10/0/" + filtersEsc, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
        }).then(response => response.json())
            .then((result) => {
                var dropoffs = [];
                var dropoffList = [];
                var _allDropoffs = [];

                if (result && result.data) {
                    let index = 0;
                    result.data.forEach(function (u) {
                        u["sn"] = ++index;
                        dropoffs.push(u);
                    });
                }

                fetch(config.fcsReportUrl + "/api/data/dropoffs", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Token': token,
                        'Project-ID': config.fcsReportId,
                    },
                    body: JSON.stringify({ filters: _wcfilters })
                }).then(response => response.json())
                    .then((result) => {
                        if (result && result.data) {
                            result.data.forEach(function (p) {
                                dropoffList.push(p);
                            });
                        }


                        var ids = new Set(dropoffs.map(d => d.id));
                        _allDropoffs = [...dropoffs, ...dropoffList.filter(d => !ids.has(d.id))];

                        _allDropoffs.forEach((_p) => {
                            _p["sn"] = ++index;
                        });

                        dispatch({
                            type: GET_MYDROPOFFS,
                            pickups: _allDropoffs
                        });
                        var count = _allDropoffs.length;
                        dispatch({
                            type: GET_MYDROPOFF_ROW_COUNT,
                            count
                        });
                    }).catch((e) => {
                        console.log("error:", e);
                    });

            }).catch((e) => {
                console.log("error:", e);
            });

    });
}


export const cancelDropoffRequest = (dropoff, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/dropoffs/cancel", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(dropoff)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Cancel drop-off successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const completeDropoff = (dropoff, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/dropoffs/complete", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(dropoff)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Complete dropoff successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}
export const uploadDropoffPhotos = (dropoffId, photos, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        console.log("token:",);
        if (!token || token === "") return;
        var promoises = [];
        photos.forEach(file => {
            // Create a root reference
            var ps = uploadImageAsPromise(dropoffId, file);
            promoises.push(ps);
        });

        Promise.all(promoises).then((values) => {
            return onSuccess(values);
        });
    })
}

function uploadImageAsPromise(dropoffId, file) {
    return new Promise(function (resolve, reject) {
        const storage = firebase.storage();
        const metadata = {
            contentType: file.type
        };
        const storageRef = storage.ref();

        var fileName = uuidv4();

        var uploadTask = storageRef.child("dropoffs/" + dropoffId + "/" + fileName).put(file, metadata);
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                console.log("upload error!", error);
            },
            () => {
                var url = uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    return downloadURL;
                });
                resolve(url);
            }
        );
    });
}

export const updateDropoff = (dropoff, onSuccess) => (dispatch, getState) => {
    dispatch({
        type: SET_IS_UPDATING,
        isUpdating: true
    });

    firebase.auth().currentUser.getIdToken().then(token => {
        if (!token || token === "") return;
        fetch(config.fcsUrl + "/dropoffs", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
                'Project-ID': config.fcsReportId,
            },
            body: JSON.stringify(dropoff)
        }).then(response => response.json())
            .then((result) => {
                console.log("Result:", result);
                if (result['status'] === 'Ok') {
                    _message = "Update dropoff successful!";
                    _color = "green";
                } else {
                    _message = result['message'];
                    _color = "red";
                }
                dispatch(getDropoffsData());
            }).catch((e) => {
                console.log("error:", e);
                _message = e.message;
                _color = "red";
            }).finally((e) => {
                dispatch({
                    type: SET_IS_UPDATING,
                    isUpdating: false
                });
                dispatch({
                    type: SET_SHOW_ALERT,
                    showAlert: { show: true, msg: _message, color: _color }
                });
                return onSuccess();
            });
    });
}

export const setDropoffStatusFilter = (filter) => (dispatch, getState) => {
    dispatch({
        type: SET_DROPOFF_STATUS_FILTER,
        filter: filter
    });
}