import { Box, Button, Grid, TextField, Typography, Paper, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { acceptUser, deleteAddress, getUserDetail, makeDefaultAddress } from '../../actions/user';
import palette from '../../theme/palette';
import { updateUser, activateUser, deleteUser } from '../../actions/user';
import { setIsUpdating } from '../../actions/app';
import AddIcon from '@material-ui/icons/Add';
import BlockIcon from '@material-ui/icons/Block';
import AddressCard from '../address_card';
import ConfirmDialog from '../confirm_dialog';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    table: {
        padding: "10px 20px 10px 20px"
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    }, button: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        width: 150,
        height: 55
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    headText: {
        fontSize: 14,
        float: "left",
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    cardBox: {
        width: 200,
        height: 150,
        margin: '5px'
    },
    cardDiv: {
        scrollBehavior: 'smooth',
        display: 'flex'
    }
}));


function UserEdit(props) {
    const classes = useStyles();
    const { dispatch, title, history } = props;
    const selector = useSelector(state => state.user);
    const [userDetail, setUserDetail] = React.useState(selector.userDetail);
    const [addresses, setAddresses] = React.useState(selector.addresses);
    const stateIsUpdating = useSelector(state => state.app.isUpdating);

    const [isDisable, setIsDisable] = React.useState(false);
    const [isEnable, setIsEnable] = React.useState(false);
    const [isAccept, setIsAccept] = React.useState(false);
    const [isDelete, setIsDelete] = React.useState(false);

    const [userName, setUserName] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');

    var pathName = history.location.pathname;
    var pathArr = pathName.split('/');
    var userId = pathArr[2];

    useEffect(() => {
        setUserDetail(selector.userDetail);
        setAddresses(selector.addresses);
    }, [selector]);

    useEffect(() => {
        dispatch(getUserDetail(userId));
    }, [userId, dispatch]);

    useEffect(() => {
        dispatch(setIsUpdating(false));
    }, [dispatch]);

    useEffect(() => {
        if (userDetail.id !== undefined) {
            setUserName(userDetail.user_name);
            setPhoneNumber(userDetail.phone_number);
        }
    }, [userDetail]);

    const handleUserName = (e) => {
        setUserName(e.target.value);
    }

    const handlePhoneNumber = (e) => {
        setPhoneNumber(e.target.value);
    }

    const handleBack = () => {
        history.goBack();
    }

    const handleSave = () => {
        var data = {
            "id": userDetail.id,
            "user_name": userName,
            "phone_number": phoneNumber
        }
        dispatch(updateUser(data, () => {
            history.goBack();
        }));
    }

    const handleActivate = () => {
        dispatch(activateUser(userDetail, true));
    }

    const handleDeactivate = () => {
        dispatch(activateUser(userDetail, false));
    }

    const handleDelete = () => {
        dispatch(deleteUser(userDetail, ()=>{
            history.goBack();
        }));
    }

    const handleAccept = () => {
        console.log('user detail:', userDetail);
        dispatch(acceptUser(userDetail));
    }

    const handleNewAddress = () => {
        history.push('/new-address');
    }

    const handleEditAddress = (id) => {
        history.push('/address-detail/' + userDetail.id + '/' + id);
    }

    const handleDeleteAddress = (v) => {
        var data = { "id": v.id, 'user_id': userDetail.id };
        dispatch(deleteAddress(data));
    }

    const handleMakeDefault = (v) => {
        var data = { "id": v.id, 'user_id': userDetail.id };
        dispatch(makeDefaultAddress(data, userDetail.id));
    }

    const handleDisableDialog = () => {
        setIsDisable(true);
    }

    const handleDeleteDialog = () => {
        setIsDelete(true);
    }

    const handleEnableDialog = () => {
        setIsEnable(true);
    }

    const handleAcceptDialog = () => {
        setIsAccept(true);
    }

    const handleCancel = () => {
        setIsEnable(false);
        setIsDisable(false);
        setIsAccept(false);
        setIsDelete(false);
    }

    return (
        <div className={classes.root}>
            <Box display="flex" p={1}>
                <Box><IconButton color="primary" onClick={handleBack} ><BackIcon /></IconButton></Box>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: '10px', fontSize: '16px', color: palette.primary.main }}>{title}</Typography></Box>
            </Box>
            <table className={classes.table}>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'FCS ID'}</Typography>
                                <Box display="flex">
                                    <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{userDetail.fcs_id}</Typography>
                                    {userDetail.status === "joined" ? <CheckIcon style={{ color: 'green' }} /> : <span />}
                                    {userDetail.status === "disabled" ? <BlockIcon style={{ color: 'grey' }} /> : <span />}
                                    <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{userDetail.status}</Typography>
                                </Box>
                            </div>
                        </td>

                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Name'}</Typography>
                                <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    size={"small"}
                                    value={userName ? userName : "" || ''}
                                    style={{ width: '250px' }}
                                    onChange={handleUserName}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Phone Number'}</Typography>
                                {userDetail.status === "invited" ? <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    placeholder="Enter phone number"
                                    size={"small"}
                                    value={phoneNumber ? phoneNumber : "" || ''}
                                    style={{ width: '250px' }}
                                    onChange={handlePhoneNumber}
                                /> : <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{phoneNumber ? phoneNumber : ""}</Typography>}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Email'}</Typography>
                                <Typography style={{ paddingRight: '30px', color: 'black', textAlign: 'left' }}>{userDetail.email ? userDetail.email : ""}</Typography>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <Grid container spacing={0} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                {userDetail.status === "joined" ? <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', backgroundColor: stateIsUpdating ? 'lightgrey' : '#d83d0f', color: 'white' }}
                                        onClick={handleDisableDialog}
                                        startIcon={<CancelIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Disable"} </Button>
                                </Box> : <span />}

                                {userDetail.status === "disabled" ? <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', backgroundColor: stateIsUpdating ? 'lightgrey' : 'green', color: 'white' }}
                                        onClick={handleEnableDialog}
                                        startIcon={<CheckIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Enable"} </Button>
                                </Box> : <span />}
                                {userDetail.status === "invited" ? <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px', backgroundColor: stateIsUpdating ? 'lightgrey' : '#d83d0f', color: 'white' }}
                                        onClick={handleDeleteDialog}
                                        startIcon={<CancelIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Delete"} </Button>
                                </Box> : <span />}
                                {userDetail.status === "requested" ? <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px' }} color="primary"
                                        onClick={handleAcceptDialog}
                                        startIcon={<CheckCircleIcon />}
                                    >{stateIsUpdating ? "Updating..." : "Accept"} </Button>
                                </Box> : <span />}
                                <Box >
                                    <Button disabled={stateIsUpdating} variant="contained" style={{ textTransform: 'none', float: 'left', marginBottom: '10px' }} color="primary"
                                        onClick={handleSave}
                                        startIcon={<SaveIcon />}
                                    > {stateIsUpdating ? "Updating..." : "Save"}</Button>
                                </Box>
                            </Grid>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            <div>
                                <Grid container spacing={0} style={{}}>
                                    <Box >
                                        <Typography style={{ paddingRight: '30px', color: 'grey', textAlign: 'left' }}>{'Delivery/Pickup addresses'}</Typography>
                                    </Box>
                                    <Box>
                                        <Button size="small" variant="contained" style={{ float: 'left', marginBottom: '10px', marginRight: '10px', textTransform: 'none' }}
                                            onClick={handleNewAddress}
                                            startIcon={<AddIcon />}
                                        > Add address</Button>
                                    </Box>
                                </Grid>

                                <div style={{ display: 'inline-flex', width: '100%', marginTop: '5px', flexWrap: 'wrap', }}>
                                    {addresses ? addresses.map((value) => {
                                        return <Paper key={value.id} style={{ marginRight: '5px', marginBottom: '5px', border: value.is_default ? '2px solid green' : '2px solid lightgray' }} >
                                            <AddressCard address={value}
                                                onMakeDefault={(v) => { handleMakeDefault(v) }}
                                                onEdit={(v) => { handleEditAddress(v) }}
                                                onDelete={(v) => { handleDeleteAddress(v) }}>

                                            </AddressCard>
                                        </Paper>
                                    }) : <span />
                                    }
                                </div>

                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {isEnable ? <ConfirmDialog
                msg={'Enable this customer "' + userDetail.user_name + '"?'}
                openDialog={isEnable}
                confirmBtnLabel={"Enable"}
                onCancel={(v) => handleCancel(v)}
                onContinue={(v) => handleActivate(v)}></ConfirmDialog> : <div />}
            {isDisable ? <ConfirmDialog
                msg={'Disable this customer "' + userDetail.user_name + '"?'}
                openDialog={isDisable}
                confirmBtnLabel={"Disable"}
                onCancel={(v) => handleCancel(v)}
                onContinue={(v) => handleDeactivate(v)}></ConfirmDialog> : <div />}
            {isAccept ? <ConfirmDialog
                msg={'Accept this customer "' + userDetail.user_name + '"?'}
                openDialog={isAccept}
                confirmBtnLabel={"Accept"}
                onCancel={(v) => handleCancel(v)}
                onContinue={(v) => handleAccept(v)}></ConfirmDialog> : <div />}
            {isDelete ? <ConfirmDialog
                msg={'Delete this invite "' + userDetail.user_name + '"?'}
                openDialog={isDelete}
                confirmBtnLabel={"Delete"}
                onCancel={(v) => handleCancel(v)}
                onContinue={(v) => handleDelete(v)}></ConfirmDialog> : <div />}

        </div >
    );
}

UserEdit.propTypes = {
    history: PropTypes.object,
    data: PropTypes.object,
    displayFields: PropTypes.array,
    onEdit: PropTypes.func,
    isNew: PropTypes.bool,
    collectionName: PropTypes.string,
    url: PropTypes.string,
    title: PropTypes.string
};

export default connect()(UserEdit);
